import 'flatpickr/dist/themes/material_blue.css'

import {
    FormSectionCard,
	FormSectionTitle,
} from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useRef, useState } from 'react'
import MissionsMiseADispoReadOnly from './MissionsMiseADispoReadOnly'
import _ from 'lodash'
import MiseADispoReadOnly from './MiseADispoReadOnly'


function InformationsMiseADispoReadOnly({
	watch,
	setValue,
	initialNumberOfMissions = 1,
	display = false,
	keepFilesAsObjects = false,
	control,
	getValues,
	errors,
	commande,
}) {
	const [numberOfMissions, setNumberOfMissions] = useState(initialNumberOfMissions)

 
    
    
      
	useEffect(() => {
		setNumberOfMissions(initialNumberOfMissions)
	}, [initialNumberOfMissions])

		  
			
	  
	  
	
  
	
	
    
    


	return (
		<div>
			

			{[...Array(parseInt(numberOfMissions || 1)).keys()].map((i) => (
				
				<div key={String(i)}>
				
				<div
				
			style={{
				display: 'flex',
				flexDirection: 'row',
				marginTop:'1rem'
			}}
		>
			<FormSectionTitle>
			<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
			Informations de transport mission {i+1}
		    </div>
				</FormSectionTitle>

			
			</div>
				<FormSectionCard >

					{
						i === 0 ? (
							<MiseADispoReadOnly
								keepFilesAsObjects={keepFilesAsObjects}
								i={i}
								watch={watch}
								errors={errors} 
								getValues={getValues}
								setValue={setValue}
								control={control}
								display={display}
								commande={commande}

							/>
						) : (
							<MissionsMiseADispoReadOnly
								keepFilesAsObjects={keepFilesAsObjects}
								i={i}
								watch={watch}
								getValues={getValues}
								setValue={setValue}
								control={control}
								display={display}
								errors={errors} 
								commande={commande}
							/>
						)
					}

				</FormSectionCard>
				</div>
			))}

			
			  
		</div>
		
	)
}

export default InformationsMiseADispoReadOnly
