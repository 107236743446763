import {
	FormContentSection,
	FormSectionCard,
	FormSectionTitle,
	Seperator,
} from '../../Containers/PasserCommande/PasserCommande.styles'
import React, { useEffect } from 'react'
import { useRef, useState } from 'react'
import vehiculeCamion from '../../assets/img/vehicule_camion.png'
import Modal from 'react-modal'
import PoidsLours from '../../assets/img/poid_lourd@2x.png'
import tracteurSemi from '../../assets/img/tracteur-actos.png'
import Semi from '../../assets/img/semi.png'
import SemiImg from '../../assets/img/Capturesemi.png'
import tonnes7 from '../../assets/img/7.5.png'
import  tonnes19 from  '../../assets/img/tonnes19.png'
import tonnes12 from '../../assets/img/tonnes12.png'
import VehiculeLeger from '../../assets/img/vehicule_leger@2x.png'
import Break from '../../assets/img/CaptureBreak.png'
import Fourgon12 from '../../assets/img/captureF12.png'
import Fourgon14 from '../../assets/img/fourgon12m.png'
import { useForm ,Controller} from 'react-hook-form'
import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import moment from 'moment'
import { CustomInput } from '../../Containers/RegisterTransporter/style'
import UploadFile from '../UploadSingleFile/upload.component'
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { BASE_URL } from '../../api/base'
Modal.setAppElement('#root')

function AddVehicleRegister({
	onChange,
	initialVehicles = [],
	disableAdd = false,
}) {
	const [vehicles, setVehicles] = useState([])
	const [editIndex, setEditIndex] = useState(null);
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const { control, handleSubmit, register,reset, setValue, watch, formState: { errors } } = useForm({
		defaultValues: {
			type: '',
			date_mise_circulation: '',
			immatriculation: '',
			carte_grise: null,
			location: false,
		},
	});	
	
    const handleSubmitForm = (data) => {
		let filteredData = { ...data };
		if (data.type === 'Véhicule léger') {
			filteredData = {
			  type: data.type,
			  date_mise_circulation: data.date_mise_circulation,
			  immatriculation: data.immatriculation,
			  location: data.location,
			  carte_grise:data.carte_grise,
			  options_vehicule_leger: data.options_vehicule_leger,
			  vehicule_leger: data.vehicule_leger,
			};
		  } else if (data.type === 'Poids lourds') {
			filteredData = {
			  type: data.type,
			  date_mise_circulation: data.date_mise_circulation,
			  immatriculation: data.immatriculation,
			  location: data.location,
			  carte_grise:data.carte_grise,
			  options_poids_lourds: data.options_poids_lourds,
			  vehicule_poids_lourds: data.vehicule_poids_lourds,
			};
		  }
		if (editIndex !== null) {
		  const updatedVehicles = vehicles.map((v, index) =>
			index === editIndex ? data : v
		  );
		  setVehicles(updatedVehicles);
		  onChange(updatedVehicles);
		  setEditIndex(null); 
		 

		} else {
		  setVehicles([...vehicles, filteredData]);
		  onChange([...vehicles, filteredData]);

		}
		reset({type: '',
			date_mise_circulation: '',
			immatriculation: '',
			carte_grise: null,
			location: false,})
		setModalIsOpen(false); 
	};
	
	const handleDelete = (index) => {
		const newVehicles = vehicles.filter((_, i) => i !== index);
		setVehicles(newVehicles);
		onChange(newVehicles);
	};
	
	const handleEdit = (index) => {
		const selectedVehicle = vehicles[index];
		reset(selectedVehicle);
		setModalIsOpen(true); 
		setEditIndex(index); 
	};
	

	useEffect(() => {
		setVehicles(initialVehicles)
	}, [initialVehicles.length])

	const type = useRef()
	type.current = watch('type', '')

	const vehicule_leger = useRef()
	vehicule_leger.current = watch('vehicule_leger', '')

	const vehicule_poids_lourds=useRef()
	vehicule_poids_lourds.current=watch('vehicule_poids_lourds','')
	
	const handleOptionChange = (e) => {
		const selectedOption = e.target.value;
	  
		// Si l'option sélectionnée est "Bachée" ou "Frigorifique"
		if (selectedOption === "Bachée" || selectedOption === "Frigorifique") {
		  // Désélectionner l'autre option si elle est sélectionnée
		  const otherOption = selectedOption === "Bachée" ? "Frigorifique" : "Bachée";
		  const otherOptionCheckbox = document.querySelector(`[value="${otherOption}"]`);
		  if (otherOptionCheckbox) {
			otherOptionCheckbox.checked = false;
		  }
		}
	  };


	const getOptionsVehiuleLeger = () => {
		if (
			['Break', 'Fourgon 12m³', 'Fourgon 14m³'].includes(
				vehicule_leger.current
			)
		) {
			return ['Frigorifique','Tall', 'Hayon']
		}

		return ['Frigorifique', 'Bachée', 'Tall', 'Hayon']
	}
	const getOptionsVehiuleLourd=()=>{
		
		if (vehicule_poids_lourds.current==='Tracteur') {
			return ['Frigorifique','Tall', 'Hayon']
		}

		return ['Frigorifique', 'Bachée', 'Tall', 'Hayon']
	}
	

	return (
		<FormContentSection>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<FormSectionTitle>Véhicules</FormSectionTitle>
					<span>
						Veuillez indiquer le type et la quantité de véhicules
						que vous exploiter
					</span>
				</div>
				{!disableAdd && (
					<button
						type='button'
						onClick={(e) => {
							setModalIsOpen(true)
							e.stopPropagation()
						}}
						style={{
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							fontFamily: 'Montserrat',
							padding: '0 2rem',
							borderRadius: 6,
							fontWeight: 'bold',
							
						}}
					>
						Ajouter véhicule
					</button>
				)}
			</div>
			<FormSectionCard>
			<table
		    cellPadding={0}
		    cellSpacing={0}
		    style={{
			width: '100%',
			textAlign: 'left',
			borderRadius: 20,
			overflow: 'hidden',
			border: 'none',
		    }}
	       >
		<thead>
			<tr
				style={{
					paddingBottom: 10,
				}}
			>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Détails
				</th>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Options
				</th>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Date de mise en circulation
				</th>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Immatriculation
				</th>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Location
				</th>
				<th
					style={{
						fontWeight: 'normal',
						color: '#262626',
						padding: '1rem',
					}}
				>
					Carte grise
				</th>
				
			</tr>
		</thead>

		<tbody>
			{vehicles.map((v, index) => (
				<tr
					key={String(index)}
					style={{
						background: 'white',
					}}
				>
					<td
						style={{
							padding: '1rem',
						}}
					>
						{v?.type == 'Poids lourds' &&
							Array.isArray(v?.vehicule_poids_lourds) ? v?.vehicule_poids_lourds?.join(', ') : v?.vehicule_poids_lourds}
						{v?.type == 'Véhicule léger' &&
							v?.vehicule_leger}
					</td>
					<td
						style={{
							padding: '1rem',
						}}
					>
						{v?.type == 'Poids lourds' &&
							Array.isArray(v?.options_poids_lourds) ? v?.options_poids_lourds?.join(', ') : v?.options_poids_lourds}
						{v?.type == 'Véhicule léger' &&
							Array.isArray(v?.options_vehicule_leger) ? v?.options_vehicule_leger?.join(', ') : v?.options_vehicule_leger}
					</td>
					<td
						style={{
							padding: '1rem',
						}}
					>
						{v.date_mise_circulation}
					</td>
					<td
						style={{
							padding: '1rem',
						}}
					>
						{v.immatriculation}
					</td>
					<td
						style={{
							padding: '1rem',
						}}
					>
						{v?.location ? 'Oui' :'Non'} 
					</td>
					<td
						style={{
							padding: '1rem',
						}}
					>
					<span
                    style={{
                      cursor: v?.carte_grise?.path ? 'pointer' : 'default', 
                    }}
                    onClick={() => {
                    if (v?.carte_grise?.path) {
                     window.open(`${BASE_URL}/${v?.carte_grise?.path}`);
                    }
                    }}
                    >
                   {v?.carte_grise?.name || 'Aucun document'} 
                   </span>
					

						
						
					</td>
					<td
						style={{
							padding: '1rem',
							display: 'flex',
							flexDirection: 'row',

						}}
					>
						<div
						        onClick={() => handleEdit(index)}
										style={{
											cursor: 'pointer',
										}}
									>
										<BiEdit size={24} style={{color: '#51C7F2',}}/>
										
						</div>
						<div
							onClick={() => handleDelete(index)}
							style={{
											cursor: 'pointer',
										}}
									>
										<MdDelete size={24} style={{color: '#d9534f',}}/>
										
						</div>
						
					
					</td>
				</tr>
			))}
		</tbody>
	        </table>
			</FormSectionCard>

			<Modal
				isOpen={modalIsOpen}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						background: '#E4EAF0',
						paddin: '4rem',
						height:"450px",
						width:"70%"
					},
				}}
				contentLabel="Ajouter un véhicule"
				>
				<div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						fontSize: 15,
						textAlign: 'left',
						color: '#838EAB',
					}}
				>
					<div
						style={{
							color: '#51C7F2',
							fontSize: 30,
							marginBottom: '1rem',
							fontWeight: 'bold',
							fontFamily: 'Montserrat',
							display: 'flex',
							alignItems: 'center',
						    justifyContent: 'center',
							
							
						}}
						
					>
					{editIndex !== null ? 'Modifier un véhicule' : 'Ajouter un véhicule'} 

					</div>

					<form onSubmit={handleSubmit()}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								marginBottom: '1.5rem',
							}}
						>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
								Type de véhicules
							</span>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '1rem',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										//marginBottom: '1rem',
									}}
								>
									<div
										style={{
											width: '15rem',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-start',
										}}
									>
										<input
											type='radio'
											style={{
												marginRight: '1rem',
											}}
											{...register('type', {
												required: true,
											})}
											value={'Véhicule léger'}
										/>

										<span
											style={{
												marginRight: '0.5rem',
											}}
										>
											Véhicule léger
										</span>
										<img
											src={VehiculeLeger}
											style={{
												width: 40,
											}}
										/>
									</div>
									
									<div
										style={{
											width: '15rem',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-start',
										}}
									>
										<input
											style={{
												marginRight: '1rem',
											}}
											type='radio'
											{...register('type', {
												required: true,
											})}
											value={'Poids lourds'}
										/>
                                     
										<span
											style={{
												marginRight: '0.5rem',
											}}
										>
											Poids lourds
										</span>

										<img
											src={PoidsLours}
											style={{
												width: 40,
											}}
										/>
									</div>
								</div>
							</div>
							{errors.type && (
                                  <div style={{ color: 'red'}}>
                                  Veuillez sélectionner le type de véhicules
                                 </div>
                            )}
						</div>
						

						{type.current && (
							<>
								<div
									style={{
										width: '100%',
										height: 1,
										background: 'red',
										borderTop: '1px solid #707070',
										opacity: '0.3',
										marginBottom: '1.5rem',
									}}
								/>

								{type.current !== 'Poids lourds' && (
									<div 
									style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									marginBottom: '1.5rem',

									}}>
									<div  style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										}}
									>
										<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%', 
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>	
											<img
										    src={Break}
											style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>
                                            <div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													{...register(
														'vehicule_leger', {required:true}
													)}
													value='Break'
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Break
												</span>
												
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontWeight:"bold",
														fontSize:"14px",
													    marginRight: '0.5rem',
													}}
												>
											1097 kg
											</span>
											</div>
											
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5', 
												}}>
													<span>Dimension en M3 : 6M3 </span>
                                                    <span>Longueur utile : 3750mm </span>  
                                                    <span>Largeur utile : 1956mm </span>  
                                                    <span>Hauteur utile : 1320mm </span>  
                                                    <span>Charge utiles : 1097kg </span>  
													<span>Nombre de palettes: 2 pal europe </span> 
												</div>	
										</div>
										<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%',
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={Fourgon12}
										    style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>
                                           
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													{...register(
														'vehicule_leger'
													)}
													value='Fourgon 12m³'
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Fourgon 12m³
												</span>
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
													    marginRight: '0.5rem',
														fontWeight: "bold"


													}}
												>
											1244 kg
											</span>
											</div>
										
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 12M3 </span>
													<span>Longueur utile : 3030mm </span>  
                                                    <span>Largeur utile : 2070mm </span>  
                                                    <span>Hauteur utile : 1320mm </span>  
                                                    <span>Charge utiles : 1244kg </span>  
													<span>Nombre de palettes: 5 pal europe</span> 
												</div>	
										</div>
										
										<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%',
												marginRight: '2%',
											}} >
									        <div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={Fourgon14}
											style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	

											
                                            <div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													{...register(
														'vehicule_leger'
													)}
													value='Fourgon 14m³'
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Fourgon 14m³
												</span>
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
														fontWeight: "bold",
														marginRight: '0.5rem',

													}}
												>
											1353 kg 
											</span>
											</div>
											
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 14M3 </span>
													<span>Longueur utile : 3680mm </span>  
                                                    <span>Largeur utile : 2070mm </span>  
                                                    <span>Hauteur utile : 1820mm </span>  
                                                    <span>Charge utiles : 1353kg </span>  
													<span>Nombre de palettes: 6 pal europe</span> 
												</div>
											</div>	
										
									</div>
									</div>	
									<div style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										}}
									>
									
										<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%',
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										src={vehiculeCamion}
										style={{
											width: '100%',
											height: 'auto',
											maxWidth: '120px',
											maxHeight: '80px',
											objectFit: 'cover',
										  }}
										alt=''
									     />
											</div>	
                                          
										   <div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													{...register(
														'vehicule_leger'
													)}
													value='Camion 20m³'
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Camion 20m³
												</span>
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
											            marginRight: '0.5rem',
														fontWeight: "bold"


													}}
												>
												1200 kg
											</span>
											</div>
										
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 20M3 </span>  
													<span>Longueur utile : 4320mm </span>  
                                                    <span>Largeur utile : 2070mm </span>  
                                                    <span>Hauteur utile : 2100mm </span>  
                                                    <span>Charge utiles : 1200kg </span>  
													<span>Nombre de palettes: 7 pal europe</span> 
												</div>
										</div>
									</div>	
									</div>
									{errors.vehicule_leger && (
                                    <div style={{ color: 'red' }}>
                                    Veuillez choisir le type de véhicule détaillé
                                    </div>
                                    )}
									</div>
								     )}
                      
								{type.current !== 'Poids lourds' && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											marginBottom: '1.5rem',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Options Véhicule léger
										</span>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												marginBottom: '1rem',
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													marginBottom: '1rem',
												}}
											>
												{getOptionsVehiuleLeger().map(
													(item, index) => (
														<div
															key={String(index)}
															style={{
																width: '15rem',
																display: 'flex',
																alignItems:
																	'center',
																justifyContent:
																	'flex-start',
															}}
														>
															<input
																type='checkbox'
																style={{
																	marginRight:
																		'1rem',
																}}
																{...register(
																	'options_vehicule_leger',
																	{required:true}
																)}
																value={item}
																onChange={(e) => handleOptionChange(e)}
															/>

															<span
																style={{
																	marginRight:
																		'0.5rem',
																}}
															>
																{item}
															</span>
														</div>
													)
												)}
											</div>
											{type.current == null && (
												<Seperator />
											)}
											{errors.options_vehicule_leger && (
                                            <div style={{ color: 'red' }}>
                                            Veuillez cocher au moins une option pour le type de transport.
                                            </div>
                                            )}
										</div>
										
									</div>
									
								)}
								

								{type.current !== 'Véhicule léger' && (
									<div
                                    style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									marginBottom:"1rem"
									}}>
									<div style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
										}}
									>
										<div 
											style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										    }}>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%', 
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={tonnes7}
										    style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
											
											<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<input
														type='radio'
														style={{
															marginRight: '1rem',
														}}
														{...register(
															'vehicule_poids_lourds'
														)}
														value='7,5 Tonnes'
													/>

													<span>7,5 Tonnes</span>
													
												</div>
												<div
											style={{
												marginLeft: 36,
											
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
														marginRight: '0.5rem',
														fontWeight: "bold"

													}}
												>
												2470 kg
											</span>
											</div>
										
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5', 
												
												}}>
													<span>Dimension en M3 : 30M3 </span>
													<span>Longueur utile : 6575mm </span>  
                                                    <span>Largeur utile : 2045mm </span>  
                                                    <span>Hauteur utile : 2400mm </span>  
                                                    <span>Charge utiles : 2470kg </span>  
													<span>Nombre de palettes: 16 pal europe</span> 
												</div>	
											</div>	
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%',
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={tonnes12}
											style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
											
											<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<input
														type='radio'
														style={{
															marginRight: '1rem',
														}}
														{...register(
															'vehicule_poids_lourds'
														)}
														value='12 Tonnes'
													/>

													<span>12 Tonnes</span>
													
												</div>
												<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
											            marginRight: '0.5rem',
														fontWeight: "bold"

													}}
												>
												5600 kg
											</span>
											</div>
											
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 45M3 </span>
													<span>Longueur utile : 7540mm </span>  
                                                    <span>Largeur utile : 2480mm </span>  
                                                    <span>Hauteur utile : 2500mm </span>  
                                                    <span>Charge utiles : 5600kg </span>  
													<span>Nombre de palettes: 19 pal europe</span> 
												</div>
											</div>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%',
											}} >
											<div  style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={tonnes19}
										    style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
											
											<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<input
														type='radio'
														style={{
															marginRight: '1rem',
														}}
														{...register(
															'vehicule_poids_lourds'
														)}
														value='19 Tonnes'
													/>

													<span>19 Tonnes</span>
													
												</div>
												<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
														marginRight: '0.5rem',
														fontWeight: "bold"

													}}
												>
												9200 kg
											</span>
											</div>
											
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 58M3 </span>
													<span>Longueur utile : 9100mm </span>  
                                                    <span>Largeur utile : 2470mm </span>  
                                                    <span>Hauteur utile : 2490mm </span>  
                                                    <span>Charge utiles : 9200kg </span>  
													<span>Nombre de palettes: 22 pal europe</span> 
												</div>	
											</div>
											</div>

									</div>	
									</div>
									<div style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
										}}
									>
									<div 
											style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
									}}>
										<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%',
											}} >
												<div  style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
												<img
										    src={SemiImg}
											style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
												marginTop:-15
											  }}
										    alt=''
									        />
												</div>
										
											<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
															marginTop:-20
													}}
												>
													<input
														type='radio'
														style={{
															marginRight: '1rem',
														}}
														{...register(
															'vehicule_poids_lourds'
														)}
														value='Semi'
													/>

													<span>Semi</span>
												
												</div>
												<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
												        marginRight: '0.5rem',
														fontWeight: "bold"

													}}
												>
												27000 kg
											</span>
											</div>
											
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 86M3 </span>
													<span>Longueur utile:13500mm</span>  
                                                    <span>Largeur utile : 2470mm </span>  
                                                    <span>Hauteur utile : 2590mm </span>  
                                                    <span>Charge utiles : 27000kg </span>  
													<span>Nombre de palettes: 33 pal europe</span> 
												</div>
											</div>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%',
											}} >
                                           
										   <div  style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
										   <img
										    src={Semi}
										    style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
										   </div>
											
											<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<input
														type='radio'
														style={{
															marginRight: '1rem',
														}}
														{...register(
															'vehicule_poids_lourds',
															{required:true}
														)}
														value='Tracteur+semi'
													/>

													<span>Tracteur+semi</span>
												</div>
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5', 
												}}>
													
                                                    <span>Longueur utile : 2.45 M</span>  
                                                    <span>Largeur utile : 1.50 M</span>
                                                    <span>Hauteur utile : 1.60 M</span>  
                                                    <span>Volume utile : 6 M3</span>  
                                                    <span>Charge max : 1000 kg</span>  
													<span>Nombre de palettes: 32 pal europe</span>   
													
												</div>	
											</div>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%',
											}} >
											<div  style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
												<img
										    src={tracteurSemi}
											style={{
												width: '100%',
												height: 'auto',
												maxWidth: '100px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>
											
											<div
													style={{
														width: '15rem',
														display: 'flex',
														alignItems: 'center',
														justifyContent:
															'flex-start',
													}}
												>
													<input
														type='radio'
														style={{
															marginRight: '1rem',
														}}
														{...register(
															'vehicule_poids_lourds'
														)}
														value='Tracteur'
													/>

													<span>Tracteur</span>
												
												</div>
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5', 
												}}>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Longueur utile : </span>  
													<span>2.45 M</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Largeur utile : </span>  
													<span>1.50 M</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Hauteur utile : </span>  
													<span>1.60 M</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Volume utile : </span>  
													<span>6 M3</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Charge max : </span>  
													<span>1000 kg</span>   
													</div>
												</div>
											</div>	
											
											
									</div>	
									</div>
									</div>	
									{errors.vehicule_poids_lourds && (
                                    <div style={{ color: 'red' }}>
                                     Veuillez choisir le type de véhicule détaillé.
                                     </div>
                                     )}
									</div>
								)}
					

								{type.current !== 'Véhicule léger' && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											marginBottom: '1.5rem',

										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Options poids lourds
										</span>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													//marginBottom: '1rem',
												}}
											>
												{getOptionsVehiuleLourd().map((item, index) => (
													<div
														key={String(index)}
														style={{
															width: '15rem',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='checkbox'
															style={{
																marginRight:
																	'1rem',
															}}
															{...register(
																'options_poids_lourds',
																{required:true}
															)}
															value={item}
														/>

														<span>{item}</span>
													</div>
												))}
											</div>
											
										</div>
										{errors.options_poids_lourds && (
                                            <div style={{ color: 'red',marginTop:'1rem' }}>
                                             Veuillez cocher au moins une option pour le type de transport
                                            </div>
                                        )}
										
									</div>
								)}
							</>
						)}
                       
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								marginBottom: '1.5rem',

							}}
						>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
                            Date de mise en circulation
							</span>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									width: '100%',
								}}
							>
						    <Controller
                            name="date_mise_circulation"
                            control={control}
                            defaultValue={null}
							rules={{
								required: 'Veuillez sélectionner la date de mise en circulation',
							}}
                            render={({ field }) => (
							
                            <Flatpickr
                             {...field}
                             className='date-input'
                             options={{
                             mode: 'single',
                             locale: French,
                             dateFormat: 'd/m/Y',
							 maxDate:new Date()
                             }}
                            onChange={([date]) =>{
								const formattedDate = moment(date).format('DD/MM/YYYY')
								field.onChange(formattedDate)
							} }
                           />
						 
						
                            )}
                           />
							</div>
							{errors?.date_mise_circulation && (
                          <div style={{ color: 'red', marginTop: '0.5rem' }}>
                           {errors?.date_mise_circulation?.message}
                         </div>
                         )}
						</div>
						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								marginBottom: '1.5rem',
					  
							}}


						>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
                            Immatriculation de voiture
							</span>
							
						    <CustomInput
                             type='text'
                             placeholder='AA-123-AA'  
                             {...register('immatriculation', {
                              required: "Veuillez entrer l'immatriculation du véhicule",  
                              })}
                            />
							{errors?.immatriculation && (
								<div style={{ color: 'red', marginTop: '0.5rem' }}>
								 {errors?.immatriculation?.message}
							   </div>
							)}
						
						</div>
						
						<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '1rem',
										width: '100%',
										fontFamily: 'Montserrat',
										marginTop: 10,
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Carte  grise valide (PDF)
										</span>
										
									</div>
									<Controller
                                     name='carte_grise'
                                     control={control}
                                     rules={{
										required: !watch('location') ? 'Veuillez télécharger la carte grise' : false, 
									}}
                                     render={({ field }) => (
									<>
                                    <UploadFile
                                    name={field.name}
                                    accept={'.pdf'}
                                    setValue={setValue}
                                     {...field}
                                    />
									{watch('carte_grise')?.name}
									{errors?.carte_grise && (
                                    <span style={{ color: 'red', fontSize:'14px'  }}>
                                    {errors?.carte_grise?.message}
                                   </span>
                                    )}
									</>
                                   )}
                                   />
								   
									
									
						</div>

						<div
                         style={{
                        
							marginBottom: '1.5rem',
							display: 'flex',
							alignItems: 'center',
							fontSize: '14px',                         }}
                        >
                       <input
                        type='checkbox'
                        style={{ marginRight: '1rem' }}
                        {...register('location',)}
						defaultChecked={false}
                         />
                        <span style={{ marginRight: '0.5rem' }}>Véhicule de location</span>
                       </div>
                      
					  
                        
					  
					 
					  
                       
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								padding: '1rem',
							}}
						>
							<div style={{display: 'flex',flexDirection: 'row',}}>
							<button
								type='button'
								onClick={handleSubmit(handleSubmitForm)}
								style={{
									background: '#50F5A9',
									width: '150px',
									height: '50px',
									border: 'none',
									cursor: 'pointer',
									color: 'white',
									borderRadius: 7,
									marginTop: 5,
									fontFamily: 'Montserrat',
									marginRight: 10,
									fontWeight: 'bold',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{editIndex !== null ? 'Modifier' : 'Ajouter'} 
							</button>
							<button 
							onClick={()=>{
								setEditIndex(null);
								reset({type: '',
									date_mise_circulation: '',
									immatriculation: '',
									carte_grise: null,
									location: false,})
								setModalIsOpen(false)
							}}
							style={{
								width: '150px',
								height: '50px',
								background: 'red',
								border: 'none',
								cursor: 'pointer',
								color: 'white',
								borderRadius: 7,
								marginTop: 5,
								fontFamily: 'Montserrat',
								fontWeight: 'bold',
							}}>
								Annuler
							</button>
							</div>
						</div>
					</form>
				</div>
			</Modal>
		</FormContentSection>
	)
}

export default AddVehicleRegister
