import 'flatpickr/dist/themes/material_blue.css'

import { Controller, useForm } from 'react-hook-form'
import {
	FormSectionCard,
	GradientText,
} from '../PasserCommande/PasserCommande.styles'
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import {
	getChauffeur,
	toggleSuspendreChauffeur,
	updateChauffeur,
} from '../../api/chauffeurs'
import { useHistory, useParams } from 'react-router'

import { CustomInput } from '../RegisterTransporter/style'
import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import LayoutTransporter from '../../Components/LayoutTransporter/LayoutTransporter'
import Modal from 'react-modal'
import toast from 'react-hot-toast'

Modal.setAppElement('#root')

function EditChauffeur() {
	const { register, handleSubmit, reset, control,formState: { errors } } = useForm()
	const { id } = useParams()
	const history = useHistory()
    const [chargementEnCours,setChargementEnCours]=useState(false)
	const [chauffeur, setChauffeur] = useState(null)
	const [suspendre, setSuspendre] = useState(false)
	const token = useSelector((state) => state.auth.token);
	const user = useSelector((state) => state.auth.user);

	const [modalIsOpenSuspendre,setModalIsOpenSuspendre]=useState(false)
	const [modalIsOpen,setModalIsOpen]=useState(false)

	const [loading, setLoading] = useState(false)

	const onSubmit = async (data) => {
		setLoading(true)
		try {
			const modifiableData = {
				permis:data.permis,
				fimo:data.fimo,
				danger:data.danger
			}
			await updateChauffeur(id, modifiableData,token)
			toast.success('Le compte du chauffeur a été mis à jour', {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		}finally{
			setLoading(false)
		}

		history.goBack()
	}



	async function _getChauffeur(id) {
		setChargementEnCours(true)
		try {
		const chauffeur = await getChauffeur(id,token)
		setChauffeur(chauffeur)
		setSuspendre(chauffeur.suspendre)
		reset(chauffeur)
		}catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		}finally{
			setChargementEnCours(false)
		}
		
	}



	useEffect(() => {
		_getChauffeur(id)	
	}, [id])

	return (
		<LayoutTransporter>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{'Modifer chauffeur'.toUpperCase()}
						</GradientText>
					</div>
				{chargementEnCours ?
				<div
				style={{
				  display: 'flex',
				  alignItems: 'center',
				  justifyContent: 'center',
				  backgroundColor: 'white',
				  marginTop:"10rem"
				}}
				>
				<div className='customLoader'></div>	
				</div>
				:
				<div
					style={{
						width: '70%',
						fontFamily: 'Montserrat',
					}}
				>
					<FormSectionCard>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<button
								onClick={ () => {
									setModalIsOpen(true)			
							
								}}
								style={{
									border: 'none',
									background: '#168EEA',
									color: '#FFF',
									fontSize: 17,
									fontFamily: 'Montserrat',
									fontWeight: 'bold',
									padding: '0.6rem 1rem',
									borderRadius: 5,
									cursor: 'pointer',
								}}
							>
								Enregistrer
							</button>
							<button
								onClick={ () => {
									setModalIsOpenSuspendre(true)			
							
								}}
								style={{
									border: 'none',
									background: '#ECC9F7',
									color: '#AB1CC5',
									fontSize: 17,
									fontFamily: 'Montserrat',
									fontWeight: 'bold',
									padding: '0.6rem 1rem',
									borderRadius: 5,
									cursor: 'pointer',
									marginLeft:"1rem"
								}}
							>
								{suspendre ? 'Activer' : 'Suspendre'}
							</button>
						</div>
					</FormSectionCard>
					<form onSubmit={handleSubmit(onSubmit)}>
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Sexe
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									<div
										style={{
											width: '30%',
										}}
									>
										<input
											type='radio'
											disabled
											style={{
												marginRight: '1rem',
												transform: 'scale(1.5)',
											}}
											{...register('sexe')}
											value={'homme'}
										/>

										<span>Homme</span>
									</div>

									<div>
										<input
											type='radio'
											disabled
											style={{
												marginRight: '1rem',
												transform: 'scale(1.5)',
											}}
											{...register('sexe')}
											value={'femme'}
										/>

										<span>Femme</span>
									</div>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Permis
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									{['Léger', 'Lourds', 'Super lourds'].map(
										(item, index) => (
											<div
												key={String(index)}
												style={{
													width: '20%',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
														transform: 'scale(1.5)',
													}}
													{...register('permis', {
														required: true,
													})}
													value={item}
												/>

												<span>{item}</span>
											</div>
										)
									)}
								</div>
								{errors.permis && (
                                     <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                     Veuillez sélectionner le type de permis
                                     </div>
                                )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='Doe'
										{...register('last_name')}
									/>
									
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Prénom
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='Doe'
										{...register('first_name')}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
										{...register('address')}
									/>
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville 
								</span>
								<CustomInput
									type='text'
									disabled
									{...register('city')}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal
								</span>
								<CustomInput
									type='text'
									disabled
									{...register('postalCode')}
								/>
								
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='(0) 01 45 66 32 88'
										{...register('phone')}
									/>
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse email
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='John.doe@wayne.fr'
										{...register('email')}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '2rem',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '50%',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Date de naissance
									</span>
									<Controller
									    
										name='date_naiss'
										control={control}
										render={({
											field: { onChange, value },
										}) => (
											<Flatpickr
											    disabled
												onChange={([date]) => {
													onChange(date)
												}}
												className='date-input'
												options={{
													mode: 'single',
													locale: French,
													dateFormat: 'd/m/Y',
												}}
												value={value}
											/>
											
										)}
									/>
									<div >
									</div>
								</div>

								<div style={{ width: 50 }} />

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '50%',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Date d’embauche
									</span>
									<Controller
										name='date_embauche'
										control={control}
										render={({
											field: { onChange, value },
										}) => (
											<Flatpickr
											    disabled
												onChange={([date]) => {
													onChange(date)
												}}
												className='date-input'
												options={{
													mode: 'single',
													locale: French,
													dateFormat: 'd/m/Y',
												}}
												value={value}
											/>
										)}
									/>
								<div/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Fimo
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									{['Oui'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
												type='radio'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('fimo',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
									{['Non'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
												type='radio'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('fimo',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
								</div>
								{errors.fimo && (
                            <div style={{ color: 'red',marginTop:'0.5rem'}}>
                              Veuillez sélectionner une option pour Fimo.
                             </div>
                             )}
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										marginBottom: '1rem',
									}}
								>
									Matière dangereuse
								</span>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									{['Oui'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
												type='radio'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('danger',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
										{['Non'].map((item, index) => (
										<div
											key={String(index)}
											style={{
												width: '20%',
											}}
										>
											<input
												type='radio'
												style={{
													marginRight: '1rem',
													transform: 'scale(1.5)',
												}}
												{...register('danger',{required:true})}
												value={item}
											/>

											<span>{item}</span>
										</div>
									))}
								</div>
								{errors.danger && (
                            <div style={{ color: 'red',marginTop:'0.5rem'}}>
                              Veuillez sélectionner une option pour Matière dangereuse.
                             </div>
                             )}
							</div>
						</FormSectionCard>

						<div style={{ height: '1rem' }} />

					</form>
				</div>
                }
			</div>
			<Modal
				isOpen={modalIsOpenSuspendre}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>{suspendre ? 'Activer Chauffeur' : 'Suspendre Chauffeur'}
		            </div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>
						{`Êtes-vous sûr de vouloir ${suspendre ? 'Activer' : 'Suspendre'} ce Chauffeur ?`}

						 </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							try {
								const data={suspendre:!suspendre,requesterId:user._id}
								const res=	await toggleSuspendreChauffeur(
										chauffeur._id,
										data,
										token
									)
									toast.success(res.message, {
										style: {
											fontFamily: 'Montserrat',
										},
									})
									setSuspendre(!suspendre)
									history.goBack()
								} catch (error) {
									if (error.response && error.response.status === 403) {
										toast.error(error.response.data.message, {
											style: {
												fontFamily: 'Montserrat',
											},
										});
									}else{
										toast.error("Une erreur s'est produite", {
											style: {
												fontFamily: 'Montserrat',
											},
										});
									}
								}finally {
									setModalIsOpenSuspendre(false)
									setLoading(false)
								}
							
                              
                            }}
							disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
						 
						 {loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)} 
					</button>
					<button
						onClick={async () => {
							setModalIsOpenSuspendre(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>
		   <Modal
				isOpen={modalIsOpen}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>{suspendre ? 'Activer Chauffeur' : 'Suspendre Chauffeur'}
		            </div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>
						{`Êtes-vous sûr de vouloir ${suspendre ? 'Activer' : 'Suspendre'} ce Chauffeur ?`}

						 </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							handleSubmit(onSubmit)()
							 }}
					    disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
						 
						 {loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)} 
					</button>
					<button
						onClick={async () => {
							setModalIsOpen(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>
		</LayoutTransporter>
	)
}

export default EditChauffeur
