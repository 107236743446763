import {
	FormContentSection,
	FormSectionCard,
	FormSectionTitle,
	Select,
	Seperator,
	StyledForm,
	StyledInput,
	StyledTextArea,

} from '../../Containers/PasserCommande/PasserCommande.styles'
import React, { useEffect, useRef ,useState} from 'react'

import {

	nombreEtagesAtom,
	optionsPoidsLourdsAtom,
	optionsVehiculeLegerAtom,
	rippeurHoursAtom,
	rippeursAtom,
	temporisationAtom,
	typeCommandeAtom,
	typeEtageAtom,
	typeTransportAtom,
	dangerAtom
} from '../../utils/price'
import { useForm, useWatch } from 'react-hook-form'
import vehiculeCamion from '../../assets/img/vehicule_camion.png'
import { CustomInput } from '../../Containers/RegisterTransporter/style'
import InformationsCourseACourseReadOnly from '../../Containers/CommandeReadOnly/InformationsCourseACourseReadOnly'
import InformationsMiseADispoReadOnly from '../../Containers/CommandeReadOnly/InformationsMiseADispoReadOnly'
import PoidsLours from '../../assets/img/poid_lourd@2x.png'
import VehiculeLeger from '../../assets/img/vehicule_leger@2x.png'
import tonnes7 from '../../assets/img/7.5.png'
import  tonnes19 from  '../../assets/img/tonnes19.png'
import Fourgon14 from '../../assets/img/fourgon12m.png'
import Fourgon12 from '../../assets/img/captureF12.png'
import Break from '../../assets/img/CaptureBreak.png'
import Semi from '../../assets/img/semi.png'
import tonnes12 from '../../assets/img/tonnes12.png'
import tracteurSemi from '../../assets/img/tracteur-actos.png'
import { useAtom } from 'jotai'
import { useOptimizedComponentWillMount } from '../../hooks'
import { useDispatch, useSelector } from 'react-redux'
import { BASE_URL } from '../../api/base'
import PhoneInput from 'react-phone-input-2'
import { Controller } from 'react-hook-form'
import UpdateUploadFileCommande from '../../Components/UploadFileCommande/UpdateUploadFileCommande'
function CommandeFormReadOnly({
	onSubmit,
	onError = () => {},
	loading = false,
	preloadForm = false,
	keepFilesAsObjects = false,
	preloadData,
	hideSubmit = false,
	hideSendSMS=false,
	disabled = false,
	submitButtonText = 'Payer',
	forceRefresh = false,
	setHeur,
	step,
	setStep,
	setIsStepOne,
	isStepOne
}) {
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		unregister,
		control,
		reset,
		getValues,
		formState: { errors, isValid, isDirty },
	} = useForm()
	const user = useSelector((state) => state.auth.user)
	const dispatch = useDispatch()
	const tarifMiseADispo = useSelector((state) => state.tarif.tarifMiseADispo)
	const tarifCourseACourse = useSelector((state) => state.tarif.tarifCourseACourse)
	const type_transport = useRef()
	type_transport.current = watch('type_transport', '')
	const type_commande = useRef()
	type_commande.current = watch('type_commande', '')
	const nature = useRef()
	nature.current = watch('nature', '')

	const livraison = useRef()
	livraison.current = watch('livraison', '')

	const assurance = useRef()
	assurance.current = watch('assurance', '')


	
	useOptimizedComponentWillMount(() => {
		if (preloadForm) {
			reset(preloadData)
			type_commande.current = preloadData.type_commande
			type_transport.current = preloadData.type_transport
			nature.current = preloadData.nature
			livraison.current = preloadData.livraison
			assurance.current = preloadData.assurance
		}
	})

	if (preloadForm && preloadData) {
		preloadData.type_commande &&
			(type_commande.current = preloadData.type_commande)
		preloadData.type_transport &&
			(type_transport.current = preloadData.type_transport)
		preloadData.nature && (nature.current = preloadData.nature)
		preloadData.livraison && (livraison.current = preloadData.livraison)
		preloadData.assurance && (assurance.current = preloadData.assurance)
	}

	const [typeCommande, setTypeCommande] = useAtom(typeCommandeAtom)
	const [typeTransport, setTypeTransport] = useAtom(typeTransportAtom)
	const [temporisation, setTemporisation] = useAtom(temporisationAtom)
	const [typeEtage, setTypeEtage] = useAtom(typeEtageAtom)
	const [danger,setDanger]=useAtom(dangerAtom)
	const [nombreEtages, setNombreEtages] = useAtom(nombreEtagesAtom)
	const [rippeurHours, setRippeurHours] = useAtom(rippeurHoursAtom)
	const [rippeurs, setRippeurs] = useAtom(rippeursAtom)
	const [showDetails, setShowDetails] = useState(false);

	const token = useSelector((state) => state.auth.token);
	const nature_chargement=useRef()
	nature_chargement.current=watch(`nature_chargement`,'')
	const toggleDetails = () => {
		setShowDetails(!showDetails);
	  };

	const [optionsPoidsLourds, setOptionsPoidsLourds] = useAtom(
		optionsPoidsLourdsAtom
	)
	const [optionsVehiculeLeger, setOptionsVehiculeLeger] = useAtom(
		optionsVehiculeLegerAtom
	)
 

	
  
   



	const debutValue = useWatch({
		name: 'debut',
		defaultValue: null,
		control,
	})

	const finValue = useWatch({
		name: 'fin',
		defaultValue: null,
		control,
	})
	
	  const handleOptionChange = (e) => {
		const selectedOption = e.target.value;
		
		// Si l'option sélectionnée est "Bachée" ou "Frigorifique"
		if (selectedOption === "Bachée" || selectedOption === "Frigorifique") {
		  // Désélectionner l'autre option si elle est sélectionnée
		  const otherOption = selectedOption === "Bachée" ? "Frigorifique" : "Bachée";
		  const otherOptionCheckbox = document.querySelector(`[value="${otherOption}"]`);
		  if (otherOptionCheckbox) {
			otherOptionCheckbox.checked = false;
			console.log("otherOptionCheckbox",otherOptionCheckbox.checked,otherOptionCheckbox.value)
		   
		} 
		  
		
		}
	  };
	  


	

	

	const typeCommandeValue = useWatch({
		name: 'type_commande',
		defaultValue: null,
		control,
	})

	const type_transport_details = useWatch({
		name: 'type_transport_details',
		defaultValue: null,
		control,
	})

	const options_poids_lourds = useWatch({
		name: 'vehicule_poids_lourds',
		defaultValue: null,
		control,
	})

	const temporisationValue = useWatch({
		name: 'temporisation',
		defaultValue: null,
		control,
	})

	const typeEtageValue = useWatch({
		name: 'type_etage',
		defaultValue: null,
		control,
	})

	const nombreEtagesValue = useWatch({
		name: 'etage',
		defaultValue: null,
		control,
	})

	const rippeurHoursValue = useWatch({
		name: 'heure_jour_rippeur',
		defaultValue: null,
		control,
	})
	const  dangerValue=useWatch({
		name: 'danger',
		defaultValue: null,
		control,
	})

	const rippeursValue = useWatch({
		name: 'rippeurs',
		defaultValue: null,
		control,
	})

	const optionsPoidsLourdsValue = useWatch({
		name: 'options_poids_lourds',
		defaultValue: [],
		control,
	})

	const optionsVehiculeLegerValue = useWatch({
		name: 'options_vehicule_leger',
		defaultValue: [],
		control,
	})

	

	useEffect(() => {
		if (typeCommandeValue) {
			setTypeCommande(typeCommandeValue)
		}
	}, [typeCommandeValue])

	useEffect(() => {
		if (type_transport_details) {
			setTypeTransport(type_transport_details)
		}
	}, [type_transport_details])
     useEffect(()=>{
        if(dangerValue){
			setDanger(dangerValue)
		}
	 },[dangerValue])
	useEffect(() => {
		if (options_poids_lourds && options_poids_lourds.length > 0) {
			setTypeTransport(options_poids_lourds)
		}
	}, [options_poids_lourds])

	useEffect(() => {
		if (temporisationValue) setTemporisation(temporisationValue)
	}, [temporisationValue])

	useEffect(() => {
		if (typeEtageValue) {
			setTypeEtage(typeEtageValue)
		}
	}, [typeEtageValue])

	useEffect(() => {
		if (nombreEtagesValue !== undefined) {
			setNombreEtages(nombreEtagesValue)
		}
	}, [nombreEtagesValue])

	useEffect(() => {
		if (rippeurHoursValue !== undefined) {
			setRippeurHours(rippeurHoursValue)
		}
	}, [rippeurHoursValue])

	useEffect(() => {
		if (rippeursValue !== undefined) {
			setRippeurs(rippeursValue)
		}
	}, [rippeursValue])

	useEffect(() => {
		if (optionsPoidsLourdsValue) {
			setOptionsPoidsLourds(optionsPoidsLourdsValue)
		}
	}, [optionsPoidsLourdsValue])

	useEffect(() => {
		if (optionsVehiculeLegerValue) {
			setOptionsVehiculeLeger(optionsVehiculeLegerValue)
			console.log("optionsVehiculeLegerValue",optionsVehiculeLeger)
		}
	}, [optionsVehiculeLegerValue])

	 
	  
	
	return (
		<StyledForm  id='form' onSubmit={handleSubmit(onSubmit, onError)}>
			
			<FormContentSection>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<FormSectionTitle>Type de transport</FormSectionTitle>
						
						<svg
							xmlns='http://www.w3.org/2000/svg'
							style={{
								width: 20,
								height: 20,
								marginTop: 10,
								marginLeft: 10,
							}}
							fill='none'
							viewBox='0 0 24 24'
							stroke='#51C7F2'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={2}
								d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
							/>
						</svg>
					</div>

					{!!preloadData?.prix && (
						<div
							style={{
								padding: '0rem 1em',
								background: '#838EAB',
								color: 'white',
								fontFamily: 'Montserrat',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
								// top: 250,
								// right: 50,
								borderRadius: '0.5rem',
								fontSize: 15,
							}}
						>
							<span>Prix: </span>
							<span style={{ width: 20 }} />
							<span style={{ fontWeight: 'bold' }}>
								{user?.role === 'client' ?
								 preloadData.prix.toFixed(2) : (preloadData.prix - (preloadData.prix * (preloadData.margin ?? 0)) / 100).toFixed(2)
								}€ ht
							</span>
						</div>
					)}
				</div>
				<FormSectionCard>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '1rem',
						}}
					>
						<div
							style={{
								width: '15rem',
							}}
						>
							<input
								type='radio'
								disabled={disabled}
								style={{
									marginRight: '1rem',
									transform: 'scale(1.5)',
								}}
								value={'course-a-course'}
								checked={preloadData?.type_commande === 'course-a-course'}
							/>

							<span>Course à course</span>
						</div>

						<div
							style={{
								width: '15rem',
							}}
						>
							<input
								style={{
									marginRight: '1rem',
								}}
								disabled={disabled}
								type='radio'
								value={'mise-a-disposition'}
								checked={preloadData?.type_commande === 'mise-a-disposition'}
							/>

							<span>Mise à disposition</span>
						</div>
					</div>

					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '50%',
							fontFamily: 'Montserrat',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Type de temporisation
						</span>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<div
							style={{
								width: '15rem',
							}}
						>
							<input
								style={{
									marginRight: '1rem',
								}}
								disabled={disabled}
								type='radio'
								value={'immediat'}
								checked={preloadData?.temporisation === 'immediat'}

							/>

							<span>Immédiat</span>
						</div>

						<div
							style={{
								width: '15rem',
							}}
						>
							<input
								style={{
									marginRight: '1rem',
								}}
								disabled={disabled}
								type='radio'
								value={'planifie'}
								checked={preloadData?.temporisation === 'planifie'}
							/>

							<span>Planifié</span>
						</div>
					</div>
					</div>
					
					
					
				</FormSectionCard>
			</FormContentSection>

			{preloadData?.type_commande === 'mise-a-disposition' ? (
				<InformationsMiseADispoReadOnly
				   unregister={unregister}
				   register={register}
					watch={watch}
					setValue={setValue}
					control={control}
					errors={errors}
					getValues={getValues}
					display={disabled}
					keepFilesAsObjects={preloadForm || keepFilesAsObjects}
					setHeur={setHeur}
					commande={preloadData}
					initialNumberOfMissions={preloadData?.missions.length}
					isActiverRippeur={user?.activerRippeur}
				/>
			) : (
				<InformationsCourseACourseReadOnly
				unregister={unregister}
				register={register} 
				watch={watch}
				setValue={setValue}
				control={control}
				errors={errors}
				getValues={getValues}
				display={disabled}
				setHeur={setHeur}
				hideSendSMS={hideSendSMS}
				initialNumberOfCourses={preloadData?.courses.length}
				keepFilesAsObjects={preloadForm || keepFilesAsObjects}
				commande={preloadData}
				/>
			)}

			{/* HERE */}

			
				<FormContentSection>
					<FormSectionTitle></FormSectionTitle>
					<FormSectionCard>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								//marginBottom: '2.5rem',
							}}
						>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
								Référence interne client
							</span>
							<StyledInput
								type='text'
								disabled={disabled}
								placeholder=''
								value={preloadData?.ref_client}
							/>
							
						</div>
					</FormSectionCard>
				</FormContentSection>
				{watch('type_commande')==="course-a-course" &&
			<>
		
			<FormContentSection>
				<FormSectionTitle></FormSectionTitle>
				<FormSectionCard>
					<p
						style={{
							color: 'black',
							//fontWeight: 'bold',
						}}
					>
						Y a t-il de la manutention chauffeur ?
					</p>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '2rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id='manutention_chauffeur-oui'
								type='radio'
								disabled={disabled}
								value='Oui'
								{...register('manutention_chauffeur', {
									required: true,
								})}
							/>

							<label htmlFor='manutention_chauffeur-oui'>
								Oui
							</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id='manutention_chauffeur-non'
								type='radio'
								disabled={disabled}
								value='Non'
								{...register('manutention_chauffeur', {
									required: true,
								})}
							/>

							<label htmlFor='manutention_chauffeur-non'>
								Non
							</label>
						</div>
					</div>
					
				</FormSectionCard>
			</FormContentSection>
			{user?.activerRippeur &&
			<FormContentSection>
				<FormSectionTitle>Rippeur</FormSectionTitle>
				<FormSectionCard>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}
						>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
								Nombre de ripper en plus du chauffeur
							</span>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
								( Facultatif )
							</span>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
								marginBottom: '1rem',
							}}
						>
							<div>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='13.605'
									height='27.839'
									viewBox='0 0 13.605 27.839'
									style={{
										marginRight: '.5rem',
									}}
								>
									<g id='user' transform='translate(0.5 0.5)'>
										<path
											id='Path_11624'
											d='M31.488,8.816A3.412,3.412,0,1,1,34.9,5.4,3.412,3.412,0,0,1,31.488,8.816Zm0-6.054a2.645,2.645,0,1,0,2.645,2.645A2.645,2.645,0,0,0,31.488,2.762Z'
											transform='translate(-25.195 -1.992)'
											fill='#51c7f2'
											stroke='#51c7f2'
											strokeWidth='1'
										/>
										<path
											id='Path_11625'
											d='M30.917,33.11h-.752a.383.383,0,1,1,0-.767h.752A1.484,1.484,0,0,0,32.4,30.861V24.829a1.7,1.7,0,0,0-1.7-1.7H23.03a1.7,1.7,0,0,0-1.7,1.7v6.034a1.484,1.484,0,0,0,1.484,1.481h.725a.383.383,0,1,1,0,.767h-.725a2.252,2.252,0,0,1-2.249-2.249V24.829A2.472,2.472,0,0,1,23.03,22.36H30.7a2.472,2.472,0,0,1,2.469,2.469v6.034A2.252,2.252,0,0,1,30.917,33.11Z'
											transform='translate(-20.562 -14.552)'
											fill='#51c7f2'
											stroke='#51c7f2'
											strokeWidth='1'
										/>
										<path
											id='Path_11626'
											d='M38.027,48.646A2.029,2.029,0,0,1,36,46.619V40.231a.383.383,0,0,1,.767,0v6.388a1.26,1.26,0,0,0,2.52,0V34.509a.383.383,0,0,1,.767,0v12.11A2.029,2.029,0,0,1,38.027,48.646Z'
											transform='translate(-30.081 -21.807)'
											fill='#51c7f2'
											stroke='#51c7f2'
											strokeWidth='1'
										/>
										<path
											id='Path_11627'
											d='M29.453,48.646a2.029,2.029,0,0,1-2.027-2.027V34.509a.383.383,0,0,1,.767,0v12.11a1.26,1.26,0,0,0,2.52,0V40.231a.383.383,0,0,1,.767,0v6.388A2.029,2.029,0,0,1,29.453,48.646Z'
											transform='translate(-24.794 -21.807)'
											fill='#51c7f2'
											stroke='#51c7f2'
											strokeWidth='1'
										/>
									</g>
								</svg>
							</div>

							<CustomInput
								width={'100%'}
								type='number'
								disabled={disabled}
								min={0}
								{...register('nombre_rippeur')}
							/>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}
						>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
								Nombre d'heure
							</span>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Select
								disabled={disabled}
								{...register('heure_rippeur',)}
								
							>
								
								{Array.from(
									{ length: 12 },
									(v, i) => i + 1
								).map((i) => (
									<option key={String(i)} value={String(i)}>
										{i}h
									</option>
								))}
							</Select>
						</div>
					</div>
					
				</FormSectionCard>
			</FormContentSection>
            }
			<FormContentSection>
				<FormSectionTitle>Lieu de livraison</FormSectionTitle>
				<FormSectionCard>
					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '2rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id='livraison-1'
								type='radio'
								disabled={disabled}
								value='Entrepot/Magasin/Chantier'
								{...register('livraison', {
									required: true,
								})}
							/>

							<label htmlFor='livraison-1'>
								Entrepot/Magasin/Chantier
							</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id='livraison-2'
								type='radio'
								disabled={disabled}
								value='Domicile/Batiment/Maison'
								{...register('livraison', {
									required: true,
								})}
							/>

							<label htmlFor='livraison-2'>
								Domicile/Batiment/Maison
							</label>

						</div>
					</div>
					
					<div>
						{livraison.current === 'Domicile/Batiment/Maison' && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
										width: '30%',
										marginRight: '1rem',
									}}
								>
									<span
										style={{
											marginRight: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Etage
									</span>
									
									<Select 
									style={{width:'100%'}}
									disabled={disabled}
									{...register(`etage`,{required: true})}
									>
									<option value='0' disabled={disabled}>0</option>
                                    <option value='1-10' disabled={disabled}>1-10</option>
									<option value='11-20' disabled={disabled}>11-20</option>
									<option value='21-30' disabled={disabled}>21-30</option>
									</Select>
								</div>

								<div
									style={{
										//width: '25%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
									}}
								>
									
                                     <Controller
                                      name={`escalier`}
                                      control={control}
                                     
                                      render={({ field }) => (
                                      <input
                                       type="checkbox"
                                       style={{ marginRight: '1rem' }}
                                       disabled={disabled}
                                       checked={field.value === true}
                                       onChange={e => field.onChange(e.target.checked ? true : false)}
                                      />
                                      )}
                                     />
									<span
										style={{
											marginRight: '0.5rem',
										}}
									>
										Escalier
									</span>
								</div>

								<div
									style={{
										//width: '25%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
									}}
								>
									<Controller
                                name={`assenceur`}
                                control={control}
                                
                                render={({ field }) => (
                                <input
                                type="checkbox"
                                style={{ marginRight: '1rem' }}
                                disabled={disabled}
                                checked={field.value === true}
                                onChange={e => field.onChange(e.target.checked ? true : false)}
                                />
                                )}
                                />

									<span
										style={{
											marginRight: '0.5rem',
										}}
									>
										Assenceur
									</span>
								</div>
							</div>
							
						)}
					</div>
					
				   
                 
				</FormSectionCard>
			</FormContentSection>
            
	        
		
			{['chauffeur','transporter'].indexOf(user?.role) === -1 &&
			 <FormContentSection>
				<FormSectionTitle>Assurance</FormSectionTitle>
				<FormSectionCard>
					<p
						style={{
							color: 'red',
							fontWeight: 'bold',
						}}
					>
						La plateforme prend en charge jusqu'a 100 000 euros de
						valeur marchandise.
					</p>

					<p
						style={{
							color: 'black',
							fontWeight: 'bold',
						}}
					>
						La valeur de votre chargement dépasse t'elle 100 000
						euros?
					</p>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '2rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id='assurance-oui'
								type='radio'
								disabled={disabled}
								value='Oui'
								{...register('assurance', {
									required: true,
								})}
							/>

							<label htmlFor='assurance-oui'>Oui</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id='assurance-non'
								type='radio'
								disabled={disabled}
								value='Non'
								{...register('assurance', {
									required: true,
								})}
							/>

							<label htmlFor='assurance-non'>Non</label>
						</div>
					</div>
					
					<div>
						{assurance.current === 'Oui' && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'center',
										//width: '30%',
										marginRight: '1rem',
									}}
								>
									<span
										style={{
											color: 'black',
											fontWeight: 'bold',
											marginBottom: '1rem',
										}}
									>
										Ci-dessous saisir la valeur du
										chargement afin que nous puissions
										avertir les assureurs
									</span>

									<div
										style={{
											position: 'relative',
											width: '100%',
										}}
									>
										<CustomInput
											width={'100%'}
											type='text'
											disabled={disabled}
											{...register('assurance_amount', {
												validate: (value) => {
													const numericValue = Number(value.replace(' ', ''));
													const onlyDigits = /^\d+$/; 
													return !isNaN(numericValue) && onlyDigits.test(value);
												},
													required: true,
											})}
										/>
										<span
											style={{
												position: 'absolute',
												right: '3rem',
												top: '1rem',
												color: 'red',
											}}
										>
											€
										</span>
									</div>
								</div>

								<p
									style={{
										color: 'black',
										fontWeight: 'bold',
									}}
								>
									Vous avez la possiblité de ne pas saisir la
									valur du chargment, cependant nous
									retiendrons la valeur de 100 000 euros. Cela
									conformément au conditions d'utilisation de
									notre plateforme.
								</p>
								
      

							</div>
						)}
					</div>
				</FormSectionCard>
			</FormContentSection>
            }
			</>
            }
			
			{watch('type_commande') === 'mise-a-disposition' && (
			<>
				<FormContentSection>
			 <FormSectionTitle></FormSectionTitle>
			 <FormSectionCard>
			 <div
						style={{
							display: 'flex',
							flexDirection: 'column',
							marginBottom: '2.5rem',
							width: '50%',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
							}}
						>
							<span
								style={{
									marginBottom: '1rem',
									color: 'black',
									fontSize: 14,
								}}
							>
								Joindre Documents (PDF)
							</span>
							{!disabled && (
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									(Facultatif)
								</span>
							)}
						</div>
					
							<UpdateUploadFileCommande
								initialFiles={watch('files')}
								name='file'
								onChange={(files) => {
									
										setValue(
											'files',
											files.map((file) => file)
										)
									
									
								}}
								commandeId={watch('_id')}
								missionId={null}
							/>
								{(watch(`files`) || []).map(
							(file, index) => (
								<div
									onClick={() => {
										window.open(`${BASE_URL}/${file?.path}`)
									}}
									key={String(index)}
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
										marginBottom: 20,
										cursor: 'pointer',
									}}
								>
									<img
										alt=''
										src={File || 'https://secure.gravatar.com/favicon.ico'}
										style={{
											height: 20,
											marginRight: '1rem',
										}}
									/>
									<span
										style={{
											marginRight: '1rem',
											width: '10rem',
										}}
									>
										{file?.name}
									</span>
								</div>
							)
						)}
						
					</div>
			 </FormSectionCard>
			</FormContentSection>
			<FormContentSection>
			<FormSectionTitle></FormSectionTitle>
			<FormSectionCard>
			<div
			>

					
					<div
				 style={{
					display:"flex",
					flexDirection: 'row',
					marginBottom: '2rem',
				 }}>
				<div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
				 <span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
				       Nom du signataire	
				</span>
				<StyledInput
							type='text'
							disabled={disabled}
							placeholder=''
							value={preloadData?.nom_signataire}
				/>
				
				</div>	
				
				<div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
				<span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
					Prénom du signataire
				</span>
				<StyledInput
							type='text'
							disabled={disabled}
							placeholder=''
							 value={preloadData?.prénom_signataire}

				/>
				
				</div>
			</div>
			<div
				 style={{
					display:"flex",
					flexDirection: 'row',
					marginBottom: '2rem',
				 }}>
				<div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
				 <span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
				    Numéro de Téléphone 1 du signataire	 
				</span>
						 	   <PhoneInput 
								country='fr'
								placeholder=''
								value={preloadData?.téléphone1_signataire}
								disabled={disabled}

								inputStyle={{
									paddingRight: '1.5rem',
									paddingTop:'1.5rem',
									paddingBottom:'1.5rem',
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex',
									width:'100%'
								
								}}
								

								  buttonStyle={{
									
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex'
								  }}
								
								/>

				</div>	
				<div
				 style={{
						display: 'flex',
						flexDirection: 'column',
						width:'50%',
						marginRight: '1rem',
						}}
				>
				<span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					    }}
						>
				         Numéro de Téléphone 2 du signataire	
				</span>
				            

						 	   <PhoneInput 
								country='fr'
                                
								placeholder=''
								value={preloadData?.téléphone2_signataire}
								disabled={disabled}

								inputStyle={{
									paddingRight: '1.5rem',
									paddingTop:'1.5rem',
									paddingBottom:'1.5rem',
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex',
									width:'100%'								
								}}
								

								  buttonStyle={{
									
									borderRadius: '0.5rem',
	                                border: 'none',
									fontFamily: 'Montserrat',
									color: '#858ea8',
									flex: '1',
									display: 'flex'
								  }}
								
								/>
						
				
				</div>
			</div>

			<div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse email  du signataire 
									</span>
									<CustomInput
										type='text'
										disabled={true}
										value={preloadData?.email_signataire}
									/>
									
								</div>
			
				 
            </div>
			</FormSectionCard>
			 </FormContentSection>
            </>
			)}
            
			
			

			{ 	type_commande.current==="course-a-course" &&
			 <>
			 <FormContentSection>
				<FormSectionTitle>Nature du chargement</FormSectionTitle>
				<FormSectionCard>
					
					
				<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '2rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`nature-chargement-1`}
								type='radio'
								value='vrac'
								disabled={disabled}
								{...register(`nature_chargement`, {
									required: true,
								})}
							/>

							<label htmlFor={`nature-chargement-1`}>
								Vrac
							</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',

							}}
						>
							<input
								id={`nature-chargement-2`}
								type='radio'
								value='palette'
								disabled={disabled}
								{...register(`nature_chargement`, {
									required: true,
								})}
							/>

							<label htmlFor={`nature-chargement-2`}>
								Palette
							</label>

						</div>
					
					</div>
					
					
					<div>
						{nature_chargement.current === 'palette'  && (
							<div style={{
								display: 'flex',
								flexDirection: 'column',
								marginBottom: '1rem',
		
							}}>
							
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											marginBottom: '1.5rem',
											width: '100%',
										}}
									>
										
											<>
												<span
													style={{
														marginBottom: '1rem',
														color: 'black',
														fontSize: 14,
													}}
												>
													Nombre de palettes 
												</span>
												<CustomInput
													width={'100%'}
													type='number'
													disabled={disabled}
													min={0}
													{...register( `nombre_palettes`,{required: true,})}
												/>
												
											</>
										
									</div>
								
		
						
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											//marginBottom: '2.5rem',
											width: '100%',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Décrire la nature des palettes	
										</span>
										<StyledTextArea
											type='text'
											rows={4}
											placeholder=''
											disabled={disabled}
											{...register(`nature_palettes`,{required: true,})}
										/>
										
									</div>
								
							</div>
							
						)}
						{nature_chargement.current==='vrac' && 
						   <div style={{
							position: 'relative',
							width: '100%',
						}}>
						   <CustomInput
							   width={'100%'}
							   type='number'
							   min={0}
							   required={true}
							   disabled={disabled}
							   {...register(`nombre_dimension`)}
						   />
						   <span 
						   style={{
							position: 'absolute',
							right: '3rem',
							top: '1rem',
						   }}>
							M³</span>
					   </div>
						}
					</div>
						
					
				</FormSectionCard>
			</FormContentSection>
			<FormContentSection>
				<FormSectionTitle>Détails Transport</FormSectionTitle>
				<p>
					<b>
						La plateforme prend en charge pour les poids lourds 30
						minutes au chargement et 30 minutes au déchargement, au
						delà 0,41€ la minutes. 10 min pour les Véhicules légers.
					</b>
				</p>
				<FormSectionCard>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							//marginBottom: '1rem',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Type de trasport
						</span>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								//marginBottom: '1rem',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '1rem',
								}}
							>
								<div
									style={{
										width: '15rem',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
									}}
								>
									<input
										type='radio'
										disabled={disabled}
										style={{
											marginRight: '1rem',
										}}
										{...register('type_transport', {
											required: true,
										})}
										value={'Véhicule léger'}
									/>

									<span
										style={{
											marginRight: '0.5rem',
										}}
									>
										Véhicule léger
									</span>
									<img
										src={VehiculeLeger}
										style={{
											width: 40,
										}}
										alt=''
									/>
								</div>

								<div
									style={{
										width: '15rem',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
									}}
								>
									<input
										style={{
											marginRight: '1rem',
										}}
										disabled={disabled}
										type='radio'
										{...register('type_transport', {
											required: true,
										})}
										value={'Poids lourds'}
									/>
                                   
									<span
										style={{
											marginRight: '0.5rem',
										}}
									>
										Poids lourds
									</span>

									<img
										src={PoidsLours}
										style={{
											width: 40,
										}}
										alt=''
									/>
								</div>
							</div>
						</div>
					</div>

					{type_transport.current && (
						<>
							<Seperator />

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									//marginBottom: '1rem',
								}}
							>
								{type_transport.current !== 'Poids lourds' && (
									<div 
									style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%'
									}}>
									<div  style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										}}
									>
										<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												
												display: 'flex',
												
												width: '48%', 
												marginRight: '2%', 
											}} >
												<div
												style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
												<img
										src={Break}
										style={{
											width: '100%',
											height: 'auto', // Pour conserver les proportions de l'image
											maxWidth: '120px', // Limiter la largeur de l'image
											maxHeight: '80px', // Limiter la hauteur de l'image
											objectFit: 'cover', // Ajuster la taille tout en conservant les proportions
										  }}
										alt=''
									     />
												</div>
											
											<div
												style={{
													width: '50%',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'type_transport_details',{required:true}
													)}
													value="Break"
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
												Break	
												</span>
												
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontWeight:"bold",
														fontSize:"14px",
													    marginRight: '0.5rem',
																												marginRight: '0.5rem',

													}}
												>
											1097 kg
											</span>
											</div>
											
											<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5', 
												}}>
													<span>Dimension en M3 : 6M3 </span>
                                                    <span>Longueur utile : 3750mm </span>  
                                                    <span>Largeur utile : 1956mm </span>  
                                                    <span>Hauteur utile : 1320mm </span>  
                                                    <span>Charge utiles : 1097kg </span>  
													<span>Nombre de palettes: 2 pal europe </span> 
												</div>	
											
										</div>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
											}} >
												<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
													<img
										src={Fourgon12}
										style={{
											width: '100%',
											height: 'auto',
											maxWidth: '120px',
											maxHeight: '80px',
											objectFit: 'cover',
										  }}
										alt=''
									     />
												</div>
												
											<div
												style={{
													
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'type_transport_details',{required:true}
													)}
													value="Fourgon 12m³"
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
												Fourgon 12m³	
												</span>
											
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
													    marginRight: '0.5rem',
														fontWeight: "bold"

													}}
												>
											1244 kg
											</span>
											</div>
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 12M3 </span>
													<span>Longueur utile : 3030mm </span>  
                                                    <span>Largeur utile : 2070mm </span>  
                                                    <span>Hauteur utile : 1320mm </span>  
                                                    <span>Charge utiles : 1244kg </span>  
													<span>Nombre de palettes: 5 pal europe</span> 
												</div>	
											
											
											</div>
											
											
										{/* ))} */}
								 	</div>
									</div> 	
									<div style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										}}
									>		
											<div
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%',
											}}
											>
										<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
	                                    <img
										src={Fourgon14}
										style={{
											width: '100%',
											height: 'auto',
											maxWidth: '120px',
											maxHeight: '80px',
											objectFit: 'cover',
										}}
										alt=''
									   />
									   </div>
											
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'type_transport_details',{required:true}
													)}
													value="Fourgon 14m³"
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
												Fourgon 14m³	
												</span>
												
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
														fontWeight: "bold",
														marginRight: '0.5rem',
													}}
												>
											1353 kg 
											</span>
											</div>
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 14M3 </span>
													<span>Longueur utile : 3680mm </span>  
                                                    <span>Largeur utile : 2070mm </span>  
                                                    <span>Hauteur utile : 1820mm </span>  
                                                    <span>Charge utiles : 1353kg </span>  
													<span>Nombre de palettes: 6 pal europe</span> 
												</div>	
											
											
											</div>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
											}} >
											<div 
											style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										src={vehiculeCamion}
										style={{
											width: '100%',
											height: 'auto',
											maxWidth: '110px',
											maxHeight: '80px',
											objectFit: 'cover',
										  }}
										alt=''
									     />
										 </div>
											<div
												style={{
													
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'type_transport_details',{required:true}
													)}
													value="Camion 20m³"
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
												Camion 20m³	
												</span>
												
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
											            marginRight: '0.5rem',
														fontWeight: "bold"
																			

													}}
												>
												1200 kg
											</span>
											</div>
											
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 20M3 </span>  
													<span>Longueur utile : 4320mm </span>  
                                                    <span>Largeur utile : 2070mm </span>  
                                                    <span>Hauteur utile : 2100mm </span>  
                                                    <span>Charge utiles : 1200kg </span>  
													<span>Nombre de palettes: 7 pal europe</span> 
												</div>	
											

											</div>
									</div>
									
									</div>
									</div>
								)}
							</div>

							{type_transport.current !== 'Poids lourds' && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										//marginBottom: '2.5rem',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Options Véhicule léger
									</span>
									
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											marginBottom: '1rem',
										}}
									>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												marginBottom: '1rem',
											}}
										>
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'options_vehicule_leger',
														{
															required: true,
														}
													)}
													value={'Frigorifique'}
													onChange={(e) => {
														
														const isChecked = e.target.checked;
														const updatedOptions = isChecked
															? [...optionsVehiculeLegerValue, 'Frigorifique']
															: optionsVehiculeLegerValue.filter(option => option !== 'Frigorifique');
															const bacheeIndex = updatedOptions.indexOf('Bachée');
															if (bacheeIndex !== -1) {
																updatedOptions.splice(bacheeIndex, 1);
															}
														setValue('options_vehicule_leger', updatedOptions);
														handleOptionChange(e)
													}
												}


												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Frigorifique
												</span>
											</div>
                                             {type_transport_details==="Camion 20m³" &&
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'options_vehicule_leger',
														{
														required: true,
														}
													)}
													value={'Bachée'}
													onChange={(e) =>{
														
														const isChecked = e.target.checked;
														const updatedOptions = isChecked
															? [...optionsVehiculeLegerValue, 'Bachée']
															: optionsVehiculeLegerValue.filter(option => option !== 'Bachée');
															const frigorifiqueIndex = updatedOptions.indexOf('Frigorifique');

															if (frigorifiqueIndex !== -1) {
																updatedOptions.splice(frigorifiqueIndex, 1);
															}
														setValue('options_vehicule_leger', updatedOptions);
														handleOptionChange(e)
													} }

												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Bachée
												</span>
											</div>
                                            }
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'options_vehicule_leger',
														{
															required: true,
														}
													)}
													value={'Tall'}
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Tall
												</span>
											</div>

											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'options_vehicule_leger',
														{
															required: true,
														}
													)}
													value={'Hayon'}
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Hayon
												</span>
											</div>
										</div>

										{type_transport.current == null && (
											<Seperator />
										)}
									</div>
								</div>
							)}

							{type_transport.current !== 'Véhicule léger' && (
								<div
                                    style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%'
									}}>
									<div style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
										}}
									>
										    <div 
											style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										    }}>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%', 
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={tonnes7}
											style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
											
												<div
												
												style={{
													width: '50%',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'vehicule_poids_lourds',{required:true}
													)}
													value='7,5 Tonnes'
													
												/>

												<span>7,5 Tonnes</span>
												
											</div>
											<div
											style={{
												marginLeft: 36,
											
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
														marginRight: '0.5rem',
														fontWeight: "bold"
													}}
												>
												2470 kg
											</span>
											</div>
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5', 
												}}>
													<span>Dimension en M3 : 30M3 </span>
													<span>Longueur utile : 6575mm </span>  
                                                    <span>Largeur utile : 2045mm </span>  
                                                    <span>Hauteur utile : 2400mm </span>  
                                                    <span>Charge utiles : 2470kg </span>  
													<span>Nombre de palettes: 16 pal europe</span> 
												</div>	
											
											</div>	
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%',
												marginTop:-5
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={tonnes12}
											style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
											
											<div
												
												style={{
													width: '50%',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
													
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'vehicule_poids_lourds',{required:true}
													)}
													value='12 Tonnes'
													
												/>

												<span>12 Tonnes</span>
											
                                              
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
											            marginRight: '0.5rem',
														fontWeight: "bold"

													}}
												>
												5600 kg
											</span>
											</div>
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 45M3 </span>
													<span>Longueur utile : 7540mm </span>  
                                                    <span>Largeur utile : 2480mm </span>  
                                                    <span>Hauteur utile : 2500mm </span>  
                                                    <span>Charge utiles : 5600kg </span>  
													<span>Nombre de palettes: 19 pal europe</span> 
												</div>	
											
											</div>
											</div>
									</div>
									</div>
									<div style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										    <div 
											style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										    }}>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%', 
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={tonnes19}
										    style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
												
											<div
												
												style={{
													width: '50%',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'vehicule_poids_lourds',{required:true}
													)}
													value='19 Tonnes'
													
												/>

												<span>19 Tonnes</span>
												
                                              
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
											            marginRight: '0.5rem',
														fontWeight: "bold"
													}}
												>
												9200 kg
											</span>
											</div>
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 58M3 </span>
													<span>Longueur utile : 9100mm </span>  
                                                    <span>Largeur utile : 2470mm </span>  
                                                    <span>Hauteur utile : 2490mm </span>  
                                                    <span>Charge utiles : 9200kg </span>  
													<span>Nombre de palettes: 22 pal europe</span> 
												</div>	
										
											</div>
											
									
											
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%',
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={Semi}
										    style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
												
												<div
												
												style={{
													width: '50%',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
													marginTop:'1.2rem'
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
														
													}}
													disabled={disabled}
													{...register(
														'vehicule_poids_lourds',{required:true}
													)}
													value='Tracteur+semi'
													
													/>

												<span>Tracteur+semi</span>
												
											</div>
											
											<div style={{
											display: 'flex',	
											flexDirection: 'column',
											background:'#f2f3f4',
											padding:10,
											width:'70%',
											borderRadius:'5px',
											marginTop:'10px',
											fontSize: '13px', 
											lineHeight: '1.5', 
												}}>
													 <span>Longueur utile : 2.45 M</span>  
                                                    <span>Largeur utile : 1.50 M</span>
                                                    <span>Hauteur utile : 1.60 M</span>  
                                                    <span>Volume utile : 6 M3</span>  
                                                    <span>Charge max : 1000 kg</span>  
													<span>Nombre de palettes: 32 pal europe</span>   
												</div>	
											</div>
											
											

											</div>
									</div>
									</div>
									
									<div style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
										}}
									>
										    <div 
											style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										    }}>
                                             
											 
											 <div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%',
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={tracteurSemi}
										    style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
												
												<div
												
												style={{
													width: '50%',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'vehicule_poids_lourds',{required:true}
													)}
													value='Tracteur'
													
													/>

												<span>Tracteur</span>
					
											</div>
								
											<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5', 
												}}>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Longueur utile : </span>  
													<span>2.45 M</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Largeur utile : </span>  
													<span>1.50 M</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Hauteur utile : </span>  
													<span>1.60 M</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Volume utile : </span>  
													<span>6 M3</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Charge max : </span>  
													<span>1000 kg</span>   
													</div>
												</div>	
											</div>
											
											</div>
									</div>
									</div>
                                    
									
									

									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Options poids lourds
										</span>
										{/* <div
											style={{
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													//marginBottom: '1rem',
												}}
											>
												{[
													'Frigorifique',
													'Bachée',
													'Tall',
													'Hayon',
												].map((item, index) => (
													<div
														key={String(index)}
														style={{
															width: '15rem',
															display: 'flex',
															alignItems:
																'center',
															justifyContent:
																'flex-start',
														}}
													>
														<input
															type='checkbox'
															style={{
																marginRight:
																	'1rem',
															}}
															disabled={disabled}
															{...register(
																'options_poids_lourds'
															)}
															value={item}
														/>

														<span>{item}</span>
													</div>
												))}
											</div>
										</div> */}
										<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											marginBottom: '1rem',
										}}
									>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												marginBottom: '1rem',
											}}
										>
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'options_poids_lourds',
														{
															required: true,
														}
													)}
													value={'Frigorifique'}
													onChange={(e) => {
														const isChecked = e.target.checked;
														const updatedOptions = isChecked
															? [...optionsPoidsLourdsValue, 'Frigorifique']
															: optionsPoidsLourdsValue?.filter(option => option !== 'Frigorifique');
															const bacheeIndex = updatedOptions.indexOf('Bachée');
															if (bacheeIndex !== -1) {
																updatedOptions.splice(bacheeIndex, 1);
															}
														setValue('options_poids_lourds', updatedOptions);
														handleOptionChange(e)
													}}

												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Frigorifique
												</span>
											</div>
											{options_poids_lourds !=="Tracteur" &&
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'options_poids_lourds',
														{
														required: true,
														}
													)}
													value={'Bachée'}
													onChange={(e) => {
														const isChecked = e.target.checked;
														const updatedOptions = isChecked
															? [...optionsPoidsLourdsValue, 'Bachée']
															: optionsPoidsLourdsValue.filter(option => option !== 'Bachée');
															const frigorifiqueIndex = updatedOptions.indexOf('Frigorifique');

															if (frigorifiqueIndex !== -1) {
																updatedOptions.splice(frigorifiqueIndex, 1);
															}
														setValue('options_poids_lourds', updatedOptions);
														handleOptionChange(e)}}

												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Bachée
												</span>
											</div>
                                            }
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'options_poids_lourds',
														{
															required: true,
														}
													)}
													value={'Tall'}
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Tall
												</span>
											</div>

											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={disabled}
													{...register(
														'options_poids_lourds',
														{
															//required: true,
														}
													)}
													value={'Hayon'}
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Hayon
												</span>
											</div>
										</div>

										
									</div>
									</div>
								</div>
							)}
						</>
					)}
					
					
				  
				</FormSectionCard>
			</FormContentSection>
            
			<FormContentSection>
				<FormSectionTitle></FormSectionTitle>
				<FormSectionCard>
					<p
						style={{
							color: 'black',
							//fontWeight: 'bold',
						}}
					>
						Y a-t-il un transport de matières dangereuses ?
					</p>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '2rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id='danger-oui'
								type='radio'
								disabled={disabled}
								value='Oui'
								{...register('danger', {
									required: true,
								})}
							/>

							<label htmlFor='danger-oui'>Oui</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id='danger-non'
								type='radio'
								disabled={disabled}
								value='Non'
								{...register('danger', {
									required: true,
								})}
							/>

							<label htmlFor='danger-non'>Non</label>
						</div>
					</div>
					
				</FormSectionCard>
			</FormContentSection>
			
			</>}
		
			<div>
			<FormContentSection>
				<FormSectionTitle>Matériels supplémentaire</FormSectionTitle>
				<FormSectionCard>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							//marginBottom: '2.5rem',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Matériels de manutention
						</span>

						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								flexDirection: 'row',
								marginBottom: '1rem',
							}}
						>
							{[
								'Diable',
								'Transpalette manuel',
								'Sangle',
							].map((item, index) => (
								<div
									key={String(index)}
									style={{
										width: '33%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
										marginBottom: '1rem',
									}}
								>
									<input
										type='checkbox'
										style={{
											marginRight: '1rem',
										}}
										disabled={disabled}
										{...register('manutention', {
											required: true,
										})}
										value={item}
										checked={preloadData?.manutention?.includes(item)}

									/>

									<span>{item}</span>
								</div>
							))}
						</div>
						
					</div>
					
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							marginBottom: '1rem',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Epi
						</span>

						

						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								flexDirection: 'row',
								//marginBottom: '1rem',
							}}
						>
							{[
								'Casque',
								'Gants',
								'Masque',
								'Lunettes',
								'Chaussure de sécurité',
								'Gilet de visibilité',
							].map((item, index) => (
								<div
									key={String(index)}
									style={{
										width: '33%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
										marginBottom: '1rem',
									}}
								>
									<input
										type='checkbox'
										style={{
											marginRight: '1rem',
										}}
										disabled={disabled}
										value={item}
										checked={preloadData?.epi?.includes(item)}

									/>

									<span>{item}</span>
								</div>
							))}
						</div>
					
					</div>
					
                    
				  <div
						style={{
							display: 'flex',
							flexDirection: 'column',
							//marginBottom: '2.5rem',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Transpalette électrique
						</span>
						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								flexDirection: 'row',
								//marginBottom: '1rem',
							}}
						>
							
							<div
									style={{
										width: '33%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
										marginBottom: '1rem',
									}}
								>
									<input
										type='checkbox'
										style={{
											marginRight: '1rem',
										}}
										disabled={disabled}
										checked={preloadData?.transpalette_électrique}
									/>

									<span>Transpalette électrique</span>
								</div>
						</div>
						</div>
				</FormSectionCard>
			</FormContentSection>

			
		
			</div>
		
		
			
		
		</StyledForm>
		
	)
}

export default React.memo(CommandeFormReadOnly)