import React from 'react'
import moment from 'moment'

function MissionHorizontal({
    commande,
	onClickDetails = () => {},
    onClickFacture=()=>{},
	onClickAvoir=()=>{},
}) {


	

	return (
        <div 
		style={{
			width: '100%',
			background: '#E4EAF0',
			padding: '1rem',
			borderRadius: 10,
			marginBottom: 10,
			
		}}> 
		<div style={{
		marginBottom:'1rem',
		display: 'flex', 
		flexDirection: 'row', 
		justifyContent: 'space-between'
		}}>
		<span
		style={{
			fontWeight: 'bold',
			color: '#51C7F2',
			fontSize: 13,
		}}
	>
		Mise a disposition {commande ? 'N°' + commande.__id : '' } /{' '}
		<span style={{ color: 'red' }}>
			{commande.temporisation === 'immediat'
				? 'Immédiat'
				: 'Planifier'}
		</span>
	    </span>
        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
		<button
			onClick={onClickDetails}
			style={{
					padding: '0.5rem 1.5rem',
					background: '#51C7F2',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					marginTop: 5,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
					width:'130px',
					
					}}
				>
			Voir détails
		</button>
       
				<button
					onClick={onClickFacture}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#99ff99',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
                        marginLeft:".5rem"
					}}
				>
					Voir Facture
				</button>
                { commande?.missions?.every((mission) => mission.statut === 'annuler' && mission.statut_remboursement==="remboursé") &&
				<button
					onClick={onClickAvoir}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#99ff99',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
                        marginLeft:".5rem"

					}}
				>
					Voir Avoir
				</button>
                }             
                
        
       </div>
		</div>
		<div>
		<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ width: '100%' }}>
        {commande.missions.map((mission, index) => (
            <div key={index}> 
                <span
                    style={{
                        fontWeight: 'bold',
                        color: '#51C7F2',
                        fontSize: 13,
                    }}
                >
                    Mission  {' '}
                </span>
                <div  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <div
                    style={{
                        width: '100%',
                        background: '#E4EAF0',
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '1rem',
                        borderRadius: 10,
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            color: '#838EAB',
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: 13,
                        }}
                    >
                        <span
                            style={{
                                marginRight: 13,
                                marginTop: "0.5rem",
                                marginBottom: '0.5rem',
                                color: '#838EAB',
                                width: '15rem',
                            }}
                        >
                            Adresse de début : {' '}
                            <span style={{ color: "red" }}>
                                {mission?.adresse_debut?.label}
                            </span>
                        </span>
                        <span
                            style={{
                                marginRight: 13,
                                marginBottom: '0.5rem',
                                color: '#838EAB',
                                width: '15rem',
                            }}
                        >
                            Adresse de fin : {' '}
                            <span style={{ color: "red" }}>
                                {mission?.adresse_fin?.label}
                            </span>
                        </span>
                        <span
                            style={{
                                marginRight: 13,
                                marginBottom: '0.5rem',
                                color: '#838EAB',
                                width: '15rem',
                            }}
                        >
                            Date de début : {' '}
                            <span style={{ color: "red" }}>
                                {mission?.date_debut} {mission?.heure_debut} {' '}
                            </span>
                        </span>
                        <span
                            style={{
                                marginRight: 13,
                                marginBottom: '0.5rem',
                                color: '#838EAB',
                                width: '15rem',
                            }}
                        >
                            Date de fin:{' '}
                            <span style={{ color: "red" }}>
                                {mission?.date_fin} {mission?.heure_fin} {' '}
                            </span>
                        </span>
                        <span
					    style={{color: '#838EAB',marginBottom: '0.5rem'}}>
					    Statut de la mission :{' '}
					    <span style={{ color: 'red' }}>
					    {mission.statut}
					    </span>
                        </span>
                    </div>
					<div
                    
					style={{
						//padding: '0.5rem 2rem',
						display: 'flex',
						flexDirection: 'column',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
                    

                    {mission?.date_debut_reel &&
					<span
                            style={{
                                marginRight: 13,
                                marginBottom: '0.5rem',
                                color: '#838EAB',
                                width: '15rem',
                            }}
                        >
                            Date de début réelle : {' '}
                            <span style={{ color: "red" }}>
                                {moment(mission?.date_debut_reel).format('DD/MM/YYYY HH:mm')}{' '}
                            </span>
                        </span>
                    }
                    {mission?.date_fin_reel &&
                        <span
                            style={{
                                marginRight: 13,
                                marginBottom: '0.5rem',
                                color: '#838EAB',
                                width: '15rem',
                            }}
                        >
                            Date de fin réelle:{' '}
                            <span style={{ color: "red" }}>
                            {moment(mission?.date_fin_reel).format('DD/MM/YYYY HH:mm')}{' '}
                            </span>
                        </span>
                    }
                    
					
				   </div>
                   <div
					style={{
						display: 'flex',
						flexDirection: 'column',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
                    <div
				 style={{
					 background: 'white',
					 padding: '0.2rem .2rem',
					 display: 'flex',
					 flexDirection: 'row',
					 color: '#838EAB',
					 fontSize: 13,
					 borderRadius: 5,
					 marginBottom: 3,
					 marginTop:2
				 }}
			 >
				 <span
					 style={{
						 marginRight: 15,
						 display: 'flex',
						 flexDirection: 'column',
						 fontWeight: 'bold',
						 fontSize: 14,
						 padding: '1rem 0.3rem',
					 }}
				 >
					 <span>
					     Montant:  {' '}
						 {
						   mission?.prix.toFixed(2)
						 }€
					 </span>
				 </span>
			     </div>
                 {(mission?.statut_remboursement==="remboursé")&&
				 <div
				 style={{
					 background: 'white',
					 padding: '0.2rem .2rem',
					 display: 'flex',
					 flexDirection: 'row',
					 color: '#838EAB',
					 fontSize: 13,
					 borderRadius: 5,
					 marginBottom: 3,
					 marginTop:2
				 }}
			 >
				 <span
					 style={{
						 marginRight: 15,
						 display: 'flex',
						 flexDirection: 'column',
						 fontWeight: 'bold',
						 fontSize: 14,
						 padding: '1rem 0.3rem',
					 }}
				 >
					 <span>
					     Montant Remboursé: {' '}
						 {
						   mission?.prix.toFixed(2)
						 }€
					 </span>
				 </span>
			     </div>
				}

                </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '0.5rem',
                            marginRight: '0.5rem',
                            fontSize: 13,
                        }}
                    >
                        <span
                            style={{
                                color: '#838EAB',
                                marginBottom: '0.5rem',
                            }}
                        >
                            Nombre de kilométrage :{' '}
                            <span style={{ color: 'red' }}>
                                {mission.kilometrage}
                            </span>
                        </span>
                        <span 
                        style={{ color: '#838EAB',
                        marginBottom: '0.5rem',}}>
                            Nombre de ripper en plus du chauffeur :{' '}
                            <span style={{ color: 'red' }}>
                                {(mission.nombre_rippeur === null || mission.nombre_rippeur === undefined) ? '0' : mission.nombre_rippeur}
                            </span>
                        </span>
                        <span
					    style={{color: '#838EAB',marginBottom: '0.5rem'}}>
					    Statut de remboursement :{' '}
					    <span style={{ color: 'red' }}>
					    {mission.statut_remboursement}
					    </span>
				        </span>
                        <span style={{ color: '#838EAB' ,marginBottom: '0.5rem',}}>
                           Date d'annulation: {' '}                           
                            <span style={{ color: 'red' }}>
                                {moment(mission?.date_annulation).format('DD/MM/YYYY')}
                            </span>
                        </span>
                        
                    </div>
                    
                    
                             
                </div> 
               
                
           
                </div>
        
            </div>
        ))}
    </div>
   
        </div>
		
		</div>
		</div>
	)
}

export default React.memo(MissionHorizontal)