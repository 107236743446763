import LayoutCommercant from '../../Components/LayoutCommercant/LayoutCommercant'
import {
	FormSectionCard,
	GradientText,
	SubmitButton,
	SubmitButtonContainer,
	Wrapper,
} from '../PasserCommande/PasserCommande.styles'
import React, { useEffect, useState } from 'react'
import { getSingleClient,updateClient } from '../../api/clients'
import { useSelector } from 'react-redux'
import { CustomInput } from '../RegisterTransporter/style'
import File from '../../assets/img/file@2x.png'
import { PUBLIC } from '../../api/base'
import toast from 'react-hot-toast'
import { useForm ,Controller} from 'react-hook-form'
import { useHistory,useParams } from 'react-router'
import Switch from 'react-switch'
function ClientAccountForCommercial() {
  const {id}=useParams()
	const history=useHistory()

	const {
		handleSubmit,
		formState: { errors },
		control
	} = useForm()
  const [client, setClient] = useState()
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user) 
  const [chargementEnCours, setChargementEnCours] = useState(false);
  const [loading,setLoading]=useState(false)

  async function fetchClient(id) {
	setChargementEnCours(true)
	try {
		const data = await getSingleClient(id,token)
		setClient(data)
	} catch (error) {
		toast.error("Une erreur s'est produite", {
			style: {
			  fontFamily: 'Montserrat',
			},
		  });
	}finally{
		setChargementEnCours(false)

	}
    
   }
 useEffect(() => {
   if (id) {
     fetchClient(id)
   }
 }, [id])
  
  

	const onSubmit = async (values) => {
		if(values.reduction && values.reduction>user.reduction){
			toast.error(`La réduction ne peut pas dépasser ${user.reduction}`,
						{
							style: {
								fontFamily: 'Montserrat',
							},
		})
	    }
		try {
		setLoading(true)
		const updatedFormValues = {
				...values,
				reduction: values.reduction ?? client.reduction
		};
		await updateClient(id, updatedFormValues,token)
		toast.success('Votre compte du client  a été mis à jour', {
					  style: {
						  fontFamily: 'Montserrat',
					  },
				  })
		history.goBack()
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		}finally{
			setLoading(false)

		}
	}
	
  return (
    
  <LayoutCommercant>
    	<Wrapper>
		<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginTop:'2rem'
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{'Compte  Client'.toUpperCase()}
						</GradientText>
					</div>
			</Wrapper>
			{chargementEnCours ?
				<div
				style={{
				  display: 'flex',
				  alignItems: 'center',
				  justifyContent: 'center',
				  backgroundColor: 'white',
				  marginTop:"10rem"
				}}
				>
				<div className='customLoader'></div>	
				</div>
				:
				
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<div
					style={{
						width: '65%',
						fontFamily: 'Montserrat',
						alignSelf: 'center',
					}}
				>
				
					<form onSubmit={handleSubmit(onSubmit)}>
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom société
									</span>
									<CustomInput
										type='text'
										disabled
										placeholder='Wayne'
										defaultValue={client?.societe}
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
										}}	
									/>
								</div>
								<div style={{ width: '1rem' }} />
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										SIRET
									</span>
									<CustomInput
										type='text'
										disabled
										defaultValue={client?.siret}
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
										}}	
									/>
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom
									</span>
									<CustomInput
							            disabled
										type='text'
										defaultValue={client?.last_name}
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
										}}	
									/>
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Prénom
									</span>
									<CustomInput
										type='text'
										disabled
										defaultValue={client?.first_name}
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
										}}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Qualité
									</span>
									<CustomInput
                                       disabled
										type='text'
										defaultValue={client?.quality}
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
										}}
										
									/>
								     </div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Domaine d’activité
									</span>
									<CustomInput
										type='text'
										disabled
										defaultValue={client?.domain}
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
										}}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Adresse
								</span>
								<CustomInput
                                    disabled
									type='text'
									defaultValue={client?.address}
									style={{
										backgroundColor: '#f0f0f0', 
										color: '#a9a9a9',           
										cursor: 'not-allowed'       
									}}
								/>
								
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone
									</span>
									
									
								    </div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Adresse email
									</span>
									<CustomInput
										type='text'
										disabled
										defaultValue={client?.email}
										style={{
											backgroundColor: '#f0f0f0', 
											color: '#a9a9a9',           
											cursor: 'not-allowed'       
										}}
									/>
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											 Réduction en %
										</span>
									</div>
									
									<Controller
                                    name="reduction"
                                    control={control}
									defaultValue={client?.reduction }
                                    render={({ field }) => (
									 <CustomInput
									type='number'
									min={0}
									max={100}
									{...field} 
									/>
                                    )}
                                    />
								</div>

								 
								
							</div>
							<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
							     TVA
								</span>
								
										<Switch
											checked={client?.tva}
											disabled
											offColor='#F3F6F9'
											onColor='#F3F6F9'
											checkedIcon={false}
											uncheckedIcon={false}
											offHandleColor='#BFBFBF'
											onHandleColor='#168EEA'
											height={40}
											width={80}
											boxShadow='0px 5px 7px 0px rgba(0,0,0,0.23)'
										/>
									
								
							</div>
							<div style={{ width: 50 }} />
                            
							 <div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
							    Rippeurs
								</span>
								
										<Switch
											checked={client?.activerRippeur}
											disabled	
											offColor='#F3F6F9'
											onColor='#F3F6F9'
											checkedIcon={false}
											uncheckedIcon={false}
											offHandleColor='#BFBFBF'
											onHandleColor='#168EEA'
											height={40}
											width={80}
											boxShadow='0px 5px 7px 0px rgba(0,0,0,0.23)'
										/>
									
								
							</div> 
						    </div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '1rem',
									width: '50%',
									fontFamily: 'Montserrat',
									marginTop: 10,
								}}
							>
								{client?.logo &&
								<>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
										<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Logo
									</span>
								</div>
								<div>
									
									<img
										alt=''
										style={{
											width: 100,
											height: 100,
											borderRadius: 5,
											objectFit: 'cover',
										}}
										src={`${PUBLIC}/${client?.logo?.name}`}
									/>
								</div>
								</>
                                }
								
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									width: '50%',
									fontFamily: 'Montserrat',
									//marginLeft: 10,
									marginTop: 10,
								}}
							>
								{client?.kbis &&
								
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										KBIS
									</span>
									
								</div>
									
                                }
								{client?.kbis &&
								client?.kbis?.mimetype !==
									'application/pdf' && (
									<img
										alt=''
										style={{
											width: 100,
											height: 100,
											borderRadius: 5,
											objectFit: 'cover',
										}}
										src={`${PUBLIC}/${client?.kbis?.name}`}
									/>
								)
								}
                                {client?.kbis &&
								client?.kbis?.mimetype ===
									'application/pdf' && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<img
											alt=''
											src={File}
											style={{
												height: 20,
												marginRight: '1rem',
											}}
										/>
										<span
											style={{
												cursor: 'pointer',
											}}
											onClick={() => {
												window.open(
													`${PUBLIC}/${client?.kbis?.name}`
												)
											}}
										>
											{client?.kbis?.name}
										</span>
									</div>
								)}
								{client?.piece_identite &&
								<>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
											Pièce d'identité
									</span>
									
								</div>
								<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<img
											alt=''
											src={File}
											style={{
												height: 20,
												marginRight: '1rem',
											}}
										/>
										<span
											style={{
												cursor: 'pointer',
											}}
											onClick={() => {
												window.open(
													`${PUBLIC}/${client?.piece_identite?.name}`
												)
											}}
										>
											{client?.piece_identite?.name}
										</span>
									</div>
								</>
                                }
							</div>
							
						</FormSectionCard>
						<div style={{ height: '1rem' }} />
						<SubmitButtonContainer>
							<SubmitButton type='submit'>
								{loading ? (
									<div className='loader'></div>
								) : (
									'Enregistrer'
								)}
							</SubmitButton>
						</SubmitButtonContainer>
					
             
					</form>
				</div>
			</div>
           }
  </LayoutCommercant>
  )
}

export default ClientAccountForCommercial