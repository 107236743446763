import {
	FormContentSection,
	FormSectionCard,
	FormSectionTitle,
	GradientText,
	StyledTextArea,
} from '../../PasserCommande/PasserCommande.styles'
import { CustomInput } from '../../RegisterTransporter/style'
import React, { useEffect, useState } from 'react'
import { getSignalement } from '../../../api/signalements'
import { useHistory, useParams } from 'react-router-dom'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import UploadMultipleFiles from '../../../Components/UploadMultipleFiles/UploadMultipleFiles'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { StyledInput } from '../../PasserCommande/PasserCommande.styles'
import PhoneInput from 'react-phone-input-2'
function DetailsSignalementAdmin() {
    const history = useHistory()
	const {  signalement_id } = useParams()
	const token = useSelector((state) => state.auth.token);
	const [signalement, setSignalement] = useState(null)
	const [chargementEnCours, setChargementEnCours] = useState(false);
    useEffect( () => {
		async function _getSignalement(_id) {
			setChargementEnCours(true)
			try {
				const data = await getSignalement(_id,token)
				setSignalement(data)
			} catch (error) {
				toast.error("Une erreur s'est produite", {
					style: {
						fontFamily: 'Montserrat',
					},
				});
			}finally{
				setChargementEnCours(false)
			}
		}
		signalement_id && _getSignalement(signalement_id)
		
	}, [signalement_id])
    const typeLabels = {
		client_absent: "Client absent",
		retard_aux_chargement: "Retard aux chargement",
		livraison_reportée: "Livraison reportée",
		retard_aux_déchargement: "Retard aux déchargement",
		marchandises_manquantes_chargement: "Marchandises manquantes/chargement",
		marchandise_refusée: "Marchandise refusée",
		marchandises_incomplètes: "Marchandises incomplètes",
		marchandise_abîmée: "Marchandise abîmée",
		route_inaccessible_fermée: "Route inaccessible ou fermée",
		circulation_dense: "Circulation dense"
	  };
    return (
		<LayoutAdmin>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
			
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							onClick={() => {
								history.goBack()
							}}
							style={{
								background: '#51C7F2',
								width: '3rem',
								height: '3rem',
								borderRadius: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width={14.017}
								height={14.11}
								viewBox='0 0 14.017 14.11'
							>
								<g
									data-name='Group 11287'
									fill='none'
									stroke='#fff'
									strokeWidth={2}
								>
									<path
										data-name='Path 11687'
										d='M16.6 11.818l-6.348 6.349 2.115 2.115 4.232 4.232'
										transform='translate(-8.838 -11.113)'
									/>
									<path
										data-name='Path 11688'
										d='M22.147 11.818l-6.348 6.349 6.348 6.348'
										transform='translate(-8.838 -11.113)'
									/>
								</g>
							</svg>
						</div>

						<div
							style={{
								width: '1rem',
							}}
						/>

						<GradientText marginTop={'unset'}>
							{'DÉTAIL signalement'.toUpperCase()}
						</GradientText>
					</div>
                    {chargementEnCours ? 
						<div
						style={{
						  display: 'flex',
						  alignItems: 'center',
						  justifyContent: 'center',
						  backgroundColor: 'white',
						  marginTop:'10rem'
						}}
						>
						<div className='customLoader'></div>	
						</div> 
					:
                    <div style={{ width: '65%' }}>
						<FormContentSection>
								<FormSectionTitle></FormSectionTitle>
								<FormSectionCard>
								<div 
								style={{
									display: 'flex',
									flexDirection: 'column',
									 marginBottom: '2rem'}}>
									<span
									style={{
										marginBottom: '1rem',
									  color: 'black',
									  fontSize: '14px',
									  display: 'block',
									  fontWeight: 'bold',
									}}
									
								>
									 Type d'anomalie 
								</span>
                                <CustomInput 
                                  type='text'
                                  value={typeLabels[signalement?.type]}
                                  disabled={true}
                                />
									
									</div>
								</FormSectionCard>
						</FormContentSection>
                        <FormContentSection>
						<FormSectionTitle>
					
						</FormSectionTitle>

						<FormSectionCard>
						<div
                    style={{
                        display: "flex",
                        flexDirection: 'row',
                        marginBottom: '1rem',
                        fontFamily: 'Montserrat',

                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',

                        }}
                    >
                        <span
                            style={{
								color: 'black',
								fontSize: '14px',
								display: 'block',
								fontWeight: 'bold',
								paddingBottom: '.5rem',
     

                            }}
                        >
                            Nom du destinataire 
                        </span>
						<StyledInput
                                    value={signalement?.points_passage?.nom_destinataire}
									disabled={true}
                                    type="text"
                         />
						
                    </div>
					
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',
                        }}
                    >
                        <span
                            style={{
								color: 'black',
								fontSize: '14px',
								display: 'block',
								fontWeight: 'bold',
								paddingBottom: '.5rem',


                            }}
                        >
                            Prénom du destinataire
                        </span>
						<StyledInput
                            value={signalement?.points_passage?.prénom_destinataire}
						    disabled={true}
                            type="text"
                         />
                    </div>
                        </div>
						<div
                    style={{
                        display: "flex",
                        flexDirection: 'row',
                        marginBottom: '1rem',
                        fontFamily: 'Montserrat',

                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',

                        }}
                    >
                        <span
                            style={{
                                color: 'black',
								fontSize: '14px',
							    display: 'block',
								fontWeight: 'bold',
								paddingBottom: '.5rem',


                            }}
                        >
                            Adresse du destinataire
                        </span>
                     
                                <StyledInput
								    value={signalement?.points_passage?.adresse_destinataire}
                                    type="text"
									disabled={true}
                                />
                          
                    
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%',
                            marginRight: '1rem',
                        }}
                    >
                        <span
                            style={{
                                color: 'black',
									  fontSize: '14px',
									  display: 'block',
									  fontWeight: 'bold',
									  paddingBottom: '.5rem',


                            }}
                        >
                            Numéro de Téléphone du destinataire
                        </span>
                       
                            
                                <PhoneInput
                                    value={signalement?.points_passage?.téléphone_destinataire}
                                    country={'fr'}
									disabled={true}
                                    inputStyle={{
                                        paddingRight: '1.5rem',
                                        paddingTop: '1.5rem',
                                        paddingBottom: '1.5rem',
                                        borderRadius: '0.5rem',
                                        border: 'none',
                                        fontFamily: 'Montserrat',
                                        color: '#858ea8',
                                        flex: '1',
                                        display: 'flex',
                                        width: '100%'
                                    }}
                                    buttonStyle={{
                                        borderRadius: '0.5rem',
                                        border: 'none',
                                        fontFamily: 'Montserrat',
                                        color: '#858ea8',
                                        flex: '1',
                                        display: 'flex'
                                    }}
                                />
                            
                       
                    </div>
                </div>

							<div
                style={{
                        display: "flex",
                        flexDirection: 'row',
                 }}>
				<div
				style={{
					width: '50%',
					display: 'flex',
					flexDirection: 'column',
					paddingBottom: '2rem',
				}}> 

				<span
										style={{
											
											color: 'black',
											fontSize: '14px',
											display: 'block',
											fontWeight: 'bold',
											paddingBottom: '.5rem',
										}}
									>
										Adresse email  du destinataire 
									</span>
									<CustomInput
										type='text'
										disabled={true}
										value={signalement?.points_passage?.nom_destinataire}
									/>
				</div>
				     
                             </div>	
							
								

							
					</FormSectionCard>
					</FormContentSection>
					

					<FormContentSection>
						<FormSectionTitle></FormSectionTitle>
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '2.5rem',
									width: '100%',
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
										fontWeight: 'bold',
									}}
								>
									Note
								</span>
								<StyledTextArea
									type='text'
									rows={4}
									placeholder=''
									disabled={true}
                                    value={signalement?.note}
								/>
								
							</div>

							
								{signalement?.files && (
									<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '1rem',
										width: '100%',
										fontFamily: 'Montserrat',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
												fontWeight: 'bold',
	
											}}
										>
                                            Fichiers joints
										</span>
										
									</div>
										<UploadMultipleFiles
										    disabled= {false}
											allowDelete={false}
											name='files'
											initialFiles={signalement.files}
										/>
										</div>
									)}
								

							
						</FormSectionCard>
					</FormContentSection>

				
				   </div>
						

				
                    }
				</div>
		</LayoutAdmin>
	)
}

export default DetailsSignalementAdmin
