import {
	FormContentSection,
	FormSectionCard,
	FormSectionTitle,
	GradientText,
	SubmitButton,
	SubmitButtonContainer,
} from '../PasserCommande/PasserCommande.styles'
import React, {useRef, useState,useMemo } from 'react'
import countryList from 'react-select-country-list'
import Select from 'react-select'
import AddVehicleRegister from '../../Components/AddVehicle/AddVehicleRegister'
import CommonHeader from '../../Components/CommonHeader/common-header.component'
import { CustomInput } from './style'
import Footer from '../../Components/Footer/footer.component'
import Modal from 'react-modal'
import UploadFile from '../../Components/UploadSingleFile/upload.component'
import { signup } from '../../api/auth'
import toast from 'react-hot-toast'
import { useForm ,Controller} from 'react-hook-form'
import { useHistory } from 'react-router'

Modal.setAppElement('#root')

function RegisterTransporter() {
	const { register,control, handleSubmit, watch, setValue,formState: { errors} } = useForm()
	
	const password = useRef()
	password.current = watch('password', '')

	const history = useHistory()

	const [loading, setLoading] = useState(false)

	const [modalIsOpen, setModalIsOpen] = useState(false)
	const options = useMemo(() => countryList().getData(), [])
	const [valueCountry, setValueCountry] = useState('')
	const changeHandler = value => {
		setValueCountry(value)
		}

	const onSubmit = async (data) => {
		if (data.password !== data.confirm_password) {
			toast.error('Veuillez vérifier votre mot de passe', {
			  style: {
				fontFamily: 'Montserrat',
			  },
			});
			return;
		  }
		
		
		delete data.confirm_password
		if(data.pays.value==="FR"){
            data.tva=true
		}
		const files = [];
		const fileFields = [
		  'fiscale', 
		  'urssaf', 
		  'assurance_marchandises', 
		  'kbis', 
		  'licence_moins35t', 
		  'licence_plus35t',
		  'capacite_transport', 
		  'piece_identite', 
		  'rib',
		  'logo'
		];
	  
		fileFields.forEach(field => {
		  if (data[field]) {
			files.push({
			  [field]: data[field] 
			});
			delete data[field];  
		  }
		});
		  if (Array.isArray(data.vehicules)) {
			data.vehicules.forEach((vehicule, index) => {
			  const { immatriculation, carte_grise } = vehicule;
		  
			  if (carte_grise) {
				files.push({
				  [`carte_grise_${index}_${immatriculation}`]: carte_grise, 
				});
				delete vehicule.carte_grise; 
			  }
			});
		  }
		 delete data.logo

		setLoading(true)
		try {
			await signup({...data,role: 'transporter',},files)
			setLoading(false)
			setModalIsOpen(true)
		} catch (error) {
			const data = error?.response?.data?.errors
			if (data?.msg === 'EMAIL_ALREADY_EXISTS') {
				toast.error(
					'Ce mail est déjà utilisé par un autre utilisateur.',
					{
						style: {
							fontFamily: 'Montserrat',
						},
					}
				)
			}else{
				toast.error("Une erreur s'est produite", {
					style: {
						fontFamily: 'Montserrat',
					},
				});
			}
		}finally{
			setLoading(false)
		}
	}
	

	return (
		<div>
			<CommonHeader />
			<div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
				}}
			>
				<GradientText>
					{'Créer un compte transporteur'.toUpperCase()}
				</GradientText>
				<div
					style={{
						width: '60%',
						fontFamily: 'Montserrat',
					}}
				>
					<form onSubmit={handleSubmit(onSubmit)}>
						<FormSectionCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom société <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										placeholder=''
										{...register('societe', {
											required: 'Veuillez indiquer le nom de société.',
											minLength: {
												value: 3,
												message: 'Le nom de la société doit contenir au moins 3 caractères.',
											  },
										})}
									/>
									{errors.societe  && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
									{errors.societe.message}
                                    </div>
                                   )}
								</div>

								<div style={{ width: '1rem' }} />

								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Nom <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										placeholder=''
										{...register('last_name', {
											required: 'Veuillez indiquer le nom',
											minLength: {
												value: 3,
												message: 'Le nom  doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'Le nom  doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },	
										})}
									/>
									{errors.last_name && (
                                    <div style={{ color: 'red', marginTop: '0.5rem' ,fontSize:'14px'}}>
                                     {errors.last_name.message}
                                    </div>
                                    )}
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Prénom <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										placeholder=''
										{...register('first_name', {
											required: 'Veuillez indiquer le prénom.',
											minLength: {
												value: 3,
												message: 'Le prénom doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'Le prénom doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
										})}
									/>
									{errors.first_name && (
                                      <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                       {errors.first_name.message}
                                       </div>
                                       )}
								</div>
								<div style={{ width: '1rem' }} />
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Qualité <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										placeholder=''
										{...register('quality', {
											required: 'Veuillez indiquer la qualité du poste.',
											minLength: {
												value: 3,
												message: 'La qualité doit contenir au moins 3 caractères.',
											  },
											  pattern: {
												value: /^[A-Za-zÀ-ÖØ-öø-ÿ\s'\-]+$/,
												message: 'La qualité doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
											  },
										})}
									/>
									{errors.quality && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                      {errors.quality.message}
                                    </div>
                                     )}
								</div>
							</div>
                            <div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Pays <span style={{ color: 'red' }}>*</span>
								</span>
								<Controller
						       name='pays'
						       rules={{ required: 'Veuillez sélectionner un pays.' }}
						      control={control}
	
						      render={({ field: { onChange, value } }) => (
						 	   <Select 
								placeholder=''
								options={options} 
								value={valueCountry}
								onChange={(selectedOption) => {
								  onChange(selectedOption);
								  changeHandler(selectedOption);
								}}
								styles={{
									control: (base,state) => ({
										...base,
										height: 50,
										minHeight: 50,
										border: 'none',
										borderRadius: '0.5rem',
										fontFamily: 'Montserrat',
									}),
								}} 
								/>
						)}
                        />
						{errors.pays && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                {errors.pays.message}
                                 </div>
                                )}
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Adresse <span style={{ color: 'red' }}>*</span>
								</span>
								<CustomInput
									type='text'
									placeholder=''
									{...register('address', {
										required: ' Veuillez indiquer l\'adresse .',
										minLength: {
											value: 3,
											message: 'L\'adresse doit contenir au moins 3 caractères.',
										},
										pattern: {
											value: /^.+$/,
											message: 'L\'adresse doit contenir des lettres, des chiffres, des espaces, des apostrophes et des tirets uniquement.',
										},
									})}
								/>
								{errors.address && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:"14px" }}>
                                {errors.address.message}
                                 </div>
                                )}
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Ville <span style={{ color: 'red' }}>*</span>
								</span>
								<CustomInput
									type='text'
									placeholder=''
									{...register('city', {
										required: 'Veuillez indiquer la ville.',
										minLength: {
										   value: 3,
										   message: 'La ville doit contenir au moins 3 caractères.',
										 },
										 pattern: {
										   value: /^.+$/,
										   message: 'La ville doit contenir uniquement des lettres, des espaces, des apostrophes ou des tirets.',
										 },
									})}
								/>
								{errors.city && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.city.message}
                                </div>
                               )}
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Code Postal <span style={{ color: 'red' }}>*</span>
								</span>
								<CustomInput
									type='text'
									placeholder=''
									{...register('postalCode', {
										required: 'Veuillez indiquer le code postal.',
										pattern: {
											value: /^\d{5}$/,
											message: 'Le code postal doit contenir exactement 5 chiffres.',
										  },
									})}
								/>
								{errors.postalCode && (
                                <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                {errors.postalCode.message}
                                </div>
                               )}
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Email <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										placeholder=''
										{...register('email', {
											required: "Veuillez indiquer l'adresse e-mail.",
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
												message: 'Veuillez entrer une adresse e-mail valide',
											  },
										})}
									/>
									{errors.email && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.email.message}
                                    </div>
                                   )}
								</div>
								<div style={{ width: '1rem' }} />
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<span
										style={{
											color: '#262626',
											paddingBottom: '1rem',
										}}
									>
										Téléphone <span style={{ color: 'red' }}>*</span>
									</span>
									<CustomInput
										type='text'
										placeholder=''
										{...register('phone', {
											required: 'Veuillez indiquer le numéro de téléphone .',
											pattern: {
												value: /^[0-9]+$/, 
												message: 'Veuillez entrer un numéro de téléphone valide contenant uniquement des chiffres',
													},
										})}
									/>
									{errors.phone && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.phone.message}
                                    </div>
                                    )}
								</div>
							</div>

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Mot de passe <span style={{ color: 'red' }}>*</span>
										</span>
									</div>
									<CustomInput
										type='password'
										placeholder=''
										{...register('password', {
											required: 'Veuillez indiquer le mot de passe',
											minLength: {
												value: 8,
												message: 'Le mot de passe doit contenir au moins 8 caractères',
													},
										})}
									/>
									{errors.password && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.password.message}
                                    </div>
                                    )}
								</div>
								<div style={{ width: '1rem' }} />
								<div
									style={{
										width: '50%',
										display: 'flex',
										flexDirection: 'column',
										paddingBottom: '2rem',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<span
											style={{
												color: '#262626',
												paddingBottom: '1rem',
											}}
										>
											Confirmer mot de passe <span style={{ color: 'red' }}>*</span>
										</span>
									</div>
									<CustomInput
										type='password'
										placeholder=''
										{...register('confirm_password', {
											required: 'Veuillez indiquer le mot de passe',
											minLength: {
												value: 8,
												message: 'Le mot de passe doit contenir au moins 8 caractères',
													},
										})}
									/>
									{errors.confirm_password && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.confirm_password.message}
                                    </div>
                               )}
								</div>
							</div>
							<div style={{ width: '1rem' }} />

							<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										marginBottom: '1rem',
										width: '50%',
										fontFamily: 'Montserrat',
										marginTop: 10,
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Logo(PNG ou JPG)
										</span>
										<span
											style={{
												marginBottom: '1rem',
												color: '#838EAB',
												fontSize: 14,
											}}
										>
											(Facultatif)
										</span>
									</div>
									
									<Controller
                                    name="logo"
                                    control={control}
                                    render={({ field }) => (
                                    <UploadFile
                                     name={field.name}
                                     accept={".jpg, .png"}
                                     setValue={setValue}
                                     {...field}
                                    />
                                    )}
                                    />
									
							</div>

							
						</FormSectionCard>

						<div style={{ height: '2rem' }} />

						<Controller
                        name="vehicules"
                        control={control}
                        rules={{ required: 'Veuillez sélectionner au moins un véhicule.' }}  
                        render={({ field }) => (
                        <>
                       <AddVehicleRegister
                       onChange={(items) => {
                       setValue('vehicules', items);
                       field.onChange(items); 
                       }}
                    />
                    {errors.vehicules && (
                    <p style={{ color: 'red' }}>{errors.vehicules.message}</p>
                    )}
            </>
         )}
      />

						<div style={{ height: '2rem' }} />

						<FormContentSection>
							<FormSectionTitle>Rippeur</FormSectionTitle>
							<FormSectionCard>
								<div
									style={{
										width: '100%',
										display: 'flex',
										flexDirection: 'column',
										//marginBottom: '2.5rem',
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											justifyContent: 'space-between',
										}}
									>
										<span
											style={{
												color: 'black',
												fontSize: 14,
											}}
										>
											Combien de rippeur pouvez-vous en
											plus du chauffeur ?
										</span>
										<span
											style={{
												color: 'black',
												fontSize: 14,
											}}
										>
											(Facultatif)
										</span>
									</div>

									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'center',
										}}
									>
										<div>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='13.605'
												height='27.839'
												viewBox='0 0 13.605 27.839'
												style={{
													marginRight: '.5rem',
												}}
											>
												<g
													id='user'
													transform='translate(0.5 0.5)'
												>
													<path
														id='Path_11624'
														d='M31.488,8.816A3.412,3.412,0,1,1,34.9,5.4,3.412,3.412,0,0,1,31.488,8.816Zm0-6.054a2.645,2.645,0,1,0,2.645,2.645A2.645,2.645,0,0,0,31.488,2.762Z'
														transform='translate(-25.195 -1.992)'
														fill='#51c7f2'
														stroke='#51c7f2'
														strokeWidth='1'
													/>
													<path
														id='Path_11625'
														d='M30.917,33.11h-.752a.383.383,0,1,1,0-.767h.752A1.484,1.484,0,0,0,32.4,30.861V24.829a1.7,1.7,0,0,0-1.7-1.7H23.03a1.7,1.7,0,0,0-1.7,1.7v6.034a1.484,1.484,0,0,0,1.484,1.481h.725a.383.383,0,1,1,0,.767h-.725a2.252,2.252,0,0,1-2.249-2.249V24.829A2.472,2.472,0,0,1,23.03,22.36H30.7a2.472,2.472,0,0,1,2.469,2.469v6.034A2.252,2.252,0,0,1,30.917,33.11Z'
														transform='translate(-20.562 -14.552)'
														fill='#51c7f2'
														stroke='#51c7f2'
														strokeWidth='1'
													/>
													<path
														id='Path_11626'
														d='M38.027,48.646A2.029,2.029,0,0,1,36,46.619V40.231a.383.383,0,0,1,.767,0v6.388a1.26,1.26,0,0,0,2.52,0V34.509a.383.383,0,0,1,.767,0v12.11A2.029,2.029,0,0,1,38.027,48.646Z'
														transform='translate(-30.081 -21.807)'
														fill='#51c7f2'
														stroke='#51c7f2'
														strokeWidth='1'
													/>
													<path
														id='Path_11627'
														d='M29.453,48.646a2.029,2.029,0,0,1-2.027-2.027V34.509a.383.383,0,0,1,.767,0v12.11a1.26,1.26,0,0,0,2.52,0V40.231a.383.383,0,0,1,.767,0v6.388A2.029,2.029,0,0,1,29.453,48.646Z'
														transform='translate(-24.794 -21.807)'
														fill='#51c7f2'
														stroke='#51c7f2'
														strokeWidth='1'
													/>
												</g>
											</svg>
										</div>

										<CustomInput
											width={'100%'}
											type='number'
											defaultValue={0}
											min={0}
											{...register('rippeurs')}
										/>
									</div>
								</div>
							</FormSectionCard>
						</FormContentSection>

						<div style={{ height: '2rem' }} />

						<FormContentSection>
							<FormSectionTitle>
								Matériels supplémentaire
							</FormSectionTitle>
							<FormSectionCard>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										//marginBottom: '2.5rem',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Matériels de manutention <span style={{ color: 'red' }}>*</span>
									</span>

									<button
										type='button'
										style={{
											marginBottom: '1rem',
											border: 'none',
											alignSelf: 'flex-start',
											padding: '1rem 2rem',
											background: '#72c5ed',
											color: 'white',
											fontWeight: 'bold',
											cursor: 'pointer',
											borderRadius: 5,
											fontFamily: 'Montserrat',
										}}
										onClick={() =>
											setValue('manutention', [
												'Diable',
												'Transpalette manuel',
												'Transpalette électrique',
												'Sangle',
											])
										}
									>
										Tout
									</button>

									<div
										style={{
											display: 'flex',
											flexWrap: 'wrap',
											flexDirection: 'row',
											marginBottom: '1rem',
										}}
									>
										{[
											'Diable',
											'Transpalette manuel',
											'Transpalette électrique',
											'Sangle',
										].map((item, index) => (
											<div
												key={String(index)}
												style={{
													width: '30%',
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
													marginBottom: '1rem',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													{...register(
														'manutention',
														{
															required: true,
														}
													)}
													value={item}
												/>

												<span>{item}</span>
											</div>
										))}
									</div>
									{errors.manutention && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',marginBottom:'1rem',fontSize:"14px" }}>
                                      Veuillez sélectionner au moins un matériel de manutention.
                                       </div>
                                   )}
								</div>

								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										//marginBottom: '2.5rem',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Epi <span style={{ color: 'red' }}>*</span>
									</span>

									<button
										type='button'
										style={{
											marginBottom: '1rem',
											border: 'none',
											alignSelf: 'flex-start',
											padding: '1rem 2rem',
											background: '#72c5ed',
											color: 'white',
											fontWeight: 'bold',
											cursor: 'pointer',
											borderRadius: 5,
											fontFamily: 'Montserrat',
										}}
										onClick={() =>
											setValue('epi', [
												'Casque',
												'Gants',
												'Masque',
												'Lunettes',
												'Chaussure de sécurité',
												'Gilet de visibilité',
											])
										}
									>
										Tout
									</button>

									<div
										style={{
											display: 'flex',
											flexWrap: 'wrap',
											flexDirection: 'row',
											//marginBottom: '1rem',
										}}
									>
										{[
											'Casque',
											'Gants',
											'Masque',
											'Lunettes',
											'Chaussure de sécurité',
											'Gilet de visibilité',
										].map((item, index) => (
											<div
												key={String(index)}
												style={{
													width: '25%',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
													marginBottom: '1rem',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													{...register('epi', {
														required: true,
													})}
													value={item}
												/>

												<span>{item}</span>
											</div>
										))}
									</div>
								</div>
								{errors.epi && (
                   <div style={{ color: 'red', marginTop: '0.5rem' }}>
                   Veuillez sélectionner au moins un équipement de protection individuelle.
                   </div>
                  )}
							</FormSectionCard>
						</FormContentSection>

						<div style={{ height: '2rem' }} />
						<FormContentSection>
                        <FormSectionTitle>Documents Administratifs</FormSectionTitle>
                        <FormSectionCard>
                        <div   
                        style={{
                         display: 'flex',
                         flexDirection: 'column', 
                         marginBottom: '1rem',
                        }}
                        >
                       {[
                       { name: 'fiscale', label: 'Attestation de régularité fiscale de moins de 6 mois (PDF)' },
                       { name: 'urssaf', label: 'Attestation de vigilance Urssaf de moins de 6 mois (PDF)' },
                       { name: 'assurance_marchandises', label: 'Assurance marchandises valide (PDF)' },
                       { name: 'kbis', label: 'Kbis de moins de 6 mois (PDF)' },
                       { name: 'licence_moins35t', label: 'Licence de transport de marchandises de moins de 3,5t valide (PDF)' },
                       { name: 'licence_plus35t', label: 'Licence de transport de marchandises de plus de 3,5t valide (PDF)' },
					   { name: 'capacite_transport', label: 'Capacite de transport valide (PDF)' },
					   { name: 'piece_identite', label: "Pièce d'identité (PDF)" },
					   { name: 'rib', label: 'Rib (PDF)' },

                       ].reduce((rows, field, index, array) => {
                       if (index % 2 === 0) {
                        rows.push(array.slice(index, index + 2));
                        }
                        return rows;
                       }, []).map((pair, index) => (
                       <div
                        key={index}
                        style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '1rem',
                        }} 
                        >
                        {pair.map((field) => (
                        <div
                         key={field.name}
                         style={{
                         display: 'flex',
                         flexDirection: 'column',
                         width: 'calc(50% - 1rem)',
                         fontFamily: 'Montserrat',
                         marginLeft: 10,
                         marginTop: 10,
                        }}
                        >
                       <span
                        style={{
                        marginBottom: '1rem',
                        color: 'black',
                        fontSize: 14,
                        }}
                        >
                       {field.label}
                       </span> 
					   <Controller
                        name={field.name}
                        control={control}
                        rules={{ required: 'Veuillez télécharger le document' }}
                        render={({ field }) => (
						<>
                        <UploadFile
                        name={field.name}
                        accept={'.pdf'}
                        setValue={setValue}
                        {...field}
                        />
						 {errors[field.name] && (
                          <span style={{ color: 'red', fontSize:'14px'  }}>
                            {errors[field.name]?.message}
                          </span>
                        )}
						</>
                        )}
                        />

            </div>
          ))}
                      </div>
                        ))}
                        </div>
                        </FormSectionCard>
                         </FormContentSection>

						

						<div style={{ height: '1rem' }} />

						<SubmitButtonContainer>
							<SubmitButton type='submit' disabled={loading}>
								{loading ? (
									<div className='loader'></div>
								) : (
									'S’inscrire'
								)}
							</SubmitButton>
						</SubmitButtonContainer>
					</form>
				</div>
			</div>
			<Footer />

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => {}}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '35rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<GradientText>{'Félicitation'.toUpperCase()}</GradientText>

					<div
						style={{
							textAlign: 'center',
						}}
					>
						La création de votre compte a bien été enregistré 😃 
						<br />
						Un e-mail de confirmation a été envoyé à votre adresse e-mail
						<br />
						Veuillez vérifier votre boîte de réception et cliquer sur le lien fourni pour finaliser la création de votre compte.

						
					</div>

					<button
						onClick={() => {
							history.push('/')
						}}
						style={{
							padding: '0.5rem 1rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 30,
						}}
					>
						OK
					</button>
				</div>
			</Modal>
		</div>
	)
}

export default RegisterTransporter
