import { Document, Page, pdfjs } from 'react-pdf'
import { GradientText, Wrapper } from '../PasserCommande/PasserCommande.styles'
import React ,{useEffect, useState} from 'react'
import { useHistory, useParams } from 'react-router'
import Container from '../../Components/LayoutClient/container.component'
import {BASE_URL} from '../../api/base'
import { useSelector } from 'react-redux'
import  {getCommandeFacture} from '../../api/commande'
import {payTarif} from '../../api/tarif'
import toast from 'react-hot-toast'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`


function PayCommande() {
	const { id } = useParams()
    const history = useHistory();
	const [commande,setCommande]=useState(null)
	const [loading, setLoading] = useState(false)
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const user = useSelector((state) => state.auth.user)
	const token = useSelector((state) => state.auth.token);
    const onSubmit = async (event) => {
		setLoading(true)
		try {
			event.preventDefault(); 
			const response = await payTarif({ commandeId: id },token);
			if(response.url){
				window.location.href = response.url;
			}
		  } catch (error) {
			toast.error("Erreur lors du paiement. Veuillez réessayer.", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		  }finally{
			setLoading(false)
		  }
		
	}
	
	const fetchCommande = async () => {
		
		setChargementEnCours(true);
			
		try {
			  const res = await getCommandeFacture(id, token);
			  setCommande(res);
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		} finally {
			setChargementEnCours(false);
			}
		};
	
	useEffect(()=>{
		fetchCommande()
	},[id])
	

	return (
		<Container>
			<Wrapper>
				<GradientText>{'Paiement'.toUpperCase()}</GradientText>
				{chargementEnCours ?
				<div
				style={{
				  display: 'flex',
				  alignItems: 'center',
				  justifyContent: 'center',
				  backgroundColor: 'white',
				  marginTop:'4rem',
				  top: 0,
				  left: 0,
				  right: 0,
				  bottom: 0,
	   
				}}
				>
				<div className='customLoader'></div>	
				</div> 
				:
				<div>
				
				<div
					style={{
						height: 'calc(100vh - 20rem)',
						overflow: 'auto',
						border: '2px dashed #000',
						marginTop: '1rem',
					}}
				>  
				{commande?.facture?.length > 0 ? (
                                <Document file={`${BASE_URL}/${commande.facture[0]}`} loading={null}>
                                    <Page pageNumber={1} />
                                </Document>
                ) : (
                                <div style={{ padding: '1rem', textAlign: 'center' }}>
                                    <p>Facture non disponible pour cette commande.</p>
                                </div>
                )}
				</div>
				<div style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
				{(user?.premium || (!user?.premium && commande?.prix===0) ) ? (
					<button
						onClick={() => {
							history.push(`/commandes/list`)
						}}
						style={{
							padding: '1rem 6rem',
							marginTop: '1rem',
							borderRadius: 5,
							cursor: 'pointer',
							color: 'white',
							marginRight: '1rem',
							background: '#50F5A9',
							border: 'none',
							fontSize: 20,
							fontFamily: 'Montserrat',
						}}
					>
						Valider
					</button>
				) : (
					<form  onSubmit={onSubmit}>
						<input type="text" hidden={true} name="commandeId" value={id} />
						<div
					   style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
						<button 
						role="link" 
						type='submit' 
						disabled={loading}
						style={{
							padding: '1rem 5rem',
							marginTop: '1rem',
							borderRadius: 5,
							cursor: 'pointer',
							color: 'white',
							marginRight: '1rem',
							background: '#50F5A9',
							border: 'none',
							fontSize: 20,
							fontFamily: 'Montserrat',
						}}>
						{loading ? (
									<div className='loader'></div>
								) : (
									'Payer'
						)}</button>
							<button
						onClick={ () => {
							history.push(`/commandes/list`)							
						}}
						style={{
							padding: '1rem 4rem',
							marginTop: '1rem',
							borderRadius: 5,
							cursor: 'pointer',
							color: 'white',
							marginRight: '1rem',
							background: 'red',
							border: 'none',
							fontSize: 20,
							fontFamily: 'Montserrat',
						}}
					>
						Annuler
					</button>
					    </div>
					
					</form>
				)}
				</div>
				</div>
				}
				
			</Wrapper>
		</Container>
	)
}

export default PayCommande
