import React ,{useState,useEffect} from 'react'
import {
	GradientText,
	SubText,
	Wrapper,
} from '../PasserCommande/PasserCommande.styles'
import Container from '../../Components/LayoutClient/container.component'
import CourseACourseHorizontal from '../../Components/SingleCommandeHorizontal/CourseACourseHorizontal'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import MiseADispoHorizontal from '../../Components/SingleCommandeHorizontal/MiseADispoHorizontal'
import MissionHorizontal from '../../Components/SingleCommandeHorizontal/MissionHorizontal'
import Select from 'react-select'
import { getAllCommandesAnnuléesByClientId } from '../../api/commande'
import {mergeInvoiceForCommande,mergeAvoirForCommande} from '../../api/invoice'
import moment from 'moment'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import {
	searchCommandesAnnulesByDateForClient,
	searchCommandesAnnuleesByPrixMinMaxForClient,
	searchCommandesAnnuleesByIdForClient
} from '../../api/clients'
import { BASE_URL } from '../../api/base'
import UltimatePaginationBasic from '../../Components/Pagination/Pagination'

import toast from 'react-hot-toast'

const SIZE = 10


function CommandeAnnulées() {
    const history = useHistory()
	const [commandes, setCommandes] = useState([])
	const [chargementEnCours, setChargementEnCours] = useState(false);

	const clientID = useSelector((state) => state.auth.user._id)
	const token = useSelector((state) => state.auth.token);
	
	const [page, setPage] = useState(1)
	const [totalPages,setTotalPages]=useState(1)

	const [isSearching,setIsSearching]=useState(false)

	const [searchCriteria, setSearchCriteria] = useState('id')
	const [range, setRange] = useState([new Date(), new Date()])
	const [min, setMin] = useState(0)
	const [max, setMax] = useState(300)
	const [searchQueryId, setSearchQueryId] = useState('')
	const [selectedOption, setSelectedOption] = useState({
		value: 'id', label: "Recherche par l'identifiant " 
	 });
     const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setSearchCriteria(selectedOption.value)
      };


     

	const fetchCommandes = async (page) => {
		setChargementEnCours(true);
		try {
			let res;
			if (isSearching) {
				if (searchCriteria === 'id') {
					res = await searchCommandesAnnuleesByIdForClient(clientID,searchQueryId, token,page,SIZE);
				} else if (searchCriteria === 'prix') {
					res = await searchCommandesAnnuleesByPrixMinMaxForClient(min, max, clientID, token, page, SIZE);
				} else if (searchCriteria === 'date') {
					let minDate = moment(range[0]).format();
					let maxDate = moment(range[1]).format();
					res = await searchCommandesAnnulesByDateForClient(minDate, maxDate,clientID,token, page, SIZE);
				}
			} else {
				res =  await getAllCommandesAnnuléesByClientId(clientID,token,page,SIZE)
			}
			console.log(res.commandes)
			setCommandes(res.commandes);
			setTotalPages(res.totalPages)
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
		} finally {
			setChargementEnCours(false);
		}
	};

	const handleSearch = () => {
		setIsSearching(true); 
		setPage(1); 
		if(isSearching){
			fetchCommandes(1); 

		}
	};

	const resetData = async () => {
		setIsSearching(false); 
		setSearchQueryId(''); 
		setSearchCriteria("id")
		setSelectedOption({
			value: 'id', label: "Recherche par l'identifiant " 
		  })
		setPage(1);
	};
	const handleViewInvoices = async (id) => {
		try {
			const res=await mergeInvoiceForCommande(id,token)
			window.open(`${BASE_URL}/${res.mergedPdfPath}`)
		} catch (error) {
			console.log(error)
		}
    };
    const handleViewAvoir = async (id) => {
	try {
		const res=await mergeAvoirForCommande(id,token)
		window.open(`${BASE_URL}/${res.mergedPdfPath}`)
	} catch (error) {
		console.log(error)
	}
   };

    useEffect(() => {
		fetchCommandes(page)
	}, [page,isSearching])


  return (
    <Container>
    <Wrapper>
        <GradientText>{'Annulées'.toUpperCase()}</GradientText>
        <SubText>
            Ici retrouver la totalité des transports annulés
        </SubText>
        <div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						style={{
							width: '350px',
							fontFamily: 'Montserrat',
							marginRight: '1rem',
						}}
					>
						<Select
							isSearchable={false}
							options={[
								{value: 'id', label: "Recherche par l'identifiant " },
								{ value: 'prix', label: 'Prix' },
								{ value: 'date', label: 'Date du Transports' },
							]}
							styles={{
								option: (provided, state) => ({
									...provided,
									fontFamily: 'Montserrat',
								}),
								control: (provided, state) => ({
									...provided,
									height: '3rem',
									minHeight: 'fit-content',
								}),
								valueContainer: (base) => ({
									...base,
									maxHeight: '3rem',
								}),
							}}
							value={selectedOption}
							onChange={handleChange}
							menuPortalTarget={document.querySelector('body')}
						/>
					</div>
					{searchCriteria === 'id' ? (
						<input
							style={{
								padding: '0.4rem',
								borderRadius: 5,
								border: '1px solid #E4EAF0',
								height: '3rem',
								width: '20rem',
								fontFamily: 'Montserrat',
							}}
							value={searchQueryId}
							onChange={(e) => setSearchQueryId(e.target.value)}
							placeholder='Recherche'
						/>
					) : null}
					{searchCriteria === 'prix' ? (
						<>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
									marginRight: '1rem',
								}}
								value={min}
								onChange={(e) => {
									setMin(Number(e.target.value))
								}}
								placeholder='Prix min'
							/>
							<input
								type='number'
								style={{
									padding: '0.4rem',
									borderRadius: 5,
									border: '1px solid #E4EAF0',
									height: '3rem',
									width: '20rem',
									fontFamily: 'Montserrat',
								}}
								value={max}
								onChange={(e) => {
									setMax(Number(e.target.value))
								}}
								placeholder='Prix max'
							/>
						</>
					) : null}

					{searchCriteria === 'date' ? (
						<DateRangePicker onChange={setRange} value={range} />
					) : null}

					<button
                    onClick={handleSearch}
					style={{
                        padding: '0.6rem 1rem',
                        height: '3rem',
                        background: '#C9D5F7',
                        border: 'none',
                        cursor: 'pointer',
                        color: '#1C63C5',
                        borderRadius: 7,
                        //marginTop: 20,
                        marginLeft: '1rem',
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                    }}
					>
						Rechercher
					</button>

					<button
						onClick={resetData}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							marginRight: '1rem',
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
		</div>
        {chargementEnCours ?
		<div
		style={{
		  display: 'flex',
		  alignItems: 'center',
		  justifyContent: 'center',
		  backgroundColor: 'white',
		  marginTop:"3rem",
		  top: 0,
		  left: 0,
		  right: 0,
		  bottom: 0,

		}}
		>
		<div className='customLoader'></div>	
		</div>
		:
        <div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: 5,
					}}
				>	{ commandes.length === 0 ? 
					<span
					style={{
					
						
						display: 'flex',
						textAlign: 'center',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					Il n'y a pas des commandes à afficher
				</span>
					:commandes.map((commande) => {
						if (commande.type_commande === 'course-a-course') {
							return (
								<CourseACourseHorizontal
									key={commande._id}
									commande={commande}
									hideRecu
									hideAttribuer
									hidecancel
									hidefacture
									hideStatus
									hideUpdate
									onClickDetails={() => {
										history.push(
											`/commandes/${commande._id}`
										)
									}}
									onClickSignaler={() => {
										history.push(
											`/client/transports/${commande._id}/signaler`
										)
									}}
								/>
							)
						} else if(commande?.statut==="annuler"){
							
							return (
								<MiseADispoHorizontal
									key={commande._id}
									commande={commande}
									hideRecu
									hideAttribuer
									hidecancel
									hideStatus
									hideUpdate
									hideSignalement
									hideMission
									hideTarif
									onClickDetails={() => {
										history.push(
											`/commandes/${commande._id}`
										)
									}}
									onClickSignaler={() => {
										history.push(
											`/client/transports/${commande._id}/signaler`
										)
									}}
									onClickFacture={()=>{
										handleViewInvoices(commande._id)

									}}
									onClickAvoir={()=>{
										handleViewAvoir(commande._id)

									}}
								/>
							)
						}else{
							return(
								<MissionHorizontal
								key={commande._id}
								commande={commande}
								onClickDetails={() => {
									history.push(
										`/commandes/${commande._id}`
									)
								}}
								onClickFacture={()=>{
									handleViewInvoices(commande._id)

								}}
								onClickAvoir={()=>{
									handleViewAvoir(commande._id)

								}}
								/>
							)
						}
					})}
		</div>
        }
      
	  <div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
							marginBottom:"3rem",
							marginTop:"3rem"
						}}
					>
						<UltimatePaginationBasic
							currentPage={page}
							totalPages={totalPages}
							onChange={(newPage) => setPage(newPage)}
						/>
					</div>

     
    </Wrapper>
</Container>
  )
}

export default CommandeAnnulées