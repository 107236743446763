import api from './base'

export const  getAllAdministrators=async(page, size,token)=>{
	const  res=await api.get(`/users/administrateur/all?fields=role&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const  getAllAdministratorsbyIdAndName=async(query,status,page,size,token)=>{
	const  res=await api.get(`/users/administrateur/byIdandName/${query}/all?status=${status}&page=${page}&limit=${size}&filter=active`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const  getArchivedAdministateurByIdAndName=async(query,page,size,token)=>{
	const  res=await api.get(`/users/administrateur/byIdandName/${query}/all?page=${page}&limit=${size}&filter=deleted`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const toggleSuspendreAdministrateur = async (id,data,token) => {
	const res = await api.patch(`/users/suspend/${id}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const softDeleteAdministrateur = async (id,token) => {
	const res = await api.get(`/users/softdelete/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getSingleAdministrateur= async (_id,token) => {
	const res = await api.get(`/users/${_id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getArchivedAdministrateur = async (page, size,token) => {
	
	const res = await api.get(`/users/administrateur/archived/all?fields=role&page=${page}&limit=${size}`,{
    headers: {
		  Authorization: `Bearer `+token
		},
	  })
	
	return res.data
}
export const restoreDeletedAdministrateur = async (id,token) => {
	
	const res = await api.get(`/users/restore/${id}`,{
    headers: {
		  Authorization: `Bearer `+token
		},
	  })
	
	return res.data
}
export const getValidAdministrators=async(page, size,token)=>{
	const  res=await api.get(`/users/administrateur-validated/all?fields=role&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getNotValidAdministrators=async(page, size,token)=>{
	const  res=await api.get(`/users/administrateur-not-validated/all?fields=role&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}