import api from './base'


export const getTransporteurs = async (page, size,token) => {
	const res = await api.get(
		`/users?filter=transporter&fields=role&page=${page}&limit=${size}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  })
	
	return res.data
}

export const getDeletelTransporteur = async (page, size,token) => {
	
	const res = await api.get(`/users/delete/all?filter=transporter&fields=role&page=${page}&limit=${size}`,{
    headers: {
		  Authorization: `Bearer `+token
		},
	  })
	
	return res.data
}

export const updateTransporterAccount = async (transporteurId,data,files,token) => {

	const formData = new FormData();
 
	Object.keys(data).forEach(key => {
		if (typeof data[key] === 'object') {
		  formData.append(key, JSON.stringify(data[key]));
		} else {
		  formData.append(key, data[key]);
		}
	  });
  

   
	files.forEach(fileObj => {
		const [fileField, file] = Object.entries(fileObj)[0];
		formData.append(fileField, file);
	  });
	const res = await api.patch(`/profile/${transporteurId}`, formData,{
		headers: {
			'Content-Type': 'multipart/form-data',
			'Authorization': `Bearer ${token}`
			},
		  })
	return res.data
}
export const getNewTransporters = async (page, size,token) => {
	const res = await api.get(
		`/users/transporters/new?page=${page}&limit=${size}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)

	return res.data
}
export const getValidNewTransporters = async (page, size,token) => {
	const res = await api.get(
		`/users/transporters/valid-new?page=${page}&limit=${size}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)

	return res.data
}
export const getNoValidNewTransporters = async (page, size,token) => {
	const res = await api.get(
		`/users/transporters/novalid-new?page=${page}&limit=${size}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)

	return res.data
}

export const getValidTransporters = async (token) => {
	const res = await api.get(
		`/users/transporters/valid`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  }
	)

	return res.data
}
export const getFilteredTransporters=async(page, size,token)=>{
	const res = await api.get(`/users/validated/all?filter=transporter&fields=role&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
		
		return res.data
	
}
export const getSingleTransporteur = async (_id,token) => {
	const res = await api.get(`/users/${_id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const updateTransporterStatus = async (id, status,token) => {
	const params = new URLSearchParams()

	params.append('status', status)

	const config = {
		headers: {
			Authorization: `Bearer `+token,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	}

	const res = await api.patch(`/users/${id}`, params, config)

	return res.data
}

export const deleteTransporter = async (id,token) => {
	const res = await api.delete(`/users/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const softDeleteTransporter = async (id,token) => {
	const res = await api.get(`/users/softdelete/${id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}


export const getSingleTransporter = async (_id,token) => {
	const res = await api.get(`/users/${_id}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}




export const toggleSuspendreTransporter = async (id,data,token) => {
	const res = await api.patch(`/users/suspend/${id}`, data,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}
export const getComptabiliteTransporteurs = async (page, size,token) => {
	const res = await api.get(
		`/comptabilite/transporteurs?size=${size}&page=${page}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			}
	)
	return res.data
}
export const getComptabiliteTransporteursByMonth = async (page, size,token,month,year) => {
	const res = await api.get(
		`/comptabilite/transporteurs/month?size=${size}&page=${page}&month=${month}&year=${year}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			}
	)
	return res.data
}
export const getComptabiliteTransporteursByMonthAndName = async (page, size,token,month,year,query) => {
	query=query.trim()
	const res = await api.get(
		`/comptabilite/transporteurs/month/${query}/all?size=${size}&page=${page}&month=${month}&year=${year}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			}
	)
	return res.data
}
export const getComptabiliteTransporterByName= async (name,page,size,token) => {
	name=name.trim()
	const res = await api.get(`/comptabilite/transporteur/${name}?page=${page}&size=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		})
	return res.data
}

export const searchCommandesByPrixMinMaxForTransporter = async (min,max,transporter,type,token,page,size) => {
	
	const res = await api.get(`/commandes/fortransporter/price/${transporter}/${min}/${max}/all?filter=${type}&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const searchCommandesAnnuléesByPrixMinMaxForTransporter = async (min,max,transporter,token,page,size) => {
	
	const res = await api.get(`/commandes/annulees/fortransporter/price/${transporter}/${min}/${max}/all?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}


export const searchCommandesByIdForTransporter = async (query,transporter,type,token,page,size) => {
		const res = await api.get(`/commandes/fortransporter/${transporter}/${query}/all?filter=${type}?page=${page}&limit=${size}`,{
			headers: {
				  Authorization: `Bearer `+token
				},
			  })
		return res.data
}
export const searchCommandesByPriceForPlateforme = async (transporteurId, min, max, page, size, token) => {
	const res = await api.get(`/commandes/forplateForm/price/${transporteurId}/${min}/${max}/all`, {
		params: {
			page: page,
			limit: size
		},
		headers: {
			Authorization: `Bearer ${token}`
		}
	});

	return res.data;
}

export const searchCommandesByDateForPlateforme = async (transporteurId,min,max,page,size,token) => {
	const res = await api.get(`/commandes/forplateForm/date/${transporteurId}/${min}/${max}/all?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const searchCommandesByIdForPlateforme = async (transporteurId,query,page,size,token) => {
	const res = await api.get(`/commandes/forplateForm/${transporteurId}/${query}/all?page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const searchCommandesByDateForTransporter = async (min,max,transporter,type,token,page,size
) => {
	const res = await api.get(`/commandes/fortransporter/date/${transporter}/${min}/${max}/all?filter=${type}&page=${page}&limit=${size}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })
	return res.data
}

export const getDemandeTransfertByCommandeIdAndTransprteurId = async (commandeId,transporteurId,token) => {
	const res = await api.get(`/commandes/demande-transfert/fortransporteur/${commandeId}/${transporteurId}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}

export const getTransporterStats=async(transporteurId,startDate,endDate,token)=>{
	const res = await api.get(`/commandes/fortransporter/stats/${transporteurId}?startDate=${startDate}&endDate=${endDate}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data

}
export  const sendTransporterDocument=async(transporteurId,document,token)=>{
	const res = await api.get(`/users/send-transporter-document/${transporteurId}?documentKey=${document.key}&documentLabel=${document.label}`,{
		headers: {
			  Authorization: `Bearer `+token
			},
		  })

	return res.data
}
export const validateToken=async(token)=>{
	const res=await api.post(`/users/validate-token`,{token})
	return  res.data
}
