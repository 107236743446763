import { Select,StyledTextArea,Seperator } from '../PasserCommande/PasserCommande.styles'

import Flatpickr from 'react-flatpickr'
import { French } from 'flatpickr/dist/l10n/fr.js'
import React,{useState} from 'react'
import 'react-phone-input-2/lib/style.css'
import { CustomInput } from '../RegisterTransporter/style'
import  PoidsLours from '../../assets/img/poid_lourd@2x.png'
import VehiculeLeger from '../../assets/img/vehicule_leger@2x.png'
import tonnes7 from '../../assets/img/7.5.png'
import  tonnes19 from  '../../assets/img/tonnes19.png'
import Fourgon14 from '../../assets/img/fourgon12m.png'
import Fourgon12 from '../../assets/img/captureF12.png'
import Break from '../../assets/img/CaptureBreak.png'
import Semi from '../../assets/img/semi.png'
import SemiImg from '../../assets/img/Capturesemi.png'
import tonnes12 from '../../assets/img/tonnes12.png'
import tracteurSemi from '../../assets/img/tracteur-actos.png'
import vehiculeCamion from '../../assets/img/vehicule_camion.png'
import { BASE_URL } from '../../api/base'
import UpdateUploadFileCommande from '../../Components/UploadFileCommande/UpdateUploadFileCommande'
import { useSelector } from 'react-redux'

function MissionsMiseADispoReadOnly({
	keepFilesAsObjects,
	i,
	watch,
	setValue,
	display,
	commande


}) {
	const user = useSelector((state) => state.auth.user);
   
	

	
	
	
    const [showInfoHeureFin, setShowInfoHeureFin] = useState(false);
    const [showInfoDateFin, setShowInfoDateFin] = useState(false);
	
  
  return (
    <div
    style={{
        display:'flex',
        flexDirection: 'column',
    }}
    >
		<div
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '2.5rem',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Adresse début   
					</span>
					<CustomInput
						width={'100%'}
						type='text'
						disabled={display}
						value={commande?.missions[i]?.adresse_debut?.label}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						marginBottom: '2.5rem',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Adresse de fin  
						</span>
						
					</div>
					<CustomInput
						width={'100%'}
						type='text'
						disabled={display}
						value={commande?.missions[i]?.adresse_fin?.label}
					/>
				
				</div>

			</div>
    <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        marginBottom: '2.5rem',
                    }}
                >
                    <div
						style={{
							display: 'flex',
							flexDirection: 'row',
							position: 'relative',

							
						}}

					>
                    <span
                        style={{
                            marginBottom: '1rem',
                            color: 'black',
                            fontSize: 14,
                        }}
                    >
                        Date de début 
                    </span>
                    </div>
                    
                            <Flatpickr
							    disabled={true}
                                className='date-input'
                                options={{
                                    mode: 'single',
                                    locale: French,
                                    dateFormat: 'd/m/Y',
                                }}
                                value={commande?.missions[i]?.date_debut}
                            />
                      
                     
    </div>
    <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '2.5rem',
        }}
    >
        <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%',
                    }}
                >
                     <div
						style={{
							display: 'flex',
							flexDirection: 'row',
							position: 'relative',

							
						}}

					>
                    <span
                        style={{
                            marginBottom: '1rem',
                            color: 'black',
                            fontSize: 14,
                        }}
                    >
                        Heure de début  
                    </span>
                    
                    </div>
                   
				    <CustomInput
						width={'100%'}
						type='text'
						disabled={display}
						value={commande?.missions[i]?.heure_debut}
					/>
                
    </div>
    <div
                    style={{
                        width: '1rem',
                    }}
                />
                
    <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '50%',
                    }}
                >
                     <div
						style={{
							display: 'flex',
							flexDirection: 'row',
							position: 'relative',

							
						}}

					>
                    <span
                        style={{
                            marginBottom: '1rem',
                            color: 'black',
                            fontSize: 14,
                        }}
                    >
                        Heure de fin  
                    </span>
                    <svg
							xmlns='http://www.w3.org/2000/svg'
							style={{
								width: 20,
								height: 20,
								
								marginLeft: 10,
							}}
							fill='none'
							viewBox='0 0 24 24'
							stroke='#858ea8'
                            onClick={()=>{
								setShowInfoHeureFin(true); 
							}}
                             onMouseLeave={()=>{
								setShowInfoHeureFin(false);
							 }} 
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={2}
								d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
							/>
						    </svg>
							{showInfoHeureFin && (
                <div
				style={{
					position: 'absolute',
					top: '20px',
					left: '150px',
					padding: '0.5rem',
					border: '1px solid #ccc',
					borderRadius: '0.5rem',
					background: '#FFFFFF',
					boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
					zIndex: 1000, 
					width: 'calc(100% - 30px)',
				}}

                >
                    <p style={{ color: '#333', fontSize: '14px', marginBottom: '0.5rem' }}>
					L'heure de fin doit se situer entre 7 et 12 heures après l'heure de début.
				    </p>
                   
                </div>
                         )}
                    </div>
					<CustomInput
						width={'100%'}
						type='text'
						disabled={display}
						value={commande?.missions[i]?.heure_fin}
					/>
               
    </div>
    </div>
    <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        marginBottom: '2.5rem',
                    }}
                >
                  <div
						   style={{
							display: 'flex',
							flexDirection: 'row',
							position: 'relative',

							
						}}>
                    <span
                        style={{
                            marginBottom: '1rem',
                            color: 'black',
                            fontSize: 14,
                        }}
                    >
                        Date de Fin 
                    </span>
                    <svg
							xmlns='http://www.w3.org/2000/svg'
							style={{
								width: 20,
								height: 20,
								
								marginLeft: 10,
							}}
							fill='none'
							viewBox='0 0 24 24'
							stroke='#858ea8'
                            onClick={()=>{
								setShowInfoDateFin(true); 
							}}
                             onMouseLeave={()=>{
								setShowInfoDateFin(false);
							 }} 
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={2}
								d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
							/>
						    </svg>
                            {showInfoDateFin && (
                <div
				style={{
					position: 'absolute',
					top: '20px',
					left: '150px',
					padding: '0.5rem',
					border: '1px solid #ccc',
					borderRadius: '0.5rem',
					background: '#FFFFFF',
					boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
					zIndex: 1000, 
					width: 'calc(100% - 30px)',
				}}

                >
                    <p style={{ color: '#333', fontSize: '14px', marginBottom: '0.5rem' }}>
                    La date de fin est calculée automatiquement
                    </p>
                   
                </div>
                         )}
                    </div>
                   
				   
                            <Flatpickr
                                disabled={true}
                                className='date-input'
                                options={{
                                    mode: 'single',
                                    locale: French,
                                    dateFormat: 'd/m/Y',
                                }}
                                value={commande?.missions[i]?.date_fin}
                                style={{
                                    backgroundColor: '#ffffff',
                                    opacity: 0.6,
                                    cursor: 'not-allowed',
                                  }}
                            />


                   
                   
                    
    </div>
  
               <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        marginBottom: '2.5rem',
                    }}
                >
                    <span
                        style={{
                            marginBottom: '1rem',
                            color: 'black',
                            fontSize: 14,
                        }}
                    >
                        Nombre de kilomètre jour 
                    </span>

                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                        }}
                    >
                        <Select 
                         disabled={display}
						 defaultValue={commande.missions[i]?.kilometrage || ''}
                       >
								<option value="" disabled  selected >Selectionner</option>
								<option value="200" >200 </option>
                                <option value="300">300 </option>
                                 <option value="400">400 </option>
                         </Select>
                        <span
                            style={{
                                position: 'absolute',
                                right: '3rem',
                                top: '1rem',
                            }}
                        >
                            KM
                        </span>
                    </div>
                    
                   
                </div>
				{watch( `missions[${i}].notes`) &&
				<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							marginBottom: '2.5rem',
							width: '100%',
						}}
					>
						<span
							style={{
								marginBottom: '1rem',
								color: 'black',
								fontSize: 14,
							}}
						>
							Notes Utiles
						</span>
						<StyledTextArea
							disabled={display}
							rows={4}
							placeholder=''
							value={commande.missions[i]?.notes}
						/>
			   </div>
                } 
			   {user?.role==='client' &&

                <div
style={{
	display: 'flex',
	flexDirection: 'column',
	marginBottom: '2.5rem',
	width: '50%',
}}
>
	<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				<span
					style={{
						marginBottom: '1rem',
						color: 'black',
						fontSize: 14,
					}}
				>
					Joindre Documents (PDF)
				</span>
				
	 </div>
				<UpdateUploadFileCommande
					initialFiles={watch(`missions[${i}].files`)}
					name={`file_${i}`}
					onChange={(files) => {
							
							if (keepFilesAsObjects) {
								setValue(
									`missions[${i}].files`,
									files.map((file) => file)
								)
							} else {
								setValue(
									`missions[${i}].files`,
									files.map((file) => file.id)
								)
							}
					}}
					commandeId={watch('_id')}
					missionId={watch(`missions[${i}]._id`)}

				/>
				 
				{(watch(`missions[${i}].files`) || []).map(
				(file, index) => (
					<div
						onClick={() => {
							window.open(`${BASE_URL}/${file?.path}`)
						}}
						key={String(index)}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
							marginBottom: 20,
							cursor: 'pointer',
						}}
					>
						<img
							alt=''
							src={File || 'https://secure.gravatar.com/favicon.ico'}
							style={{
								height: 20,
								marginRight: '1rem',
							}}
						/>
						<span
							style={{
								marginRight: '1rem',
								width: '10rem',
							}}
						>
							{file?.name}
						</span>
					</div>
				)
			)}
	
               </div>
               }
			   {user?.role!=='client' && watch(`missions[${i}].files`).length>0 &&
               <div
  style={{
	  display: 'flex',
	  flexDirection: 'column',
	  marginBottom: '2.5rem',
	  width: '50%',
  }}
  >
	  <div
				  style={{
					  display: 'flex',
					  flexDirection: 'row',
					  justifyContent: 'space-between',
				  }}
			  >
				  <span
					  style={{
						  marginBottom: '1rem',
						  color: 'black',
						  fontSize: 14,
					  }}
				  >
					   Documents (PDF)
				  </span>
				  
	   </div>
				 
				   
				  {(watch(`missions[${i}].files`) || []).map(
				  (file, index) => (
					  <div
						  onClick={() => {
							  window.open(`${BASE_URL}/${file?.path}`)
						  }}
						  key={String(index)}
						  style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'flex-start',
							  marginBottom: 20,
							  cursor: 'pointer',
						  }}
					  >
						  <img
							  alt=''
							  src={File || 'https://secure.gravatar.com/favicon.ico'}
							  style={{
								  height: 20,
								  marginRight: '1rem',
							  }}
						  />
						  <span
							  style={{
								  marginRight: '1rem',
								  width: '10rem',
							  }}
						  >
							  {file?.name}
						  </span>
					  </div>
				  )
			  )}
	  
               </div>
                }
                <div 
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				marginBottom: '2.5rem',
			}}>
			<p
						style={{
							color: 'black',
							//fontWeight: 'bold',
						}}
					>
						Y a t-il de la manutention chauffeur ?
					</p>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '2rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`manutention_chauffeur-oui-${i}`}
								type='radio'
								value='Oui'
								checked={ commande?.missions[i]?.manutention_chauffeur === 'Oui'}
								disabled={true}
							/>

							<label htmlFor={`manutention_chauffeur-oui-${i}`}>
								Oui
							</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`manutention_chauffeur-non-${i}`}
								type='radio'
								value='Non'
								checked={ commande?.missions[i]?.manutention_chauffeur === 'Non'}
								disabled={true}
							/>

							<label htmlFor={`manutention_chauffeur-non-${i}`}>
								Non
							</label>
						</div>
					</div>
					
			</div>
                {commande?.missions[i].nombre_rippeur &&
				<div
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Nombre de ripper en plus du chauffeur
					</span>
					
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'center',
						marginBottom: '1rem',
					}}
				>
					<div>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='13.605'
							height='27.839'
							viewBox='0 0 13.605 27.839'
							style={{
								marginRight: '.5rem',
							}}
						>
							<g id='user' transform='translate(0.5 0.5)'>
								<path
									id='Path_11624'
									d='M31.488,8.816A3.412,3.412,0,1,1,34.9,5.4,3.412,3.412,0,0,1,31.488,8.816Zm0-6.054a2.645,2.645,0,1,0,2.645,2.645A2.645,2.645,0,0,0,31.488,2.762Z'
									transform='translate(-25.195 -1.992)'
									fill='#51c7f2'
									stroke='#51c7f2'
									strokeWidth='1'
								/>
								<path
									id='Path_11625'
									d='M30.917,33.11h-.752a.383.383,0,1,1,0-.767h.752A1.484,1.484,0,0,0,32.4,30.861V24.829a1.7,1.7,0,0,0-1.7-1.7H23.03a1.7,1.7,0,0,0-1.7,1.7v6.034a1.484,1.484,0,0,0,1.484,1.481h.725a.383.383,0,1,1,0,.767h-.725a2.252,2.252,0,0,1-2.249-2.249V24.829A2.472,2.472,0,0,1,23.03,22.36H30.7a2.472,2.472,0,0,1,2.469,2.469v6.034A2.252,2.252,0,0,1,30.917,33.11Z'
									transform='translate(-20.562 -14.552)'
									fill='#51c7f2'
									stroke='#51c7f2'
									strokeWidth='1'
								/>
								<path
									id='Path_11626'
									d='M38.027,48.646A2.029,2.029,0,0,1,36,46.619V40.231a.383.383,0,0,1,.767,0v6.388a1.26,1.26,0,0,0,2.52,0V34.509a.383.383,0,0,1,.767,0v12.11A2.029,2.029,0,0,1,38.027,48.646Z'
									transform='translate(-30.081 -21.807)'
									fill='#51c7f2'
									stroke='#51c7f2'
									strokeWidth='1'
								/>
								<path
									id='Path_11627'
									d='M29.453,48.646a2.029,2.029,0,0,1-2.027-2.027V34.509a.383.383,0,0,1,.767,0v12.11a1.26,1.26,0,0,0,2.52,0V40.231a.383.383,0,0,1,.767,0v6.388A2.029,2.029,0,0,1,29.453,48.646Z'
									transform='translate(-24.794 -21.807)'
									fill='#51c7f2'
									stroke='#51c7f2'
									strokeWidth='1'
								/>
							</g>
						</svg>
					</div>

					<CustomInput
						width={'100%'}
						type='number'
						disabled={display}
						min={0}
						value={commande?.missions[i]?.nombre_rippeur}
					/>
				</div>

				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<span
						style={{
							marginBottom: '1rem',
							color: 'black',
							fontSize: 14,
						}}
					>
						Nombre d'heures des rippeurs
					</span>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'center',
                        marginBottom:"1rem"
					}}
				>
					<Select
						disabled={display}
						defaultValue={commande?.missions[i].heure_rippeur}	
					>
						{Array.from(
							{ length: 12 },
							(v, i) => i + 1
						).map((i) => (
							<option key={String(i)} value={String(i)}>
								{i}h
							</option>
						))}
					</Select>
				</div>
			</div>}
            <div 
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				marginBottom: '2.5rem',
			}}>
			
			<p
						style={{
							color: 'black',
							//fontWeight: 'bold',
						}}
					>
						Lieu de livraison 
					</p>
					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '2rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`livraison-1-${i}`}
								type='radio'
								disabled={display}
								value='Entrepot/Magasin/Chantier'
								checked={commande?.missions[i]?.livraison === 'Entrepot/Magasin/Chantier'}
							/>

							<label htmlFor={`livraison-1-${i}`}>
								Entrepot/Magasin/Chantier
							</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`livraison-2-${i}`}
								type='radio'
								disabled={display}
								value='Domicile/Batiment/Maison'
								checked={commande?.missions[i]?.livraison === 'Domicile/Batiment/Maison'}
							/>

							<label htmlFor={`livraison-2-${i}`}>
								Domicile/Batiment/Maison
							</label>

						</div>
					</div>
                  
					
					<div>
						{commande?.missions[i]?.livraison === 'Domicile/Batiment/Maison' && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
										width: '30%',
										marginRight: '1rem',
									}}
								>
									<span
										style={{
											marginRight: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Etage
									</span>
									<Select 
									style={{width:'100%'}}
									disabled={display}
									defaultValue={commande.missions[i]?.etage || '0'}
									>
									<option value='0' disabled={display}>0</option>
                                    <option value='1-10' disabled={display}>1-10</option>
									<option value='11-20' disabled={display}>11-20</option>
									<option value='21-30' disabled={display}>21-30</option>
									</Select>
								</div>

								<div
									style={{
										//width: '25%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
									}}
								>
									
                                      <input
                                       type="checkbox"
                                       style={{ marginRight: '1rem' }}
                                       disabled={display}
                                       checked={commande?.missions[i]?.escalier === true}
                                      />
                                     

									<span
										style={{
											marginRight: '0.5rem',
										}}
									>
										Escalier
									</span>
								</div>

								<div
									style={{
										//width: '25%',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
									}}
								>
									
                                <input
                                type="checkbox"
                                style={{ marginRight: '1rem' }}
                                disabled={display}
								checked={commande?.missions[i]?.assenceur === true}
                                />
                              

									<span
										style={{
											marginRight: '0.5rem',
										}}
									>
										Assenceur
									</span>
								</div>
							</div>
							
						)}
					</div>
				
				   
				
			
			</div>
			<div 
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				marginBottom: '2.5rem',
			}}>
			
			<p
						style={{
							color: 'black',
							//fontWeight: 'bold',
						}}
					>
						Nature du chargement  
					</p>
					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '2rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`nature-chargement-1-${i}`}
								type='radio'
								disabled={display}
								value='vrac'
								checked={commande?.missions[i]?.nature_chargement === 'vrac'}
							/>

							<label htmlFor={`nature-chargement-1-${i}`}>
								Vrac
							</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',

							}}
						>
							<input
								id={`nature-chargement-2-${i}`}
								type='radio'
								disabled={display}
								value='palette'
								checked={commande?.missions[i]?.nature_chargement === 'palette'}
							/>

							<label htmlFor={`nature-chargement-2-${i}`}>
								Palette
							</label>

						</div>
					
					</div>
					
					
					<div>
						{commande?.missions[i]?.nature_chargement === 'palette'  && (
							<div style={{
								display: 'flex',
								flexDirection: 'column',
								marginBottom: '1rem',
		
							}}>
							
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											marginBottom: '1.5rem',
											width: '100%',
										}}
									>
										
											<>
												<span
													style={{
														marginBottom: '1rem',
														color: 'black',
														fontSize: 14,
													}}
												>
													Nombre de palettes 	
												</span>
												<CustomInput
													width={'100%'}
													type='number'
													disabled={display}
													min={0}
													value={commande?.missions[i]?.nombre_palettes}
												/>
												
											</>
										
									</div>
								
		
						
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											//marginBottom: '2.5rem',
											width: '100%',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Décrire la nature des palettes
										</span>
										<StyledTextArea
											disabled={display}
											type='text'
											rows={4}
											placeholder=''
											value={commande?.missions[i]?.nature_palettes}
										/>
										
									</div>
								
							</div>
							
						)}
						{commande?.missions[i]?.nature_chargement==='vrac' && 
						<>
						   <div style={{
							position: 'relative',
							width: '100%',
						}}>
						   <CustomInput
							   width={'100%'}
							   type='number'
							   disabled={display}
							   min={0}
							   value={commande?.missions[i]?.nombre_dimension}
						   />
						   <span 
						   style={{
							position: 'absolute',
							right: '3rem',
							top: '1rem',
						   }}>
							M³</span>
					   </div>
					  
                        </>
						}
					</div>
					
				
			
			</div>
			<div 
			style={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				marginBottom: '2.5rem',
			}}>
				<p
						style={{
							color: 'black',
							//fontWeight: 'bold',
						}}
					>

                Type de transport 
					</p>
					<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								//marginBottom: '1rem',
							}}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									marginBottom: '1rem',
								}}
							>
								<div
									style={{
										width: '15rem',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
									}}
								>
									<input
										type='radio'
										disabled={display}
										style={{
											marginRight: '1rem',
										}}
										value={'Véhicule léger'}
										checked={ commande?.missions[i]?.type_transport === 'Véhicule léger'}
									/>

									<span
										style={{
											marginRight: '0.5rem',
										}}
									>
										Véhicule léger
									</span>
									<img
										src={VehiculeLeger}
										style={{
											width: 40,
										}}
										alt=''
									/>
								</div>

								<div
									style={{
										width: '15rem',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
									}}
								>
									<input
										style={{
											marginRight: '1rem',
										}}
										disabled={display}
										type='radio'
										value={'Poids lourds'}
										checked={ commande?.missions[i]?.type_transport === 'Poids lourds'}

									/>
                                   
									<span
										style={{
											marginRight: '0.5rem',
										}}
									>
										Poids lourds
									</span>

									<img
										src={PoidsLours}
										style={{
											width: 40,
										}}
										alt=''
									/>
								</div>
							</div>
						</div>
						{commande?.missions[i]?.type_transport && (
						<>
							<Seperator />

							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
								}}
							>
								{commande?.missions[i]?.type_transport !== 'Poids lourds' && (
									<div 
									style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%'
									}}>
									<div  style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										}}
									>
										<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												
												display: 'flex',
												
												width: '48%', 
												marginRight: '2%', 
											}} >
												<div
												style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
												<img
										src={Break}
										style={{
											width: '100%',
											height: 'auto', 
											maxWidth: '120px', 
											maxHeight: '80px', 
											objectFit: 'cover', 
										  }}
										alt=''
									     />
												</div>
											
											<div
												style={{
													width: '50%',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value="Break"
													checked={commande?.missions[i]?.type_transport_details === 'Break'}
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
												Break	
												</span>
											
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontWeight:"bold",
														fontSize:"14px",
													    marginRight: '0.5rem',
													}}
												>
											1097 kg
											</span>
											</div>
											
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5', 
												}}>
													<span>Dimension en M3 : 6M3 </span>
                                                    <span>Longueur utile : 3750mm </span>  
                                                    <span>Largeur utile : 1956mm </span>  
                                                    <span>Hauteur utile : 1320mm </span>  
                                                    <span>Charge utiles : 1097kg </span>  
													<span>Nombre de palettes: 2 pal europe </span> 
												</div>	
											
										</div>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
											}} >
												<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
													<img
										src={Fourgon12}
										style={{
											width: '100%',
											height: 'auto',
											maxWidth: '120px',
											maxHeight: '80px',
											objectFit: 'cover',
										  }}
										alt=''
									     />
												</div>
												
											<div
												style={{
													
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value="Fourgon 12m³"
													checked={commande?.missions[i]?.type_transport_details === 'Fourgon 12m³'}

												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
												Fourgon 12m³	
												</span>
												
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
													    marginRight: '0.5rem',
														fontWeight: "bold"


													}}
												>
											1244 kg
											</span>
											</div>
										
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 12M3 </span>
													<span>Longueur utile : 3030mm </span>  
                                                    <span>Largeur utile : 2070mm </span>  
                                                    <span>Hauteur utile : 1320mm </span>  
                                                    <span>Charge utiles : 1244kg </span>  
													<span>Nombre de palettes: 5 pal europe</span> 
												</div>	
											
											
											</div>
											
											
								 	</div>
									</div> 	
									<div style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										}}
									>		
											<div
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%',
											}}
											>
										<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
	                                    <img
										src={Fourgon14}
										style={{
											width: '100%',
											height: 'auto',
											maxWidth: '120px',
											maxHeight: '80px',
											objectFit: 'cover',
										}}
										alt=''
									   />
									   </div>
											
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value="Fourgon 14m³"
													checked={commande?.missions[i]?.type_transport_details === 'Fourgon 14m³'}

												/>

												<span
													style={{
														marginRight: '0.5rem',
														
													}}
												>
												Fourgon 14m³	
												</span>
											
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
														fontWeight: "bold",
														marginRight: '0.5rem',

													}}
												>
											1353 kg 
											</span>
											</div>
											
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 14M3 </span>
													<span>Longueur utile : 3680mm </span>  
                                                    <span>Largeur utile : 2070mm </span>  
                                                    <span>Hauteur utile : 1820mm </span>  
                                                    <span>Charge utiles : 1353kg </span>  
													<span>Nombre de palettes: 6 pal europe</span> 
												</div>	
											
											
											</div>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
											}} >
											<div 
											style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										src={vehiculeCamion}
										style={{
											width: '100%',
											height: 'auto',
											maxWidth: '110px',
											maxHeight: '80px',
											objectFit: 'cover',
										  }}
										alt=''
									     />
										 </div>
											<div
												style={{
													
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value="Camion 20m³"
													checked={commande?.missions[i]?.type_transport_details === 'Camion 20m³'}

												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
												Camion 20m³	
												</span>
												
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
											            marginRight: '0.5rem',
														fontWeight: "bold"


													}}
												>
												1200 kg
											</span>
											</div>
										
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 20M3 </span>  
													<span>Longueur utile : 4320mm </span>  
                                                    <span>Largeur utile : 2070mm </span>  
                                                    <span>Hauteur utile : 2100mm </span>  
                                                    <span>Charge utiles : 1200kg </span>  
													<span>Nombre de palettes: 7 pal europe</span> 
												</div>	
											

											</div>
									</div>
									
									</div>
									</div>
								)}
							</div>

							{commande?.missions[i]?.type_transport !== 'Poids lourds' && (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										//marginBottom: '2.5rem',
									}}
								>
									<span
										style={{
											marginBottom: '1rem',
											color: 'black',
											fontSize: 14,
										}}
									>
										Options Véhicule léger
									</span>
									
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											marginBottom: '1rem',
										}}
									>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												marginBottom: '1rem',
											}}
										>
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value={'Frigorifique'}
													checked={commande?.missions[i]?.options_vehicule_leger?.includes('Frigorifique')}
												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Frigorifique
												</span>
											</div>
                                             {commande?.missions[i]?.type_transport_details==="Camion 20m³" &&
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value={'Bachée'}
													checked={commande?.missions[i]?.options_vehicule_leger?.includes('Bachée')}


												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Bachée
												</span>
											</div>
                                            }
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value={'Tall'}
													checked={commande?.missions[i]?.options_vehicule_leger?.includes('Tall')}

												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Tall
												</span>
											</div>

											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value={'Hayon'}
													checked={commande?.missions[i]?.options_vehicule_leger?.includes('Hayon')}

												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Hayon
												</span>
											</div>
										</div>

										{commande?.missions[i]?.type_transport == null && (
											<Seperator />
										)}
									</div>
								</div>
							)}

							{commande?.missions[i]?.type_transport !== 'Véhicule léger' && (
								<div
                                    style={{
									display: 'flex',
									flexDirection: 'column',
									width: '100%'
									}}>
									<div style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
										}}
									>
										    <div 
											style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										    }}>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%', 
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={tonnes7}
											style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
											
												<div
												
												style={{
													width: '50%',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value="7,5 Tonnes"
													checked={ commande?.missions[i]?.vehicule_poids_lourds=== '7,5 Tonnes'}
												/>

												<span>7,5 Tonnes</span>
												
											</div>
											<div
											style={{
												marginLeft: 36,
											
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
														marginRight: '0.5rem',
														fontWeight: "bold"

													}}
												>
												2470 kg
											</span>
											</div>
										
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5', 
												
												}}>
													<span>Dimension en M3 : 30M3 </span>
													<span>Longueur utile : 6575mm </span>  
                                                    <span>Largeur utile : 2045mm </span>  
                                                    <span>Hauteur utile : 2400mm </span>  
                                                    <span>Charge utiles : 2470kg </span>  
													<span>Nombre de palettes: 16 pal europe</span> 
												</div>	
											
											</div>	
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%',
												marginTop:-5
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={tonnes12}
											style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
											
											<div
												
												style={{
													width: '50%',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
													
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value='12 Tonnes'
													checked={ commande?.missions[i]?.vehicule_poids_lourds=== '12 Tonnes'}

													
												/>

												<span>12 Tonnes</span>
												
                                              
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
											            marginRight: '0.5rem',
														fontWeight: "bold"

													}}
												>
												5600 kg
											</span>
											</div>
											
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 45M3 </span>
													<span>Longueur utile : 7540mm </span>  
                                                    <span>Largeur utile : 2480mm </span>  
                                                    <span>Hauteur utile : 2500mm </span>  
                                                    <span>Charge utiles : 5600kg </span>  
													<span>Nombre de palettes: 19 pal europe</span> 
												</div>	
											
											</div>
											</div>
									</div>
									</div>
									<div style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										    <div 
											style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										    }}>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%', 
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={tonnes19}
										    style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
												
											<div
												
												style={{
													width: '50%',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value='19 Tonnes'
													checked={ commande?.missions[i]?.vehicule_poids_lourds=== '19 Tonnes'}
	
												/>

												<span>19 Tonnes</span>
												
                                              
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
														marginRight: '0.5rem',
														fontWeight: "bold"

													}}
												>
												9200 kg
											</span>
											</div>
											
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 58M3 </span>
													<span>Longueur utile : 9100mm </span>  
                                                    <span>Largeur utile : 2470mm </span>  
                                                    <span>Hauteur utile : 2490mm </span>  
                                                    <span>Charge utiles : 9200kg </span>  
													<span>Nombre de palettes: 22 pal europe</span> 
												</div>	
											
											</div>
											{watch('type_commande')!=="course-a-course" &&
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%',
											
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>	
											<img
										    src={SemiImg}
										    style={{
												width: '100%',
												height: 'auto',
												maxWidth: '140px',
												maxHeight: '80px',
												objectFit: 'cover',
												
											  }}
										    alt=''
									        />
											</div>
											<div
												
												style={{
													width: '50%',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
														marginTop:-20
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value='Semi'
													checked={ commande?.missions[i]?.vehicule_poids_lourds=== 'Semi'}

												/>

												<span>Semi</span>
												
											</div>
											<div
											style={{
												marginLeft: 36,
												
											}}
											>
											<span
													style={{
														fontSize:"14px",
												        marginRight: '0.5rem',
														fontWeight: "bold"

													}}
												>
												27000 kg
											</span>
											</div>
											
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5',
												}}>
													<span>Dimension en M3 : 86M3 </span>
													<span>Longueur utile:13500mm</span>  
                                                    <span>Largeur utile : 2470mm </span>  
                                                    <span>Hauteur utile : 2590mm </span>  
                                                    <span>Charge utiles : 27000kg </span>  
													<span>Nombre de palettes: 33 pal europe</span> 
												</div>	
											
											</div>
                                            }
											
											

											</div>
									</div>
									</div>
									
									<div style={{width: '100%'}}>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
										}}
									>
										    <div 
											style={{
											display: 'flex',
											flexDirection: 'row',
											marginBottom: '1rem',
											width: '100%',
										    }}>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%', 
												marginRight: '2%',
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={Semi}
										    style={{
												width: '100%',
												height: 'auto',
												maxWidth: '120px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
												
												<div
												
												style={{
													width: '50%',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value='Tracteur+semi'
													checked={ commande?.missions[i]?.vehicule_poids_lourds=== 'Tracteur+semi'}

													/>

												<span>Tracteur+semi</span>
												
											</div>
											
										
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5', 
												}}>
													<span>Longueur utile : 2.45 M</span>  
                                                    <span>Largeur utile : 1.50 M</span>
                                                    <span>Hauteur utile : 1.60 M</span>  
                                                    <span>Volume utile : 6 M3</span>  
                                                    <span>Charge max : 1000 kg</span>  
													<span>Nombre de palettes: 32 pal europe</span>   
												</div>	
											
											</div>
											<div 
											style={{
												display: 'flex',	
												flexDirection: 'column',
												width: '48%',
											}} >
											<div style={{ width: '100%', height: 'auto', marginBottom: '0.5rem' }}>
											<img
										    src={tracteurSemi}
										    style={{
												width: '100%',
												height: 'auto',
												maxWidth: '100px',
												maxHeight: '80px',
												objectFit: 'cover',
											  }}
										    alt=''
									        />
											</div>	
											
											<div
												
												style={{
													width: '50%',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='radio'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value='Tracteur'
													checked={ commande?.missions[i]?.vehicule_poids_lourds=== 'Tracteur'}

													
												/>

												<span>Tracteur</span>
                                                
											</div>
								
										
												<div style={{
												display: 'flex',	
												flexDirection: 'column',
												background:'#f2f3f4',
												padding:10,
												width:'70%',
												borderRadius:'5px',
												marginTop:'10px',
												fontSize: '13px', 
												lineHeight: '1.5', 
												}}>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Longueur utile : </span>  
													<span>2.45 M</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Largeur utile : </span>  
													<span>1.50 M</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Hauteur utile : </span>  
													<span>1.60 M</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Volume utile : </span>  
													<span>6 M3</span>   
													</div>
													<div
													style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent:'flex-start',
														
												   }}>
                                                    <span>Charge max : </span>  
													<span>1000 kg</span>   
													</div>
												</div>	
										
											</div>
											
											</div>
									</div>
									</div>
									
                                    
									

									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<span
											style={{
												marginBottom: '1rem',
												color: 'black',
												fontSize: 14,
											}}
										>
											Options poids lourds
										</span>
										<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											marginBottom: '1rem',
										}}
									>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												marginBottom: '1rem',
											}}
										>
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value={'Frigorifique'}
													checked={commande?.missions[i]?.options_poids_lourds?.includes('Frigorifique')}


												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Frigorifique
												</span>
											</div>
											{commande?.missions[i].vehicule_poids_lourds !=="Tracteur" &&
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value={'Bachée'}
													checked={commande?.missions[i]?.options_poids_lourds?.includes('Bachée')}


												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Bachée
												</span>
											</div>
                                            }
											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value={'Tall'}
													checked={commande?.missions[i]?.options_poids_lourds?.includes('Tall')}

												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Tall
												</span>
											</div>

											<div
												style={{
													width: '15rem',
													display: 'flex',
													alignItems: 'center',
													justifyContent:
														'flex-start',
												}}
											>
												<input
													type='checkbox'
													style={{
														marginRight: '1rem',
													}}
													disabled={display}
													value={'Hayon'}
													checked={commande?.missions[i]?.options_poids_lourds?.includes('Hayon')}

												/>

												<span
													style={{
														marginRight: '0.5rem',
													}}
												>
													Hayon
												</span>
											</div>
										</div>

										
									</div>
									</div>
								</div>
							)}
						</>
					)}
							
					
				  
				  
			</div>
			<div>
		   <p
						style={{
							color: 'black',
							//fontWeight: 'bold',
						}}
					>
						Y a-t-il un transport de matières dangereuses ? 
					</p>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '2rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`danger-oui-${i}`}
								type='radio'
								disabled={display}
								value='Oui'
								checked={commande?.missions[i].danger === 'Oui'}

							/>

							<label htmlFor={`danger-oui-${i}`}>Oui</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`danger-non-${i}`}
								type='radio'
								disabled={display}
								value='Non'
								checked={commande?.missions[i].danger === 'Non'}

							/>

							<label htmlFor={`danger-non-${i}`}>Non</label>
						</div>
					</div>
				




		   </div>
		   <div>
		   <p
						style={{
							color: 'red',
							fontWeight: 'bold',
						}}
					>
						La plateforme prend en charge jusqu'a 100 000 euros de
						valeur marchandise.
			</p>
			
			<p
						style={{
							color: 'black',
							fontWeight: 'bold',
						}}
					>
						La valeur de votre chargement dépasse t'elle 100 000
						euros?
					</p>

					<div
						className='radio-btn-group-palettes'
						style={{
							display: 'flex',
							flexDirection: 'row',
							marginBottom: '2rem',
						}}
					>
						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`assurance-oui-${i}`}
								type='radio'
								disabled={display}
								value='Oui'
								checked={commande?.missions[i]?.assurance === 'Oui'}

							/>

							<label htmlFor={`assurance-oui-${i}`}>Oui</label>
						</div>

						<div
							className='radio'
							style={{
								marginRight: '1rem',
							}}
						>
							<input
								id={`assurance-non-${i}`}
								type='radio'
								disabled={display}
								value='Non'
								checked={commande?.missions[i]?.assurance === 'Non'}
							/>

							<label htmlFor={`assurance-non-${i}`}>Non</label>
						</div>
					</div>
					
					<div>
						{commande?.missions[i]?.assurance === 'Oui' && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										justifyContent: 'center',
										//width: '30%',
										marginRight: '1rem',
									}}
								>
									<span
										style={{
											color: 'black',
											fontWeight: 'bold',
											marginBottom: '1rem',
										}}
									>
										Ci-dessous saisir la valeur du
										chargement afin que nous puissions
										avertir les assureurs
									</span>

									<div
										style={{
											position: 'relative',
											width: '100%',
										}}
									>
										<CustomInput
											width={'100%'}
											type='text'
											disabled={display} 
										    value={commande?.missions[i]?.assurance_amount}
										/>
										<span
											style={{
												position: 'absolute',
												right: '3rem',
												top: '1rem',
												color: 'red',
											}}
										>
											€
										</span>
									</div>
								</div>

								<p
									style={{
										color: 'black',
										fontWeight: 'bold',
									}}
								>
									Vous avez la possiblité de ne pas saisir la
									valur du chargment, cependant nous
									retiendrons la valeur de 100 000 euros. Cela
									conformément au conditions d'utilisation de
									notre plateforme.
								</p>
								
      

							</div>
						)}
					</div>

		   </div>
    </div>
  )
}

export default MissionsMiseADispoReadOnly