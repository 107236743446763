import React ,{useState,useEffect} from 'react'
import CommonHeader from '../../../Components/CommonHeader/common-header.component'
import { useParams,useHistory } from 'react-router-dom';
import { GradientText,SubmitButton,SubmitButtonContainer } from '../../PasserCommande/PasserCommande.styles';
import { FormSectionCard } from '../../PasserCommande/PasserCommande.styles';
import { validateToken } from '../../../api/transporteurs';
import {uploadDocument} from '../../../api/upload'
import toast from 'react-hot-toast';
import { FileInputContainer } from '../../PasserCommande/PasserCommande.styles';
import File from  '../../../assets/img/file@2x.png'


function UpdateDocument() {
    const {token}=useParams()
    const history=useHistory()
    const [file, setFile] = useState(null);
    const [documentKey, setDocumentKey] = useState('');
    const [documentLabel,setDocumentLabel]=useState('')
    const [loading,setLoading]=useState(false)
    const accept = 'pdf';

    const handleChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
          const fileType = selectedFile.type.split('/')[1];
          if (accept.includes(fileType)) {
            setFile(selectedFile);
          } else {
            alert(`Désolé, le fichier ${selectedFile.name} n'est pas pris en charge. Veuillez choisir un fichier au format ${accept.replace('.', '')}.`);
          }
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault()
        if(!file){
            toast.error('Veuillez sélectionner un document',{
				style: {
					fontFamily: 'Montserrat',
				},
			})
            return
        }
        setLoading(true)
        try {
         const formData = new FormData();
         formData.append(documentKey,file)
         formData.append('token', token);
         await uploadDocument(formData)
         toast.success('Document mis à jour avec succès',{
            style: {
                fontFamily: 'Montserrat',
            },
        });
        history.push('/')
        } catch (error) {
            toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			});
        }finally{
            setLoading(false)
        }
    }
    useEffect(() => {
        const _validateToken = async () => {
          try {
            const response = await validateToken(token)
            setDocumentKey(response.documentKey);
            setDocumentLabel(response.documentLabel);
          } catch (err) {
            toast.error("Une erreur s'est produite", {
                style: {
                    fontFamily: 'Montserrat',
                },
            });
          }
        };
        _validateToken();
      }, [token]);

  return (
    <div>
    <CommonHeader/>
    <div
		style={{
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			paddingTop: '2rem',
			paddingBottom: '4rem',
		}}
	>
        <GradientText>
			{`Mettre à jour le document` .toUpperCase()}
		</GradientText>
        <div
			style={{
					width: '60%',
					fontFamily: 'Montserrat',
			}}
		>
            <form onSubmit={handleSubmit}>
            <FormSectionCard>
            <span
                        style={{
                        marginBottom: '1rem',
                        color: 'black',
                        fontSize: 14,
                        }}
                        >
                       {documentLabel} {' '} (PDF)
                       </span> 

                       <div
      style={{
        display: 'flex',
        flex: 1,
        borderRadius: '0.5rem',
        background: 'white',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1rem',
        position: 'relative',
      }}
    >
      {file && (
        <img
          src={File}
          style={{
            height: 20,
            marginLeft: '1rem',
            marginRight: '1rem',
          }}
          alt='Fichier'
        />
      )}
      <span
        style={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {file && file.name}
      </span>
      <FileInputContainer>
        <input
          type='file'
          id={documentKey}
          name={documentKey}
          className='file'
          accept={'.pdf'}
          onChange={handleChange}
        />
        <label htmlFor={documentKey}>Télécharger</label>
      </FileInputContainer>
    </div>

             <SubmitButtonContainer>
							<SubmitButton type='submit' disabled={loading}>
								{loading ? (
									<div className='loader'></div>
								) : (
									'Soumettre'
								)}
							</SubmitButton>
			  </SubmitButtonContainer>

            </FormSectionCard>
           
            </form>
            
        </div>

    </div>

    </div>
  )
}

export default UpdateDocument