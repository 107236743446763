import { Controller, useForm } from 'react-hook-form'
import React, { useEffect, useState } from 'react'
import {
	
	softDeleteClient,
	getSingleClient,
	toggleSuspendreClient,
	updateClient,
} from '../../api/clients'
import { useHistory, useParams } from 'react-router'
import { CustomInput } from '../RegisterTransporter/style'
import File from '../../assets/img/file@2x.png'
import { FormSectionCard } from '../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../Components/LayoutAdmin/LayoutAdmin'
import { BASE_URL } from '../../api/base'
import Switch from 'react-switch'
import toast from 'react-hot-toast'
import Modal from 'react-modal'
import { useSnackbar } from 'react-simple-snackbar'
import { useSelector } from 'react-redux'

function SingleClient() {
	const { register, handleSubmit, reset, control ,formState: { errors, isValid, isDirty }} = useForm()
	const { id } = useParams()
	const history = useHistory()
	const token = useSelector((state) => state.auth.token);
	const [client, setClient] = useState(null)
	const [modalIsOpen,setModalIsOpen]=useState(false)
	const [modalIsOpenDelete,setModalIsOpenDelete]=useState(false)
	const [loading, setLoading] = useState(false)
	const user = useSelector((state) => state.auth.user)
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const [suspendre, setSuspendre] = useState(false)
	const [openSnackbar] = useSnackbar({
		position: 'top-center',
		style: {
			color: 'black',
			background: 'white',
			fontFamily: 'Montserrat',
		},
		closeStyle: {
			fontSize: '2rem',
			color: '#51C7F2',
		},
	})

	useEffect(() => {
		async function getClient(id) {
			setChargementEnCours(true)
			const data = await getSingleClient(id,token)
			setClient(data)
			setSuspendre(data.suspendre === true)
			reset(data)
			setChargementEnCours(false)

		}

		if (id) {
			getClient(id)
		}
	}, [id])

	const onSubmit = async (values) => {
	

		delete values._id
		delete values.verified
		delete values.verification
		delete values.updatedAt
		delete values.logo
		delete values.kbis
		delete values.createdAt
		//delete values.email
		delete values.suspendre
		delete values.status

		if (!values.reduction) {
			values.reduction = 0
		}
		
		try {
			await updateClient(client._id, values,token)
		
		if(values.email !== client.email){
			openSnackbar(
				"L'email de votre client a été modifié. Veuillez informer le client que son compte a été mis à jour avec succès. Le client doit confirmer son compte en cliquant sur le lien de confirmation envoyé à sa nouvelle adresse e-mail.",
				120000
		   )
		}
		if(!modalIsOpen && !modalIsOpenDelete){
            history.goBack()
        }
		} catch (error) {
			const data = error?.response?.data?.errors
			if (data.msg === 'EMAIL_ALREADY_EXISTS') {
				toast.error(
					'Ce mail est déjà utilisé par un autre utilisateur.',
					{
						style: {
							fontFamily: 'Montserrat',
						},
					}
				)
			}
		}
		
		
	}

	const onError = async (errors) => {
		if (errors.password) {
			toast('Mot de pass doit être de 8 caractères minimum', {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		}
	}

	return (
		<LayoutAdmin>
			<div
				style={{
					width: '100%',
					fontFamily: 'Montserrat',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					paddingBottom: '1rem',
				}}
			> 
			{chargementEnCours ?
			    <div
			style={{
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center',
			  backgroundColor: 'white',
			  marginTop:"10rem"
			}}
			>
			<div className='customLoader'></div>	
			    </div>
			      :
				<form
					style={{ width: '60%' }}
					onSubmit={handleSubmit(onSubmit, onError)}
				>
					<FormSectionCard>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<div>
								<p
									style={{
										color: 'black',
										fontWeight: 'bold',
									}}
								>
									Fiche Client
								</p>
							</div>

							<div
								style={{
									display: 'flex',
								}}
							>
								<button
									onClick={ () => {
										setModalIsOpen(true)
									}}
									style={{
										border: 'none',
										background: '#ECC9F7',
										color: '#AB1CC5',
										fontSize: 17,
										fontFamily: 'Montserrat',
										fontWeight: 'bold',
										padding: '0.6rem 1rem',
										borderRadius: 5,
										cursor: 'pointer',
									}}
								>
									{suspendre ? 'Activer' : 'Suspendre'}
								</button>
								{(user.role==="admin" || user?.role==="super admin" )&&
								<button
									onClick={ () => {
										setModalIsOpenDelete(true)
									}}
									style={{
										border: 'none',
										background: '#F7CDC9',
										color: '#C5431C',
										fontSize: 17,
										fontFamily: 'Montserrat',
										fontWeight: 'bold',
										padding: '0.6rem 1rem',
										borderRadius: 5,
										cursor: 'pointer',
										marginLeft: '1rem',
									}}
								>
									Supprimer
								</button>
                                }
								<button
									type='submit'
									style={{
										border: 'none',
										background: '#168EEA',
										color: '#FFF',
										fontSize: 17,
										fontFamily: 'Montserrat',
										fontWeight: 'bold',
										padding: '0.6rem 1rem',
										borderRadius: 5,
										cursor: 'pointer',
										marginLeft: '2rem',
									}}
								>
									Enregistrer
								</button>
							</div>
						</div>
					</FormSectionCard>
					<FormSectionCard>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Nom société
								</span>
								<CustomInput
									type='text'
									disabled
									placeholder='Wayne'
									{...register('societe', {
										required: true,
									})}
								/>
							</div>

							<div style={{ width: 50 }} />

							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									SIRET
								</span>
								<CustomInput
								    disabled
									type='text'
									placeholder='833 543 509 000'
									{...register('siret', {
										required: true,
									})}
								/>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Nom
								</span>
								<CustomInput
								    disabled
									type='text'
									placeholder='Doe'
									{...register('last_name', {
										required: true,
									})}
								/>
							</div>

							<div style={{ width: 50 }} />

							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Prénom
								</span>
								<CustomInput
								    disabled
									type='text'
									placeholder='John'
									{...register('first_name', {
										required: true,
									})}
								/>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Qualité
								</span>
								<CustomInput
								    disabled
									type='text'
									placeholder='Directeur d’exploitation'
									{...register('quality', {
										required: true,
									})}
								/>
							</div>

							<div style={{ width: 50 }} />

							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Domaine d’activité
								</span>
								<CustomInput
								    disabled
									type='text'
									placeholder='Transport frigorifique'
									{...register('domain', {
										required: true,
									})}
								/>
							</div>
						</div>

						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Adresse
							</span>
							<CustomInput
							    disabled
								type='text'
								placeholder='54 rue avenue de la grande armée, 75017, Paris, France'
								{...register('address', {
									required: true,
								})}
							/>
						</div>

						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Ville
							</span>
							<CustomInput
							    disabled
								type='text'
								placeholder='Paris'
								{...register('city', {
									required: false,
								})}
							/>
						</div>

						<div
							style={{
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								paddingBottom: '2rem',
							}}
						>
							<span
								style={{
									color: '#262626',
									paddingBottom: '1rem',
								}}
							>
								Code Postal
							</span>
							<CustomInput
								type='text'
								disabled
								placeholder='75017'
								{...register('postalCode', {
									required: false,
								})}
							/>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Téléphone
								</span>
								<CustomInput
								    disabled
									type='text'
									placeholder='(0) 01 45 66 32 88'
									{...register('phone', {
										required: true,
									})}
								/>
							</div>

							<div style={{ width: 50 }} />

							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Adresse email
								</span>
								<CustomInput
								    disabled
									type='text'
									placeholder='John.doe@wayne.fr'
									{...register('email', {
										required: "Veuillez indiquer l'adresse e-mail.",
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
											message: 'Veuillez entrer une adresse e-mail valide',
										  },
									})}
								/>
								{errors.email && (
                                    <div style={{ color: 'red', marginTop: '0.5rem',fontSize:'14px' }}>
                                    {errors.email.message}
                                    </div>
                                   )}
							</div>
						</div>

						

						{client?.logo && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '1rem',
									width: '50%',
									fontFamily: 'Montserrat',
									marginLeft: 10,
									marginTop: 10,
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									Logo (PNG ou JPG)
								</span>

								<img
									alt=''
									style={{
										width: 100,
										height: 100,
										borderRadius: 5,
										objectFit: 'cover',
									}}
									src={`${BASE_URL}/${client.logo.path}`}
								/>
							</div>
						)}

						{client?.kbis && (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									marginBottom: '1rem',
									width: '50%',
									fontFamily: 'Montserrat',
									marginLeft: 10,
									marginTop: 10,
								}}
							>
								<span
									style={{
										marginBottom: '1rem',
										color: 'black',
										fontSize: 14,
									}}
								>
									KBIS (PDF ou JPG)
								</span>

								{client?.kbis?.mimetype !==
									'application/pdf' && (
									<img
										alt=''
										style={{
											width: 100,
											height: 100,
											borderRadius: 5,
											objectFit: 'cover',
										}}
										src={`${BASE_URL}/${client?.kbis?.path}`}
									/>
								)}

								{client?.kbis?.mimetype ==
									'application/pdf' && (
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<img
											alt=''
											src={File}
											style={{
												height: 20,
												marginRight: '1rem',
											}}
										/>
										<span
											style={{
												cursor: 'pointer',
											}}
											onClick={() => {
												window.open(
													`${BASE_URL}/${client.kbis.path}`
												)
											}}
										>
											{client?.kbis?.name}
										</span>
									</div>
								)}
							</div>
						)}

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Activer compte premium
								</span>
								<Controller
									control={control}
									name='premium'
									render={({
										field: { value, onChange },
									}) => (
										<Switch
											onChange={onChange}
											checked={value}
											offColor='#F3F6F9'
											onColor='#F3F6F9'
											checkedIcon={false}
											uncheckedIcon={false}
											offHandleColor='#BFBFBF'
											onHandleColor='#168EEA'
											height={40}
											width={80}
											boxShadow='0px 5px 7px 0px rgba(0,0,0,0.23)'
										/>
									)}
								/>
							</div>

							<div style={{ width: 50 }} />

							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									Paramétrer une réduction en %
								</span>
								<CustomInput
									type='number'
									min={0}
									max={100}
									{...register('reduction', {})}
								/>
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
								Petite entreprise
								</span>
								<Controller
									control={control}
									name='petiteentreprise'
									render={({
										field: { value, onChange },
									}) => (
										<Switch
											onChange={onChange}
											checked={value}
											offColor='#F3F6F9'
											onColor='#F3F6F9'
											checkedIcon={false}
											uncheckedIcon={false}
											offHandleColor='#BFBFBF'
											onHandleColor='#168EEA'
											height={40}
											width={80}
											boxShadow='0px 5px 7px 0px rgba(0,0,0,0.23)'
										/>
									)}
								/>
							</div>

							<div style={{ width: 50 }} />

							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
									seuil
								</span>
								<CustomInput
									type='number'
									
									{...register('seuilDepenses', {})}
								/>
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
							     TVA
								</span>
								<Controller
									control={control}
									name='tva'
									render={({
										field: { value, onChange },
									}) => (
										<Switch
											onChange={onChange}
											checked={value}
											offColor='#F3F6F9'
											onColor='#F3F6F9'
											checkedIcon={false}
											uncheckedIcon={false}
											offHandleColor='#BFBFBF'
											onHandleColor='#168EEA'
											height={40}
											width={80}
											boxShadow='0px 5px 7px 0px rgba(0,0,0,0.23)'
										/>
									)}
								/>
							</div>
							<div style={{ width: 50 }} />
                            <div
								style={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingBottom: '2rem',
								}}
							>
								<span
									style={{
										color: '#262626',
										paddingBottom: '1rem',
									}}
								>
							     Activer les rippeurs
								</span>
								<Controller
									control={control}
									name='activerRippeur'
									render={({
										field: { value, onChange },
									}) => (
										<Switch
											onChange={onChange}
											checked={value}
											offColor='#F3F6F9'
											onColor='#F3F6F9'
											checkedIcon={false}
											uncheckedIcon={false}
											offHandleColor='#BFBFBF'
											onHandleColor='#168EEA'
											height={40}
											width={80}
											boxShadow='0px 5px 7px 0px rgba(0,0,0,0.23)'
										/>
									)}
								/>
							</div>
						</div>
					</FormSectionCard>
				</form>
            }
			</div>
			<Modal
				isOpen={modalIsOpen}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>{suspendre ? 'Activer Client' : 'Suspendre Client'}</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>{`Êtes-vous sûr de vouloir ${suspendre ? 'Activer' : 'Suspendre'} ce Client ?`}</p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
						setLoading(true)
						try {
							const data={suspendre:!suspendre}
							const res=await toggleSuspendreClient(
								client._id,
								data,
								token
							)
					    toast.success(res.message, {
						style: {
							fontFamily: 'Montserrat',
						},
					})
					setSuspendre(!suspendre)
					history.goBack()
						} catch (error) {
							toast.error(
								error.response?.data.message || 'Erreur lors de la suspension du client',
								{
									style: {
										fontFamily: 'Montserrat',
									},
								}
							)
						}finally {
							setLoading(false)
							setModalIsOpen(false)
						}	
						}}
						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
					  {loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
					 )}
					</button>
					<button
						onClick={() => {
							
							setModalIsOpen(false)	
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	</Modal>
	<Modal
				isOpen={modalIsOpenDelete}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Supprimer Client</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir supprimer ce Client ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoading(true)
							   try {
								const res=await softDeleteClient(client._id,token)
								toast.success(res.message, {
									style: {
										fontFamily: 'Montserrat',
									},
								})
								history.goBack()
							   } catch (error) {
								toast.error(
									error.response?.data.message || "Erreur lors de la suppression du client ",
									{
										style: {
											fontFamily: 'Montserrat',
										},
									}
								)
							   }finally {
								setLoading(false)
								setModalIsOpenDelete(false)
							}
							  
                              
                            }}

						disabled={loading}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
					 {loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
					 )}
					</button>
					<button
						onClick={ () => {
							setModalIsOpenDelete(false)						
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>	
		</LayoutAdmin>
	)
}

export default SingleClient
