import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { Select } from '../../Containers/PasserCommande/PasserCommande.styles';
import { addPackage } from '../../api/commande';
import { computePriceForfait,computePriceEtage,computePriceHeure } from '../../utils/price';
import moment from 'moment';


function AddForfaitSupplementaireModal({  isOpen, onClose, mission }) {
  const token = useSelector((state) => state.auth.token);
  const user= useSelector((state) => state.auth.user);
  const tarif = useSelector((state) => state.tarif.tarif);
  const [selectedKilometrage, setSelectedKilometrage] = useState(null);
  const [selectedHeures, setSelectedHeures] = useState(null);
  const [selectedEtages, setSelectedEtages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [priceTotal,setPriceTotal]=useState(0)
  const [priceEtage,setPriceEtage]=useState(0)
  const [priceKilometrage,setPriceKilometrage]=useState(0)
  const [priceHeure,setPriceHeure]=useState(0)
  const [priceAvecReduction, setPriceAvecReduction] = useState(0);
  
  
  const convertToDecimal=(timeString)=> {
  const [hours, minutes] = timeString.split(':').map(Number);
  return hours + (minutes / 60);
  }
  const extractHoursFromForfaits = (forfaits) => {
    return forfaits?.reduce((total, forfait) => {
      if (forfait.type === "heure" || forfait.type === "heure_etage") {
        total += convertToDecimal(forfait.details.heures);
      }
      return total;
    }, 0);
  };

  const calculateTotalHours = (dateDebut, heureDebut, dateFin, heureFin,extraHours) => {
    const startDateTime = `${dateDebut} ${heureDebut}`;
    const endDateTime = `${dateFin} ${heureFin}`;

    const startMoment = moment(startDateTime, 'DD/MM/YYYY HH:mm');
    const endMoment = moment(endDateTime, 'DD/MM/YYYY HH:mm');

    let totalHours = endMoment.diff(startMoment, 'hours', true);

    // Add extra hours to the total
    
     totalHours += extraHours;

    return totalHours;
  };
  const calculateHeuresSupplementaires = () => {
    if (!mission) return 0;
    const extraHours = extractHoursFromForfaits(mission?.forfaits);
    const totalHours = calculateTotalHours(
      mission.date_debut,
      mission.heure_debut,
      mission.date_fin,
      mission.heure_fin,
      extraHours
    );

    const intervalAutorise = 12;

    return intervalAutorise > totalHours ? intervalAutorise -totalHours   : 0;
  };
  const generateHourOptions = () => {
    const heuresSupplementaires = calculateHeuresSupplementaires();
    const options = [];

    for (let i = 1; i <= heuresSupplementaires; i++) {
      options.push(`${i}:00`);
      if (i < heuresSupplementaires) {
        options.push(`${i}:30`);
      }
    }

    return options;
  };
  const handleAddPackage = async () => {
    if (!mission || (!selectedKilometrage && !selectedHeures && !selectedEtages)) {
        toast.error("Veuillez sélectionner une mission et un forfait approprié.", {
          style: {
            fontFamily: 'Montserrat',
          },
        });
        return;
      }
    let selectedPackage = null;
    let additionalDetails = {};
  
    if (selectedKilometrage && selectedEtages) {
      selectedPackage = 'kilometrage_etage';
      additionalDetails = {
        kilometrage: selectedKilometrage,
        etages: selectedEtages,
      };
    }  else if (selectedHeures && selectedEtages) {
      selectedPackage = 'heure_etage';
      additionalDetails = {
        heures: selectedHeures,
        etages: selectedEtages,
      };
    } else if (selectedKilometrage) {
      selectedPackage = 'kilometrage';
      additionalDetails = {
        kilometrage: selectedKilometrage,
      };
    } else if (selectedHeures) {
      selectedPackage = 'heure';
      additionalDetails = {
        heures: selectedHeures,
      };
    } else if (selectedEtages) {
      selectedPackage = 'etage';
      additionalDetails = {
        etages: selectedEtages,
      };
    } 
    setLoading(true);
    try {
      const response = await addPackage(mission._id, { forfaitType: selectedPackage, forfaitPrice: user?.reduction ? priceAvecReduction :priceTotal ,additionalDetails}, token);
      if (response.url) {
        window.location.href = response.url;
      }else {
        throw new Error("Erreur lors de la récupération de l'URL");
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        style: {
          fontFamily: 'Montserrat',
        },
      });
    } finally {
      setLoading(false);
      onClose()

    }
  };
  const showPrice = () => {
    let result = '';
  
    if (priceKilometrage) {
      result += priceKilometrage.toFixed(2);
    }
  
    if (priceHeure) {
      result += (result ? ' + ' : '') + priceHeure.toFixed(2);
    }
  
    if (priceEtage) {
      result += (result ? ' + ' : '') + priceEtage.toFixed(2);
    }
  
    return result;
  };
  

  useEffect(()=>{
    if(selectedKilometrage){
      setSelectedHeures(null)
      setPriceHeure(0)
    }else if(selectedHeures){
      setSelectedKilometrage(null)
      setPriceKilometrage(0)
    }

  },[selectedHeures,selectedKilometrage])

  useEffect(()=>{
    setPriceTotal(priceKilometrage+priceHeure+priceEtage)
  },[priceEtage,priceHeure,priceKilometrage])
  useEffect(()=>{
  setPriceAvecReduction(Number(priceTotal) - (Number(priceTotal) * user?.reduction) / 100)
  },[priceTotal])
  useEffect(()=>{
    
    if(selectedKilometrage){
      const heureSupp=extractHoursFromForfaits(mission?.forfaits)
      setPriceKilometrage(computePriceForfait(tarif,mission,selectedKilometrage, calculateTotalHours(mission?.date_debut,mission?.heure_debut,mission?.date_fin, mission?.heure_fin,heureSupp)))
    }else{
      setPriceKilometrage(0)
    }
   
  },[selectedKilometrage,mission])
  useEffect(()=>{
    if(mission && selectedHeures){
      setPriceHeure(computePriceHeure(tarif,mission,selectedHeures)) 
    }else{
      setPriceHeure(0)
    }
  },[selectedHeures,mission])
  useEffect(()=>{
    if(mission && selectedEtages){
      setPriceEtage(computePriceEtage(tarif,mission,selectedEtages))
    }else{
      setPriceEtage(0)
    }
  },[mission,selectedEtages])

  
  return (
    <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    style={{
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 19,
            background: '#E4EAF0',
            padding: '2rem',
            width:"700px",
            height:"550px"
        
            

        },
    }}
    contentLabel='Ajouter forfait'
>
      <div
        style={{
          width: '100%',
          fontFamily: 'Montserrat',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 15,
          textAlign: 'left',
          color: '#838EAB',
        }}
      >
       <div
						style={{
							color: '#51C7F2',
							fontSize: 25,
							marginBottom: '1rem',
                            fontWeight: 'bold',
						    fontFamily: 'Montserrat',
							
						}}
					>
						  Ajouter forfait supplémentaire
	   </div>
        
     	
                   
          <div
          style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%',
          marginBottom: '1rem',
          marginTop:'1rem'
          }}
         >
          
          <div 
		  style={{
			width: '100%',
			fontFamily: 'Montserrat',
			marginBottom: '2rem',
            display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			
		}}>
      
        
		
             
              <div >
                <span
                  style={{
                    marginBottom: '1rem',
                    color: '#495057',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                   Kilométrage supplémentaire :
                </span>
             
              <div style={{ marginTop: '1rem', position: 'relative' }}>
                <Select 
                value={selectedKilometrage || ''}
                onChange={(e) => {
                  setSelectedKilometrage(e.target.value)
                 
                }}
                disabled={!!selectedHeures}
                 >
                  <option value=''>Sélectionner le kilométrage</option>
                  <option value='200'>200</option>
                  <option value='300'>300</option>
                  <option value='400'>400</option>
                </Select>
                <span
                  style={{
                    position: 'absolute',
                    right: '3rem',
                    top: '1rem',
                  }}
                >
                  KM
                </span>
              </div>
              </div>
              {!mission?.forfaits?.some((forfait)=>forfait.type==='kilometrage'||  forfait.type==='kilometrage_etage') &&
              <div style={{ marginTop: '1rem' }}>
                <span
                  style={{
                    marginBottom: '1rem',
                    color: '#495057',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                   Heures supplémentaires :
                </span>
             
              <div style={{ marginTop: '1rem' }}>
              <Select 
              value={selectedHeures || ''} 
              onChange={(e) => {
                setSelectedHeures(e.target.value)
              }}
              disabled={!!selectedKilometrage}
              >
              <option value=''>Sélectionner les heures </option>
              {generateHourOptions().map((hour, index) => (
              <option key={index} value={hour}>
              {hour}
              </option>
              ))}
              </Select>
              </div>

              </div>
              }
              {(mission?.manutention_chauffeur==="Oui" || mission?.nombre_rippeur) &&
              <div style={{ marginTop: '1rem' }}>
                <span
                  style={{
                    marginBottom: '1rem',
                    color: '#495057',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                   Étages supplémentaires :
                </span>
             
              <div style={{ marginTop: '1rem' }}>
              <Select value={selectedEtages || ''} onChange={(e) => {
                setSelectedEtages(e.target.value)
              }}
                >
              <option value=''>Sélectionner l'étages </option>
              <option value='1-10' >1-10</option>
							<option value='11-20' >11-20</option>
							<option value='21-30' >21-30</option>
              </Select>
              </div>

              </div>
              }
             
			
		
            
			
			
		  
          </div>
          
		   <div
						style={{
							padding: '1rem 1rem',
							background: '#838EAB',
							color: 'white',
							fontFamily: 'Montserrat',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: '0.5rem',
							fontSize: 20,
                            marginLeft:"1rem",
                            marginTop:"1rem"
						}}
					>
            <span>Prix Total </span>
            <span style={{ fontWeight: 'bold',marginBottom:'.5rem'}}>{priceTotal?.toFixed(2)}€ ht</span>
            <span style={{ fontWeight: 'bold' }}>{showPrice()}</span>
            {!!user?.reduction && (
						<><span>Prix avec remise</span>
						<span style={{ fontWeight: 'bold' }}>
							{ priceAvecReduction?.toFixed(2)}€ ht
						</span></>) }
          </div>
          </div>
          <div
            style={{
              width: '100%',
              fontFamily: 'Montserrat',
              paddingLeft: '1rem',
              paddingRight: '1rem',
              marginBottom: '5px',
              marginTop: '1rem',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button
            onClick={handleAddPackage}
			  disabled={loading}
              style={{
                width: '150px',
                height: '50px',
                background: '#50F5A9',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 5,
                fontFamily: 'Montserrat',
                marginRight: 10,
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {loading ? <div className='loader'></div> : 'Confirmer'}
            </button>
            <button
              onClick={()=>{
                setSelectedEtages(null)
                setSelectedHeures(null)
                setSelectedKilometrage(null)
                setPriceTotal(0)
                setPriceAvecReduction(0)
                onClose()
              }}
              style={{
                width: '150px',
                height: '50px',
                background: 'red',
                border: 'none',
                cursor: 'pointer',
                color: 'white',
                borderRadius: 7,
                marginTop: 5,
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
              }}
            >
              Annuler
            </button>
          </div>
          
          </div>
        
     
    </Modal>

    
    

    
  );
}

export default AddForfaitSupplementaireModal;
