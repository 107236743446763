import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { GradientText } from '../../PasserCommande/PasserCommande.styles'
import LayoutAdmin from '../../../Components/LayoutAdmin/LayoutAdmin'
import { FaRegFilePdf } from "react-icons/fa";
import { BiSolidErrorCircle } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { BASE_URL} from '../../../api/base'
import {getSignalementForCommandeMiseADispo,refundSignalement,softDeleteSignalement} from '../../../api/signalements'
import { HiCurrencyDollar } from "react-icons/hi2";
import { useSelector } from 'react-redux'
import Modal from 'react-modal'
import toast from 'react-hot-toast'
function SignalerAdminAll(props) {
	const { id } = useParams()
	const history = useHistory()
	const [signalements, setSignalements] = useState([])
	const [chargementEnCours,setChargementEnCours]=useState(false)
	const [signalement,setSignalement]=useState(null)
	const [currentPage, setCurrentPage] = useState(1)
	const [loading, setLoading] = useState(false)
	const [loadingDelete, setLoadingDelete] = useState(false)
	const [modalIsOpen,setModalIsOpen]=useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [modalIsOpenDelete,setModalIsOpenDelete]=useState(false)
	const token = useSelector((state) => state.auth.token);
	const user = useSelector((state) => state.auth.user);
	
	const fetchData = async (page) => {
		setChargementEnCours(true)
		try {
		const res = await getSignalementForCommandeMiseADispo(id,token,page,perPage)
		setSignalements(res.docs)
		setTotalRows(res.totalDocs)
		} catch (error) {
			
		}finally{
			setChargementEnCours(false)
		}
		
	}

	const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchData(page)
	
		
	}	
	const handlePerRowsChange = async (newPerPage,page) => {
		setChargementEnCours(true)
		try {
		const res = await getSignalementForCommandeMiseADispo(id,token,page,perPage)
		setSignalements(res.docs)
		setPerPage(newPerPage)
		} catch (error) {
			
		}finally{
			setChargementEnCours(false)
		}
		
	}
	const typeLabels = {
		client_absent: "Client absent",
		retard_aux_chargement: "Retard aux chargement",
		livraison_reportée: "Livraison reportée",
		retard_aux_déchargement: "Retard aux déchargement",
		marchandises_manquantes_chargement: "Marchandises manquantes/chargement",
		marchandise_refusée: "Marchandise refusée",
		marchandises_incomplètes: "Marchandises incomplètes",
		marchandise_abîmée: "Marchandise abîmée",
		route_inaccessible_fermée: "Route inaccessible ou fermée",
		circulation_dense: "Circulation dense"
	  };

	const columns = [
		{
			name: "Type d'anomalie ",
			selector: (row) => typeLabels[row.type] || "Type inconnu",
			sortable: false,
			width:'250px'  
		},
		{
            name: "Numéro de mission",
            selector: (row) => row?.mission_index || '__',
			width:'170px'  
        },
		{
            name: "Adresse du point de passage",
            selector: (row) => row?.points_passage.adresse?.label || 'Adresse non disponible',  
        },
		{
			name: "Chauffeur",
			sortable: false,
			cell: (row) => {
				return (
					<div  style={{
						fontWeight: 'bold',
						color: '#51C7F2',
						cursor: 'pointer',
					}}
					onClick={() =>{history.push(`/admin/transports/chauffeur/single/${row?.chauffeur?._id}`)}}>
						{`${row?.chauffeur?.first_name} ${row?.chauffeur?.last_name}`}
					</div>
				);
			},
			width:'200px'  

		
		},
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(
								`/admin/signalement-details/${row._id}`
							)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
					<BiSolidErrorCircle style={{
						fontSize:'25',
						color: '#51C7F2',

					}} />
					</div>
					{row.deletedAt===null && user.role!=="admin distribution" && user.role!=="admin service après vente" &&
					<div 
					onClick={()=>{
						setSignalement(row)
						setModalIsOpenDelete(true)
					}}
					style={{
						cursor: 'pointer',
					}}>
					<MdDelete 
					style={{
						fontSize:'25',
						color: 'red',

					}}/>
					</div>
		             }
					{row.paymentStatus==="payé" && row.statut===true && row.deletedAt!==null && !row.rembourse && user.role!=="admin distribution" &&
					<div
					onClick={() => {
						setSignalement(row)
						setModalIsOpen(true)
					}}
					style={{
						cursor: 'pointer',
						marginLeft:'2px'

					}}
				>
				< HiCurrencyDollar style={{ fontSize: "28px" ,color:'#51C7F2'}} />

				    </div>
					}
					{row.avoir &&
					<div
						onClick={() => {
							window.open(`${BASE_URL}/${row.avoir}`)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
					<FaRegFilePdf style={{
						fontSize:'25',
						color: 'red',

					}} />
					</div>
		            }
				</div>
			),
		},
		
		
		
		
		
		
	
		
		
	]


	useEffect(() => {
		fetchData(currentPage)
	}, [])


	return (
		<LayoutAdmin>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
				<div
					style={{
						width: '100%',
					}}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginBottom: '1rem',
							marginTop: '2rem',
							flexDirection: 'column',
						}}
					>
						<GradientText marginTop={'unset'}>
							{'signalement'.toUpperCase()}
						</GradientText>
						</div>
						<div
					    style={{
						width: '100%',
						padding: '0 1rem',
						marginTop:'2rem'
					}}
				    >
                        <DataTable
						columns={columns}
						data={signalements}
						progressPending={chargementEnCours}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						noDataComponent="Il n'y a pas des signalements à afficher"
						progressComponent={
							<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>
						}
						paginationComponentOptions={{
							rowsPerPageText: 'Rangs par page',
							rangeSeparatorText: 'sur',
						}}
					/>
				</div>
				</div>

					
			</div>
			<Modal
				isOpen={modalIsOpen}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Lancer  le remboursement</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir lancer ce remboursement ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
						disabled={loading}
						onClick={async () => {	
							setLoading(true)
							   try {
								const res=await refundSignalement(signalement._id,token)
								toast.success(res.message, {
									style: {
										fontFamily: 'Montserrat',
									},
								})
								await fetchData(currentPage)
							   } catch (error) {
								 console.log(error)
							   }finally {
								setModalIsOpen(false)
								setLoading(false)
							}
							  
                              
                            }}

						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
					{loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
					</button>
					<button
						onClick={ () => {
							setModalIsOpen(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>	
		   <Modal
				isOpen={modalIsOpenDelete}
				style={{
					overlay: {
						position: 'fixed',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						backgroundColor: 'rgba(0, 0, 0, 0.45)',
					},
					content: {
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						transform: 'translate(-50%, -50%)',
						borderRadius: 19,
						padding: '2rem',
					},
				}}
				contentLabel='Example Modal'
			>
				<div
					style={{
						width: '20rem',
						fontFamily: 'Montserrat',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 15,
						textAlign: 'center',
					}}
				>
					<div
					style={{
						fontWeight: 'bold',
						fontFamily: 'Montserrat',
						fontSize:20,
						color: '#51C7F2',
					}}
					>Supprimer Signalement</div>
					<div
						style={{
							marginTop: 4,
							width: '100%',
						}}
					>
						
						<p>Êtes-vous sûr de vouloir supprimer ce signalement ? </p>
	
					</div>
                     <div
					 style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '5px',
						marginTop: '1rem',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}>
					<button
					
						onClick={async () => {	
							setLoadingDelete(true)
							   try {
								await softDeleteSignalement(signalement._id,token)
								toast.success("Le signalement a été supprimé avec succès.", {
									style: {
										fontFamily: 'Montserrat',
									},
								})
								setModalIsOpenDelete(false)
								await fetchData()
							   } catch (error) {
								
								toast.error(
									error.response.data.message,
									{
										style: {
											fontFamily: 'Montserrat',
										},
									}
								)
								setModalIsOpenDelete(false)

							   }finally {
								setLoadingDelete(false)
							}
							  
                              
                            }}

						disabled={loadingDelete}
						style={{
							width:"150px",
							height:"50px",
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							marginRight:10,
							fontWeight: 'bold',
							display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
						}}
					>
					{loading ? (
									<div className='loader'></div>
								) : (
									'Confirmer'
								)}
					</button>
					<button
						onClick={async () => {
							setModalIsOpenDelete(false)
												
						}}
						style={{
							// padding: '1rem 1.5rem',
							width:"150px",
							height:"50px",
							background: 'red',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 5,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Annuler
					</button>
					</div>
					
				</div>
	       </Modal>	
		</LayoutAdmin>
	)
}

export default SignalerAdminAll
