import React, {  useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import UpdateInfomationsSignataire from '../UpdateInfomationsSignataire/UpdateInfomationsSignataire'

function MiseADispoHorizontal({
	commande,
	onClickDetails = () => {},
	onClickFacture=()=>{},
	onClickAvoir=()=>{},
	hideTransfert=true,
	hidecancel=false,
	hideTarif = false,
	hidefacture=false,
	hideUpdate=false,
	hideSignalement=false,
	hideMission=false,
	onClickCancel=()=>{},
	onClickPayer=()=>{},
	onClickSignaler=()=>{},

}) {
	const user = useSelector(state => state.auth.user)
	const [modalIsOpenUpdate,setModalIsOpenUpdate]=useState(false)
    
    const history=useHistory()
	
	const findClosestMission = (missions) => {
		const validMissions = missions
			.filter(mission => mission.statut !== "annuler") 
			.sort((a, b) => { 
				const aDateTime = moment(`${a.date_debut} ${a.heure_debut}`, "DD/MM/YYYY HH:mm");
				const bDateTime = moment(`${b.date_debut} ${b.heure_debut}`, "DD/MM/YYYY HH:mm");
				return aDateTime - bDateTime;
			});
	
		if (validMissions.length === 0){
			const sortedAnnulerMissions=missions
			.sort((a, b) => { 
				const aDateTime = moment(`${a.date_debut} ${a.heure_debut}`, "DD/MM/YYYY HH:mm");
				const bDateTime = moment(`${b.date_debut} ${b.heure_debut}`, "DD/MM/YYYY HH:mm");
				return aDateTime - bDateTime;
			});
			return sortedAnnulerMissions[0]
		}
	
		return validMissions[0];
	};
	
	const closestMission = findClosestMission(commande?.missions);
	
	const findLatestMission = (missions) => {
		const validMissions = missions
			.filter(mission => mission.statut !== "annuler") 
			.sort((a, b) => { 
				const aDateTime = moment(`${a.date_fin} ${a.heure_fin}`, "DD/MM/YYYY HH:mm");
				const bDateTime = moment(`${b.date_fin} ${b.heure_fin}`, "DD/MM/YYYY HH:mm");
				return bDateTime - aDateTime;
			});
			
			
		if (validMissions.length === 0){
				const sortedAnnulerMissions=missions
				.sort((a, b) => { 
					const aDateTime = moment(`${a.date_fin} ${a.heure_fin}`, "DD/MM/YYYY HH:mm");
					const bDateTime = moment(`${b.date_fin} ${b.heure_fin}`, "DD/MM/YYYY HH:mm");
					return bDateTime - aDateTime;
				});
				return sortedAnnulerMissions[0]
			}
		return validMissions[0];
	};
	const latestMission = findLatestMission(commande?.missions);
	
	const totalForfaitsSupp = commande.missions
    .filter(mission => mission.statut !== 'annuler')
    .reduce((total, mission) => {
        const totalForfaitsMission = mission.forfaits
            ?.reduce((totalForfait, forfait) => totalForfait + (forfait.prix || 0), 0) || 0;
        
        return total + totalForfaitsMission;
    }, 0);

	

	return (
		<div
			style={{
				width: '100%',
				background: '#E4EAF0',
				display: 'flex',
				flexDirection: 'row',
				padding: '1rem',
				borderRadius: 10,
				alignItems: 'flex-start',
				justifyContent: 'space-between',
				marginBottom: 10,
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
				}}
			>
				<span
					style={{
						fontWeight: 'bold',
						color: '#51C7F2',
						marginBottom: 3,
					}}
				>
					Mise a disposition {!!commande ? 'N°' + commande.__id : '' } /{' '}
					<span style={{ color: 'red' }}>
						{commande.temporisation === 'immediat'
							? 'Immédiat'
							: 'Planifier'}
					</span>
				</span>
                <div 
				style={{
					display: 'flex',
					flexDirection: 'row'
				}}>
				<span
					style={{
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Date du Transports :{' '}
					<span
						style={{
							marginRight: 13,
							color: 'red',

						}}
					>

                    {moment(commande.date_transport).format('DD/MM/YYYY')}
                 </span>
				</span>
				
				</div>
				
                <span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginBottom: '1rem',

					}}
				>
					Date du Création :{' '}
					<span
						style={{
							marginRight: 13,
							color:'red'
						}}
					>
				    {moment(commande.createdAt).format('DD/MM/YYYY')}
					</span>
				</span>
				<span
					style={{
						marginRight: 13,
						marginBottom: '1rem',
						color: '#838EAB',
						width: '15rem',

					}}
				>
          Date de début  {' '}
		  <span 
		  style={{color:"red",
           }}>
		   {closestMission?.date_debut}{' '}
           {closestMission?.heure_debut}{' '}
           {closestMission?.adresse_debut?.label}
		  </span>
		 
				</span>


        <span
        style={{
        marginRight: 13,
        color: '#838EAB',
        width: '15rem',
         }}
        >
        Date de fin:{' '}
      <span style={{ color: "red" }}>
	  {latestMission?.date_fin}{' '}
      {latestMission?.heure_fin}{' '}
      {latestMission?.adresse_fin?.label}
      </span>
      </span>

                <span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,

					}}
				>
					 L'état de paiement :{' '}
					<span
						style={{
							marginRight: 13,
							color: 'red'
						}}
					>
				 {commande.paymentStatus==='payé' ? 'Payé' : 'Non Payé'}

					</span>
				</span>
				<span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,

					}}
				>
					 Statut de la commande :{' '}
					<span
						style={{
							marginRight: 13,
							color: 'red'
						}}
					>
                    {commande?.statut==="init" && "Non commencée"}
                    {commande?.statut==="en-cours" && "En cours"}
                    {commande?.statut==="terminer" && "Terminée"}
                   {commande?.statut==="annuler" && "Annulée"}
					</span>
				</span>
				{(!hideTransfert && commande.recu && commande.statut==="en-cours") && (
				<span
					style={{
						color: '#838EAB',
						width: '15rem',
						marginTop: 10,
						
					}}
				>
					 Statut de Transfert :{' '}
					<span
						style={{
							marginRight: 15,
							color: 'red'
						}}
					>
             {commande?.statutTransfert === 'transferé' && "Transferé"}
             {commande?.statutTransfert==="en cours de transfert" && "En cours de transfert"}
             {commande?.statutTransfert==="aucun transfert" && "Aucun transfert"}

					</span>
				</span>
				)}
				<span
					style={{
						color: '#838EAB',
						marginTop: 10,

					}}
				>
					Nombre de missions: {' '}
					<span style={{color:'red'}}>
					{commande?.missions?.length}
					</span>
					
				</span>
			</div>

			{/*  */}
             
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
					height: '100%',

				}}
			>
				<span
					style={{
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Nombre de rippeurs :{' '}
					<span style={{ color: 'red' }}>
					{commande.statut!=="annuler" ?(commande?.missions
                    ?.filter(mission => mission.statut !== "annuler")
                    ?.reduce((total, mission) => total + (mission?.nombre_rippeur || 0), 0) || 0)
				    :
					(commande?.missions
						?.reduce((total, mission) => total + (mission?.nombre_rippeur || 0), 0) || 0)
				    }  

					</span>
				</span>
				<span
					style={{
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Documents :{' '}
					<span style={{ color: 'red' }}>
					{commande.statut!=="annuler"  ? (commande?.files?.length || 0 + 
					commande?.missions
					?.filter(mission=>mission.statut!=="annuler")
					?.reduce((total, mission) => total + (mission.files?.length || 0), 0))
					:
					(commande?.files?.length || 0 + 
						commande?.missions
						?.reduce((total, mission) => total + (mission.files?.length || 0), 0))
					}

					</span>
				</span>
				<span
					style={{
						marginBottom: 3,
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Référence interne client :{' '}
					<span style={{ color: 'red' }}>
					{commande.ref_client}
					</span>
				</span>
                <span
					style={{
						marginBottom: 3,
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Nombre de  kilomètrage :{' '}
					<span style={{ color: 'red' }}>
					{commande.statut!=="annuler" ?(commande?.missions
                    ?.filter(mission => mission.statut !== "annuler")
                    ?.reduce((total, mission) => total + (mission?.kilometrage || 0), 0) || 0)
				    :
				    (commande.distance)
				    }  
					</span>
				</span>
				{user?.role==="client" && 
				<span
				style={{
					marginBottom: 3,
					color: '#838EAB',
					marginBottom: '1rem',

				}}
			>
				 Affectation :{' '}
				 <span style={{ color: 'red' }}>
				{!commande.transporterID && 'Non Affecté'}
				 {(commande.transporterID && !commande.chauffeurID ) && 'Transporteur Affecté' }
				</span>
			 </span>
			 
                }
				<span
						style={{
							color: '#838EAB',
							marginBottom: '.5rem',

						}}
					>
						Notes utiles:{' '}
						<span style={{ color: 'red' }}>
						{commande.statut!=="annuler"? 
						 (commande?.missions
                         ?.filter(mission => mission.statut !== "annuler")
                         ?.some(mission => mission?.notes?.length > 0) ? 'Oui' : 'Non')
						  :
						  (commande?.missions
							?.some(mission => mission?.notes?.length > 0) ? 'Oui' : 'Non')
						 }						
						 
						 </span>
				</span>
				{commande?.statut==="annuler" &&
				<>
				<span
					style={{
						marginBottom: 3,
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Statut de remboursement :{' '}
					<span style={{ color: 'red' }}>
					{commande.statut_remboursement}
					</span>
				</span>
				<span
					style={{
						marginBottom: 3,
						color: '#838EAB',
						marginBottom: '1rem',

					}}
				>
					Date d'annulation :{' '}
					<span style={{ color: 'red' }}>
					{moment(commande?.date_annulation)?.format("DD/MM/YYYY")}
					</span>
				</span>
				</>
                }
				
			</div>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					marginLeft:'0.5rem',
					marginRight:'0.5rem',
					fontSize: 13,
				}}
			>
				{!hideTarif && (
				 <>
				 <div
					style={{
						background: 'white',
						padding: '0.2rem .1rem',
						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							fontWeight: 'bold',
							fontSize: 14,
							padding: '1rem 0.3rem',
						}}
					>
						<span>
							Montant Totale:{' '}
							{
                             (commande.prix+totalForfaitsSupp).toFixed(2)
                            }€
						</span>
					</span>
				</div> 
				
				 <div
					style={{
						background: 'white',
						padding: '0.2rem .2rem',
						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
						marginTop:2
					}}
				>
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							fontWeight: 'bold',
							fontSize: 14,
							padding: '1rem 0.3rem',
						}}
					>
						<span>
						Montant Initiale:{' '}
							
							{
                              commande.prix.toFixed(2)
                            }€
						</span>
					</span>
				</div> 
			
				 <div
					style={{
						background: 'white',
						padding: '0.2rem .2rem',
						display: 'flex',
						flexDirection: 'row',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						marginBottom: 3,
					}}
				>
					<span
						style={{
							marginRight: 15,
							display: 'flex',
							flexDirection: 'column',
							fontWeight: 'bold',
							fontSize: 14,
							padding: '1rem 0.3rem',
						}}
					>
						<span>
						Montant Forfait:{' '}
							{
                             totalForfaitsSupp?.toFixed(2)
                            }€
						</span>
					</span>
				</div>
				</> 
			    )}
				{hideTarif && 
				 <div
				 style={{
					 background: 'white',
					 padding: '0.2rem .2rem',
					 display: 'flex',
					 flexDirection: 'row',
					 color: '#838EAB',
					 fontSize: 13,
					 borderRadius: 5,
					 marginBottom: 3,
					 marginTop:2
				 }}
			 >
				 <span
					 style={{
						 marginRight: 15,
						 display: 'flex',
						 flexDirection: 'column',
						 fontWeight: 'bold',
						 fontSize: 14,
						 padding: '1rem 0.3rem',
					 }}
				 >
					 <span>
					     Montant:  {' '}
						 {
						   commande?.prix.toFixed(2)
						 }€
					 </span>
				 </span>
			     </div>
				}
				{(hideTarif && commande?.statut_remboursement==="remboursé")&&
				 <div
				 style={{
					 background: 'white',
					 padding: '0.2rem .2rem',
					 display: 'flex',
					 flexDirection: 'row',
					 color: '#838EAB',
					 fontSize: 13,
					 borderRadius: 5,
					 marginBottom: 3,
					 marginTop:2
				 }}
			 >
				 <span
					 style={{
						 marginRight: 15,
						 display: 'flex',
						 flexDirection: 'column',
						 fontWeight: 'bold',
						 fontSize: 14,
						 padding: '1rem 0.3rem',
					 }}
				 >
					 <span>
					     Montant Remboursé: {' '}
						 {
						   commande?.prixRembourse.toFixed(2)
						 }€
					 </span>
				 </span>
			     </div>
				}
			    
				
                
			</div>

			{/*  */}

			<div
				style={{
					color: '#838EAB',
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
				}}
			>
				<span>
					Diable:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes('Diable') && 'Oui'}
					</span>
				</span>
				<span>
					Transpalette manuel:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes('Transpalette manuel') &&
							'Oui'}
					</span>
				</span>
				<span>
					Transpalette électrique:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes(
							'Transpalette électrique'
						) && 'Oui'}
					</span>
				</span>
				<span>
					Sangle:{' '}
					<span style={{ color: 'red' }}>
						{commande.manutention.includes('Sangle') && 'Oui'}
					</span>
				</span>

				<span>
					Casque:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Casque') && 'Oui'}
					</span>
				</span>
				<span>
					Gants:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Gants') && 'Oui'}
					</span>
				</span>
				<span>
					Masque:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Masque') && 'Oui'}
					</span>
				</span>
				<span>
					Lunettes:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Lunettes') && 'Oui'}
					</span>
				</span>
				<span>
					Chaussure de sécurité:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Chaussure de sécurité') &&
							'Oui'}
					</span>
				</span>
				<span>
					Gilet de visibilité:{' '}
					<span style={{ color: 'red' }}>
						{commande.epi.includes('Gilet de visibilité') && 'Oui'}
					</span>
				</span>
			</div>

			<div
				style={{
					//width: '16%',
					display: 'flex',
					flexDirection: 'column',
					fontSize: 13,
					marginTop: 35,
				}}
			>
				
				<button
					onClick={onClickDetails}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#51C7F2',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Voir détails
				</button>
				{!hideSignalement &&
				<button
				onClick={onClickSignaler}
				style={{
					padding: '0.5rem 1.5rem',
					background: '#838EAB',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					marginTop: 5,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
				}}
			>
				Signalements
			   </button>
                }
				
				{!hidefacture &&
				<button
					onClick={onClickFacture}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#99ff99',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Voir Facture
				</button>
                }
				{!hidefacture &&  commande.statut==="annuler" && commande.statut_remboursement==="remboursé" &&
				<button
					onClick={onClickAvoir}
					style={{
						padding: '0.5rem 1.5rem',
						background: '#99ff99',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Voir Avoir
				</button>
                }
				{!hidecancel&&
				<button
					onClick={onClickCancel}
					style={{
						padding: '0.5rem 1.5rem',
						background:commande?.statut ==="annuler" ? 'red' : '#ff9999',
						border: 'none',
						cursor: 'pointer',
						borderRadius: 7,
						color: commande?.statut ==="annuler"? 'white' : 'white',
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Annuler
				</button>
                }
				{(!hidecancel && !user.premium && commande.prix!==0 &&  commande.paymentStatus==="non payé")&&
				<button
					onClick={onClickPayer}
					style={{
						padding: '0.5rem 1.5rem',
						background:commande?.statut ==="payé" ? '#66ff66' : '#99ff99',
						border: 'none',
						cursor: 'pointer',
						color: 'white',
						borderRadius: 7,
						marginTop: 5,
						fontFamily: 'Montserrat',
						fontWeight: 'bold',
					}}
				>
					Payer
				</button>
                }
				{!hideUpdate && 
				<button
				onClick={()=>setModalIsOpenUpdate(true)}
				style={{
					padding: '0.5rem 1.5rem',
					background:'#5bc0de',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					marginTop: 5,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
				}}
			>
				Modifier Signataire
			   </button>
				}
			
			    <>
				{!hideMission &&
				<button 
				onClick={()=>{history.push(`/client/transports/missions/${commande._id}`)}}
				style={{
					padding: '0.5rem 1.5rem',
					background:'#5bc0de',
					border: 'none',
					cursor: 'pointer',
					color: 'white',
					borderRadius: 7,
					marginTop: 5,
					fontFamily: 'Montserrat',
					fontWeight: 'bold',
				}}>
					Gestion des missions
				</button>
                }
				</>
			 
			
               
				 
				
				 
				 
			</div>
			
		
		     <UpdateInfomationsSignataire
			 commande={commande}
			 modalIsOpenUpdate={modalIsOpenUpdate}
			 setModalIsOpenUpdate={setModalIsOpenUpdate}
			 />
			
		
			
		</div>
	)
}

export default React.memo(MiseADispoHorizontal)
