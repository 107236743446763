import React ,{useEffect,useState} from 'react'
import LayoutCommercant from '../../Components/LayoutCommercant/LayoutCommercant'
import {
	GradientText,
} from '../PasserCommande/PasserCommande.styles'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { getClientOfCommercant,getClientByNameAndIdForCommercial } from '../../api/commerciaux'
import toast from 'react-hot-toast'

function ClientList() {
    const history=useHistory()
	const [chargementEnCours, setChargementEnCours] = useState(false);
	const [totalRows,setTotalRows]=useState(10)
    const token = useSelector((state) => state.auth.token);
    const user = useSelector((state) => state.auth.user)
    const [searchQuery, setSearchQuery] = useState('')
    const [data, setData] = useState([])
	const [perPage, setPerPage] = useState(10)
	const [currentPage, setCurrentPage] = useState(1)
	const [isSearching,setIsSearching]=useState(false)

    const fetchClient = async (page) => {
		setChargementEnCours(true)
		try {
			let res
			if(isSearching){
				if (searchQuery.length === 0) {
					res = await getClientOfCommercant(user._id,page, perPage,token)
				} else {
				 res = await getClientByNameAndIdForCommercial(searchQuery,user._id,page,perPage,token)
				}
			}else{
				res = await getClientOfCommercant(user._id,page,perPage,token)
			}
			setData(res.docs)
			setTotalRows(res.totalDocs)
		} catch (error) {
			toast.error("Une erreur s'est produite", {
				style: {
					fontFamily: 'Montserrat',
				},
			})
		}finally{
			setChargementEnCours(false)

		}
	
	}
	const handlePerRowsChange = async (newPerPage) => {
		setPerPage(newPerPage)
	}
	const handlePageChange = (page) => {
		setCurrentPage(page)
		fetchClient(page)
	}
 
	
	const  handleSearch=async()=>{
		setCurrentPage(1)
		setIsSearching(true)
		if(isSearching){
			await fetchClient(1)
		}
	}
	const  handelReset=async()=>{
		setIsSearching(false)
		setCurrentPage(1)
		setSearchQuery('')

	}


	useEffect(()=>{
		fetchClient(currentPage)
	},[isSearching,perPage,currentPage])
    const columns = [
		{
			name: 'ID',
			selector: (row) => row.__id,
			sortable: false,
			width: "200px"

		},
		{
			name: 'Nom du client',
			selector: (row) => `${row.first_name} ${row.last_name}`,
			sortable: false,
			width:'200px',

		},
		{
			name: '% de réduction',
			cell: (row) => (
				<div
					style={{
						//padding: '0.5rem 2rem',
						display: 'flex',
						flexDirection: 'column',
						color: '#838EAB',
						fontSize: 13,
						borderRadius: 5,
						margin: '1rem 0 1rem 0',
					}}
				>
					<span
						
						style={{
							
							fontWeight: 'bold',
							color: '#51C7F2',
							
						}}
					>
                    {row.reduction ? `${row.reduction}%` : '0%'}					
                </span>	
				</div>
			),
			sortable: false,
			width:'150px',
		},
		{
			name: "Date d'affectation",
			selector: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<span
						style={{
							fontWeight: 'bold',
							marginBottom: 3,
						}}
					>
					{moment(row?.date_affectation).format('DD/MM/YYYY')}
					</span>
					
				</div>
			),
			width: '150px',
			sortable: false,
		},
		{
			name: 'Statut',
			selector: (row) => (row.suspendre ? 'Suspendu' : 'Activé'),
			sortable: false,
			width:'150px',

		},
		{
			name: "Date de suspension",
			selector: (row) => (row?.suspendre ? moment(row?.dateSuspension).format('DD/MM/YYYY') : '__'),
			sortable: false,
			width: "200px"
		},
		{
			name: "Date d'inscription",
			selector: (row) => moment(row.createdAt).format('DD/MM/YYYY'),
			sortable: false,
			width:'200px',
		},
	
		{
			name: 'Actions',
			button: true,
			cell: (row) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<div
						onClick={() => {
							history.push(`/commercial/clients/${row._id}/commandes`)
						}}
						style={{
							cursor: 'pointer',
							paddingRight:'1rem'
						}}
					>
						<svg
									id='Icon_Invoices'
									xmlns='http://www.w3.org/2000/svg'
									width='21.75'
							        height='27.466'
									viewBox='0 0 14 16'
								>
									<path
										id='Path_102'
										data-name='Path 102'
										d='M14,0H2A.945.945,0,0,0,1,1V16l3-2,2,2,2-2,2,2,2-2,3,2V1A.945.945,0,0,0,14,0ZM12,10H4V8h8Zm0-4H4V4h8Z'
										transform='translate(-1)'
										fill='#b4b4c3'
									/>
								</svg>
					</div>
					<div
						onClick={() => {
							history.push(`/commercial/clients/${row._id}/accounts`)
						}}
						style={{
							cursor: 'pointer',
						}}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='21.75'
							height='27.466'
							viewBox='0 0 21.75 27.466'
						>
							<g
								id='Group_11097'
								data-name='Group 11097'
								transform='translate(-10999.709 -6280)'
							>
								<path
									id='Path_11755'
									data-name='Path 11755'
									d='M11013.243,6280l8.216,8.216-12.913,12.914h-8.837v-8.244Z'
									fill='#b4b4c3'
								/>
								<path
									id='Path_11756'
									data-name='Path 11756'
									d='M10999.709,6306.466h20.784'
									fill='none'
									stroke='#e7e7eb'
									strokeWidth='2'
								/>
							</g>
						</svg>
					</div>
				</div>
			),
		},
	]
  return (
    <LayoutCommercant>
       
        <div
				style={{
					//height: '80vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					paddingTop: '2rem',
					paddingBottom: '4rem',
					fontFamily: 'Montserrat',
				}}
			>
     <GradientText marginTop={'0'}>
					{'Mes Clients'.toUpperCase()}
	</GradientText>
      <div
					style={{
						width: '100%',
						fontFamily: 'Montserrat',
						paddingLeft: '1rem',
						paddingRight: '1rem',
						marginBottom: '2rem',
						marginTop: '1rem',
					}}
				>
					<input
					disabled={chargementEnCours}
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
                          handleSearch()
						}
					}}
						placeholder='Rechercher par identifiant,nom client '
						style={{
							padding: '1rem',
							borderRadius: 5,
							border: '1px solid #E4EAF0',
							width: '25rem',
							fontFamily: 'Montserrat',
						}}
					/>

					<button
						disabled={chargementEnCours}
						onClick={handleSearch}
						style={{
							padding: '1rem 2rem',
							background: '#50F5A9',
							border: 'none',
							cursor: 'pointer',
							color: 'white',
							borderRadius: 7,
							marginTop: 20,
							marginLeft: 20,
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Rechercher
					</button>
					<button
						onClick={handelReset}
						style={{
							padding: '0.6rem 1rem',
							height: '3rem',
							background: '#C9D5F7',
							border: 'none',
							cursor: 'pointer',
							color: '#1C63C5',
							borderRadius: 7,
							//marginTop: 20,
							marginLeft: '1rem',
							fontFamily: 'Montserrat',
							fontWeight: 'bold',
						}}
					>
						Effacer
					</button>
	  </div>
	  
	  <div
	  style={{
	  width: '100%',
	  padding: '0 1rem',
	 }}>
		<DataTable
						columns={columns}
						data={data}
						pagination
						paginationServer
						paginationTotalRows={totalRows}
						progressPending={chargementEnCours}
						noDataComponent="Il n'y a pas des clients à afficher"
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						progressComponent={
						<div
							style={{
							  display: 'flex',
							  alignItems: 'center',
							  justifyContent: 'center',
							  backgroundColor: 'white',
							  marginTop:"10rem"
							}}
							>
							<div className='customLoader'></div>	
							</div>}
						
		/>
	 </div>
     
      </div>
    </LayoutCommercant>

  )
}

export default ClientList